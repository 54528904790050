import {
  Button,
  RefreshButton,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin'
import { useState } from 'react'
import { CircularProgress } from '@mui/material'

const DownloadButton = () => {
  const record = useRecordContext()
  const dp = useDataProvider()

  const handleClick = async () => {
    dp.downloadFileImport(record.id).then(({ data }) => {
      window.location.href = data.url
    })
  }

  return <Button label="Download" onClick={handleClick} />
}

const ImportButton = () => {
  const [loading, setLoading] = useState(false)
  const record = useRecordContext()
  const notify = useNotify()
  const dp = useDataProvider()

  const handleClick = () => {
    setLoading(true)
    dp.startFileImport(record.id).then(({ data }) => {
      console.log(data)
      notify('Import done!')
      setLoading(false)
    })
  }

  return (
    <>
      <Button label="Import" onClick={handleClick} />
      {loading && <CircularProgress size={24} />}
    </>
  )
}

const FileActionButtons = () => {
  return (
    <div className="flex">
      <DownloadButton />
      <ImportButton />
      <RefreshButton />
    </div>
  )
}

export default FileActionButtons
