import { defaultTheme } from 'react-admin'
import { createTheme } from '@mui/material'

const theme = createTheme({
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #cbd5e1',
          overflow: 'hidden !important',
          borderRadius: '10px',
          boxShadow: '0 0 transparent',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          textAlign: 'center',
          padding: '1rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff !important',
          borderRadius: '6px',
          border: '1px solid #d1d5db',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            fontSize: 13,
            lineHeight: 1.3,
            background: '#f8fafc',
            fontWeight: 600,
            color: '#333',
            paddingTop: 12,
            paddingBottom: 12,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          padding: '2px 0',
          border: '1px solid #d1d5db',
          backgroundColor: '#ffffff',
          borderRadius: 8,
          boxShadow:
            '#000000 0 0 0 0, #000000 0 0 0 0, #000000 0 0 0 0, rgba(0,0,0, 0.05) 0px 1px 2px 0px',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          padding: 10,
          '&.Mui-selected': {
            color: '#ffffff',
            background: '#4338ca',
            fontWeight: 600,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 0 transparent',
          padding: '12px 14px',
          fontWeight: 600,
          fontSize: 15,
        },
        text: {
          padding: '6px 8px',
          lineHeight: 1.1,
          fontWeight: 500,
          fontSize: 13,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 13,
          padding: '12px 10px',
          paddingBottom: 12,
          borderColor: '#e2e8f0',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#4338ca',
      dark: '#312e81',
      light: '#4f46e5',
    },
    secondary: {
      main: '#584f9c',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['Inter', 'Arial', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
})

const spendQubeTheme = {
  ...theme,
  components: {
    ...theme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-headerCell': {
            backgroundColor: 'white',
          },
          '& .RaDatagrid-rowOdd': {
            backgroundColor: '#f8fafc',
          },
        },
      },
    },
  },
}

export default spendQubeTheme
