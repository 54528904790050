import { PointTooltipProps, ResponsiveLine } from '@nivo/line'
import { useTranslation } from 'react-i18next'

import { formatNumber, getLabelSuffix } from '../../helpers'
import { NoData } from '../NoData'
import { useChartCompare } from '../../helpers/useChartCompare'
import { CompareLegend } from '../CompareLegend'

const theme = {
  grid: {
    line: {
      stroke: '#f0f0f0',
    },
  },
}

const MAX_X_LENGTH = 20
const CHART_COLORS = ['#76c793', '#4338ca']
const renderTooltip = ({ point }: PointTooltipProps) => {
  return (
    <div className="rounded shadow p-3 bg-white text-sm border border-stone-100 flex space-x-3 text-left">
      <div
        className="w-1 h-10 rounded-lg"
        style={{ backgroundColor: point.serieColor }}
      />

      <div className="space-y-0.5">
        {point.serieId === 'compare' ? (
          <div className="font-medium text-xs text-indigo-600">Compare</div>
        ) : (
          <div className="text-gray-500 font-medium">
            {point.data.xFormatted}
          </div>
        )}
        <div className="font-semibold">
          {formatNumber(point.data.y)} vendors
        </div>
      </div>
    </div>
  )
}

const MonthlyVendorCountChart = () => {
  const chartType = 'vendorCountPerMonth'
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  const { formattedData, isLoading, isChartLong } = useChartCompare({
    chartType,
    isCurrency: false,
    serieName: 'vendorCount',
    maxLength: MAX_X_LENGTH,
  })

  const title = t(`vendor_per_month${suffix}`)

  if (isLoading || !formattedData)
    return (
      <div className="space-y-4 flex-1">
        <h2 className="font-semibold">{title}</h2>
        <div className="h-[300px] bg-stone-50/70 rounded-md animate-pulse" />
      </div>
    )

  return (
    <div className="space-y-4 flex-1">
      <h2 className="font-semibold">{title}</h2>
      <div className="h-[300px]">
        {formattedData.length ? (
          <ResponsiveLine
            curve="monotoneX"
            theme={theme}
            data={formattedData}
            enableArea
            margin={{
              top: 10,
              right: 15,
              bottom: isChartLong ? 35 : 20,
              left: 50,
            }}
            lineWidth={3}
            pointSize={6}
            pointColor="white"
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            enableGridX={false}
            axisBottom={{
              tickRotation: isChartLong ? -40 : 0,
            }}
            colors={CHART_COLORS}
            areaOpacity={0.07}
            useMesh
            tooltip={renderTooltip}
            enableCrosshair={false}
          />
        ) : (
          <NoData />
        )}
      </div>
      <CompareLegend colors={CHART_COLORS} />
    </div>
  )
}

export default MonthlyVendorCountChart
