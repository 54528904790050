import { GlobeAltIcon } from '@heroicons/react/24/outline'
import { Datagrid, List, TextField } from 'react-admin'
import { useTranslation } from 'react-i18next'
import { Title } from '../components/Title'

const GroupList = () => {
  const { t } = useTranslation()
  return (
    <section>
      <div className="flex items-center justify-between">
        <Title>{t('groups')}</Title>
        <div className="p-2 rounded-full bg-white flex items-center justify-center shadow-sm">
          <GlobeAltIcon className="h-6" />
        </div>
      </div>
      <List sort={{ field: 'group_name', order: 'ASC' }}>
        <Datagrid rowClick="show" bulkActionButtons={false} optimized>
          <TextField source="name" />
        </Datagrid>
      </List>
    </section>
  )
}

export default GroupList
