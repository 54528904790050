// This site has really good colour palettes https://observablehq.com/@d3/color-schemes

export const PREFERRED_COLOR_PALETTE = [
  '#717171',
  '#66c2a5',
  '#9e0142',
  '#f46d43',
  '#fdae61',
  '#fee08b',
  '#ffffbf',
  '#e6f598',
  '#abdda4',
  '#3288bd',
  '#5e4fa2',
]
export const COLOR_PALETTE = [
  '#9e0142',
  '#d53e4f',
  '#f46d43',
  '#fdae61',
  '#fee08b',
  '#ffffbf',
  '#e6f598',
  '#abdda4',
  '#66c2a5',
  '#3288bd',
  '#5e4fa2',
]

export const COLOR_PALETTE_LEVEL_2 = [
  '#440154',
  '#482475',
  '#414487',
  '#355f8d',
  '#2a788e',
  '#21918c',
  '#22a884',
  '#44bf70',
  '#7ad151',
  '#bddf26',
  '#fde725',
]

export const COLOR_PALETTE_LEVEL_3 = [
  '#004529',
  '#036034',
  '#15793f',
  '#2f944e',
  '#4eaf63',
  '#78c578',
  '#a2d88a',
  '#c7e89b',
  '#e4f4ac',
  '#f7fcc4',
  '#ffffe5',
]

export const COLOR_PALETTE_BLUE = [
  '#fff7fb',
  '#efeaf4',
  '#dbdaeb',
  '#bfc9e2',
  '#9bb9d9',
  '#72a8cf',
  '#4394c3',
  '#1a7db6',
  '#0667a1',
  '#045281',
  '#023858',
]

export const COLOR_PALETTE_GREEN = [
  '#ffffe5',
  '#f7fcc4',
  '#e4f4ac',
  '#c7e89b',
  '#a2d88a',
  '#78c578',
  '#4eaf63',
  '#2f944e',
  '#15793f',
  '#036034',
  '#004529',
]

export const COLOR_PALETTE_MAP = [
  '#ffffe5',
  '#fff8c4',
  '#feeaa1',
  '#fed676',
  '#feba4a',
  '#fb992c',
  '#ee7918',
  '#d85b0a',
  '#b74304',
  '#8f3204',
  '#662506',
]

export const TOP_VENDOR_COLOR = '#115dfc'
export const BOTTOM_VENDOR_COLOR = '#baeeff'
