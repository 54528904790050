import {
  Edit,
  ListButton,
  ShowButton,
  SimpleForm,
  TextInput,
  TopToolbar,
} from 'react-admin'

const GroupEditActions = () => (
  <TopToolbar>
    <ShowButton />
    <ListButton />
  </TopToolbar>
)

const GroupEdit = () => {
  const transform = (data) => {
    return data
  }

  return (
    <Edit
      mutationMode="pessimistic"
      actions={<GroupEditActions />}
      transform={transform}
    >
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  )
}

export default GroupEdit
