import * as React from 'react'
import {
  AutocompleteInput,
  Button,
  Form,
  ReferenceInput,
  SaveButton,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'

import MergeIcon from '@mui/icons-material/Merge'
import CancelIcon from '@mui/icons-material/Cancel'

import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'

const ReferenceVendorDialog = ({ open, handleClose }) => {
  const [loading, setLoading] = React.useState(false)
  const dp = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleSubmit = (values) => {
    setLoading(true)
    const data = {
      vendor_id: values.id,
      reference_vendor_id: values.reference_vendor_id,
    }
    dp.addReferenceVendor({ data })
      .then((resp) => {
        notify(`Vendor changed`, { type: 'success' })
        refresh()
        handleClose(resp)
      })
      .catch((e) => {
        console.log('Error')
        notify(`Vendor change error: ${e.message}`, { type: 'warning' })
      })
      .finally(() => {
        setLoading(false)
      })

    setLoading(false)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>This vendor is also known as</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <ReferenceInput
                label="Reference Vendor"
                source="reference_vendor_id"
                enableGetChoices={({ q }) => q && q.length >= 2}
                perPage={1000}
                reference="reference_vendors"
              >
                <AutocompleteInput
                  optionText="name"
                  label="Vendor"
                  noOptionsText="Start typing name to find vendor"
                  helperText="Enter 3 characters to start searching"
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12}>
              <Button disabled={loading} onClick={handleClose} label="Cancel">
                <CancelIcon />
              </Button>

              <SaveButton disabled={loading} />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const ReferenceVendorMapper = () => {
  const record = useRecordContext()
  const [openDialog, setOpenDialog] = React.useState(false)

  if (record.reference_vendor_matched)
    return (
      <p>
        {record.reference_vendor_matched && record.reference_vendor_tags_found
          ? 'Perfect Match'
          : 'Partial Match'}
      </p>
    )

  return (
    <>
      <ReferenceVendorDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false)
        }}
      />
      <Button
        onClick={() => {
          setOpenDialog(true)
        }}
        label="Known as"
      >
        <MergeIcon />
      </Button>
    </>
  )
}

export default ReferenceVendorMapper
