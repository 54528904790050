import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'

import useChartContext from '../Dashboard/charts/useChartContext'

import { Widget } from '../types'
import { Card } from './Card'

export const SectionWidget = ({ widget }: { widget: Widget }) => {
  const { selectedCompareOption } = useChartContext()
  const isCompareEnabled = useMemo(
    () => selectedCompareOption.id !== '',
    [selectedCompareOption]
  )
  return (
    <section key={widget.id} className="py-4">
      <div className="relative">
        {widget.supportsComparisons && isCompareEnabled && (
          <div className="absolute right-3 -top-4 border bg-indigo-600 text-white py-2 px-3 rounded-full text-xs font-medium z-10 flex items-center space-x-3">
            <ArrowsRightLeftIcon className="h-4" />
            <span>Comparison</span>
          </div>
        )}

        <Card
          className={`overflow-hidden ${
            widget.supportsComparisons && isCompareEnabled
              ? '!border-indigo-600'
              : ''
          }`}
        >
          {widget.chart}
        </Card>
      </div>
    </section>
  )
}
