import { FunctionComponent, ReactNode } from 'react'

interface Props {
  className?: string
  children?: ReactNode
  title?: ReactNode
}

export const Card: FunctionComponent<Props> = ({
  className,
  children,
  title,
}) => (
  <section
    className={`bg-white rounded-xl p-6 transition-all border border-stone-300/80 hover:scale-[1.004] hover:shadow-sm ${
      className || ''
    }`}
  >
    {title && <h2 className="font-semibold mb-6">{title}</h2>}
    {children}
  </section>
)
