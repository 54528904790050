import { useRecordContext } from 'react-admin'
import { formatCurrency } from '../helpers'

const AmountField = (props) => {
  const record = useRecordContext(props)

  return record ? <span>{formatCurrency(record.amount / 100)}</span> : null

  /*
    record.amount = record.amount / 100 
  
    return record ? (
      <NumberField
        source="amount"
        options={{ style: 'currency', currency: 'GBP' }}
      />
    ) : null
    */
}

export default AmountField
