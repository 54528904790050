export const CategoryTooltip = ({ label, value, color }) => (
  <div className="rounded shadow p-3 bg-white flex items-center space-x-2 text-sm border border-stone-100 font-medium">
    <div
      className="h-2.5 w-2.5 rounded-sm"
      style={{ backgroundColor: color }}
    />
    <h3 className="text-stone-700">{label}:</h3>
    <h4 className="font-semibold">{value}</h4>
  </div>
)
