/* eslint-disable */
/* This file has the linter disabled.  But it is basically a cut'n'paste from
 * the origin react-admin library with a few minor changes.
 * And there is no time left to fix it for our linting
 * */
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@mui/material'
import {
  Form,
  required,
  useLogin,
  useNotify,
  useSafeSetState,
  useTranslate,
} from 'ra-core'
import { TextInput, useDataProvider } from 'react-admin'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Tagline } from './Tagline'
import { useTranslation } from 'react-i18next'

const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol character')
      .required(),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required()

export const LoginForm = (props: LoginFormProps) => {
  const { redirectTo, className } = props
  const [loading, setLoading] = useSafeSetState(false)
  const { i18n } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [action, setAction] = useSafeSetState('login')
  const [token, setToken] = useSafeSetState('')

  const login = useLogin()
  const translate = useTranslate()
  const notify = useNotify()
  const dp = useDataProvider()

  React.useEffect(() => {
    const qtoken = searchParams.get('token')

    if (qtoken !== null) {
      setAction('reset')
      setToken(qtoken)
    }
  }, [searchParams])

  const submit = (values) => {
    const logLoginAttempt = (isSuccess, userId = null) => {
      dp.create('login_attempts', {
        data: {
          user_id: userId,
          username: values.username,
          success: isSuccess,
          timestamp: new Date(),
        },
      }).catch((error) => {
        console.error('Failed to record login attempt:', error)
      })
    }

    setLoading(true)

    if (action === 'login') {
      login(values, redirectTo)
        .then(() => {
          dp.getProfile().then(({ data }) => {
            logLoginAttempt(true, data.id)
            i18n.changeLanguage(data.lang || 'en')
            setLoading(false)
          })
        })
        .catch((error) => {
          logLoginAttempt(false)
          setLoading(false)
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
            {
              type: 'warning',
              messageArgs: {
                _:
                  typeof error === 'string'
                    ? error
                    : error && error.message
                    ? error.message
                    : undefined,
              },
            }
          )
        })
    }

    if (action === 'forgotten') {
      dp.forgottenPassword({ email: values.username })
        .then(() => {
          setLoading(false)
          notify(
            'Forgotten password request sent.  If your email address is registered you should receive an email',
            {
              type: 'success',
            }
          )
          setAction('login')
        })
        .catch((error) => {
          setLoading(false)
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
            {
              type: 'warning',
              messageArgs: {
                _:
                  typeof error === 'string'
                    ? error
                    : error && error.message
                    ? error.message
                    : undefined,
              },
            }
          )
        })
    }

    if (action === 'reset') {
      dp.resetPassword({ token, password: values.password })
        .then(() => {
          setLoading(false)
          notify('Password reset. Please login', {
            type: 'success',
          })
          setAction('login')
        })
        .catch((error) => {
          setLoading(false)
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
            {
              type: 'warning',
              messageArgs: {
                _:
                  typeof error === 'string'
                    ? error
                    : error && error.message
                    ? error.message
                    : undefined,
              },
            }
          )
        })
    }
  }

  let buttonText = 'Sign in'

  let resolver: any = undefined

  if (action === 'reset') {
    buttonText = 'Save'
    resolver = yupResolver(schema)
  }
  if (action === 'forgotten') {
    buttonText = 'Request link'
  }

  const titleText = React.useMemo(() => {
    if (action === 'login') return 'Sign in to your account'
    return 'Reset your password'
  }, [action])

  return (
    <>
      <StyledForm
        onSubmit={submit}
        mode="onChange"
        noValidate
        className={className}
        resolver={resolver}
      >
        <section className="space-y-8 w-[350px] mx-4 my-2">
          <div className="flex flex-col items-center space-y-6">
            <div className="flex flex-col items-center space-y-0.5">
              <img src="/logo.png" className="h-10" alt="SpendQube logo" />
              <h2>
                Spend<span className="font-medium">Qube</span>
              </h2>
            </div>
            <h1 className="font-semibold text-xl">{titleText}</h1>
          </div>
          <div>
            {action === 'forgotten' && (
              <div className="space-y-4">
                <button
                  className="w-full flex space-x-3 text-sm font-semibold items-center text-indigo-700 hover:underline active"
                  onClick={() => setAction('login')}
                >
                  <ArrowLeftIcon className="h-3" />
                  <span>Back to Login</span>
                </button>
                <p className="text-slate-700 pb-2 text-sm">
                  Fill in your email address and you will be sent a link to
                  follow to reset your password
                </p>
              </div>
            )}
            {(action === 'login' || action === 'forgotten') && (
              <TextInput
                autoFocus
                source="username"
                label="Email address"
                validate={required()}
                fullWidth
              />
            )}
            {(action === 'login' || action === 'reset') && (
              <TextInput
                source="password"
                label={translate('ra.auth.password')}
                type="password"
                autoComplete="current-password"
                validate={required()}
                fullWidth
              />
            )}

            {action === 'reset' && (
              <TextInput
                source="password2"
                label="Confirm password"
                type="password"
                autoComplete="current-password"
                validate={required()}
                fullWidth
              />
            )}
            <div className="space-y-3">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
                className={LoginFormClasses.button}
              >
                {loading ? (
                  <CircularProgress
                    className={LoginFormClasses.icon}
                    size={19}
                    thickness={3}
                  />
                ) : (
                  buttonText
                )}
              </Button>
              {action == 'login' && (
                <div className="flex justify-end">
                  <a
                    className="text-indigo-700 font-semibold text-sm hover:underline active cursor-pointer"
                    onClick={() => setAction('forgotten')}
                  >
                    Forgotten password?
                  </a>
                </div>
              )}
            </div>
          </div>
        </section>
      </StyledForm>
      <Tagline floating />
    </>
  )
}

const PREFIX = 'RaLoginForm'

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}))

export interface LoginFormProps {
  redirectTo?: string
  className?: string
}

interface FormData {
  username: string
  password: string
}

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
}
