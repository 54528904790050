import { Create, SimpleForm, TextInput } from 'react-admin'

const TagCategoryCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  )
}

export default TagCategoryCreate
