import { useState } from 'react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  HandRaisedIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline'
import { useRefresh } from 'react-admin'
import { useTranslation } from 'react-i18next'

import { Modal } from './common/Modal'
import { WidgetPicker } from './WidgetPicker'

export const CustomisationModal = ({
  orderedWidgets,
}: {
  orderedWidgets: number[]
}) => {
  const refresh = useRefresh()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      renderButton={(onClick) => (
        <button
          onClick={() => onClick()}
          type="button"
          className="px-3 py-2 rounded-md border border-gray-300 hover:bg-slate-50 text-sm font-medium flex gap-2 items-center h-full bg-white shadow-sm active"
        >
          <RectangleStackIcon className="h-4 inline-block" />
          <span>{t('widget_gallery')}</span>
        </button>
      )}
      title={t('customize_dashboard')}
    >
      <div className="max-h-[80vh] overflow-y-scroll">
        <section className="min-w-[900px] space-y-4 bg-white rounded">
          <div className="flex flex-col items-center text-center space-y-1.5 py-2">
            <h3 className="font-medium">{t('select_widgets')}</h3>
            <div className="flex items-center space-x-6">
              <ArrowLeftIcon className="h-5 -rotate-6" />
              <div className="flex items-center -space-x-1.5">
                <HandRaisedIcon className="h-6 -rotate-6 z-10" />
                <div className="rounded-md ring-1 ring-indigo-700 bg-gray-50 h-12 w-20 shadow-md rotate-3 hover:shadow-lg hover:rotate-6 transition-all p-2 space-y-1">
                  <div className="h-2.5 rounded bg-gray-200/70 w-10" />
                  <div className="h-2.5 rounded bg-gray-200/70 w-14" />
                </div>
              </div>
              <ArrowRightIcon className="h-5 rotate-6" />
            </div>
          </div>
          <WidgetPicker
            orderedWidgets={orderedWidgets}
            onFinish={() => {
              setOpen(false)
              refresh()
            }}
          />
        </section>
      </div>
    </Modal>
  )
}
