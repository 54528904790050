import { BuildingStorefrontIcon } from '@heroicons/react/24/outline'
import {
  Datagrid,
  EditButton,
  List,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin'
import { useTranslation } from 'react-i18next'
import { Title } from '../components/Title'
import AssignGroupButton from './AssignGroupButton'
import ReferenceVendorMapper from './ReferenceVendorMapper'
import userPermissions from '../Users/userPermissions'
import VendorMatchFlags from '../Transactions/VendorMatchFlags'
import { getLabelSuffix } from '../helpers'

const vendorFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput
    sx={{ minWidth: 300 }}
    label="Vendor match flag"
    source="vendor_match_flags"
    choices={VendorMatchFlags}
    emptyText="Show all"
    alwaysOn
  />,
]

const VendorList = () => {
  const { permissions } = usePermissions()
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  return (
    <section>
      <div className="flex items-center justify-between">
        <Title>{t(`vendors${suffix}`)}</Title>
        <div className="p-2 rounded-full bg-white flex items-center justify-center shadow-sm">
          <BuildingStorefrontIcon className="h-6" />
        </div>
      </div>
      <List filters={vendorFilters} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid bulkActionButtons={false} optimized>
          <ShowButton label="" />

          <TextField source="name" />
          <TextField source="territory.name" />

          <TextField source="organisation.name" />
          {permissions & userPermissions.SUPER_ADMIN && (
            <TextField source="company_number" />
          )}
          {permissions & userPermissions.SUPER_ADMIN && (
            <TextField source="credit_score" />
          )}
          <TextField source="group.name" />

          {permissions & userPermissions.SUPER_ADMIN && (
            <ReferenceVendorMapper />
          )}
          <AssignGroupButton />
          <EditButton label="" />
        </Datagrid>
      </List>
    </section>
  )
}

export default VendorList
