import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionTitle } from '../../components/Title'
import MonthlySpendChart from '../charts/MonthlySpendChart'
import MonthlyTransactionChart from '../charts/MonthlyTransactionChart'
import { TotalSpend } from './TotalSpend'
import { TransactionCount } from './TransactionCount'
import { VendorCount } from './VendorCount'
import MonthlyVendorCountChart from '../charts/MonthlyVendorCountChart'
import { getLabelSuffix } from '../../helpers'

const sections = [
  {
    name: 'spend',
    chart: MonthlySpendChart,
    summary: TotalSpend,
  },
  {
    name: 'vendor',
    chart: MonthlyVendorCountChart,
    summary: VendorCount,
  },
  {
    name: 'transaction',
    chart: MonthlyTransactionChart,
    summary: TransactionCount,
  },
]

export const TotalSpendSection = () => {
  const { t } = useTranslation()
  const [selectedSection, setSelectedSection] = useState('spend')
  const suffix = getLabelSuffix()

  return (
    <div className="space-y-6">
      <SectionTitle
        title={t('home.chart_title_1')}
        subtitle={t(`home.chart_desc_1${suffix}`)}
      />
      <div className="flex justify-between divide-x border-t bg-stone-100 border-stone-200/70 divide-stone-200/70 -mx-6">
        {sections.map((section) => (
          <div key={section.name} className="flex-1">
            <section.summary
              onClick={() => setSelectedSection(section.name)}
              key={section.name}
              isSelected={section.name === selectedSection}
            />
          </div>
        ))}
      </div>
      {sections.map((section) => {
        if (section.name !== selectedSection) return null
        return <section.chart key={section.name} />
      })}
    </div>
  )
}
