import { useEffect, useState } from 'react'
import { subYears } from 'date-fns'
import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'

export const useDateRangeFilter = () => {
  const dp = useDataProvider()
  const [ranges, setRanges] = useState<any>(null)
  const [defaultRange, setDefaultRange] = useState<any>(null)

  const { data, isLoading } = useQuery(`dateRangeData`, () => dp.getDateRange())

  useEffect(() => {
    if (
      !isLoading &&
      data &&
      data.data.startDateInvoice &&
      data.data.endDateInvoice
    ) {
      // getDateRange returns the complete date range for the data
      // we want to set the current range (and, by reference, the default range) to the last year of data
      const startDateRange = data.data.startDateFilter
        ? new Date(data.data.startDateFilter)
        : subYears(new Date(data.data.endDateInvoice), 1)
      const endDateRange = data.data.endDateFilter
        ? new Date(data.data.endDateFilter)
        : new Date(data.data.endDateInvoice)
      const startDateDefault = subYears(new Date(data.data.endDateInvoice), 1)
      const endDateDefault = new Date(data.data.endDateInvoice)

      setRanges([
        {
          startDate: startDateRange,
          endDate: endDateRange,
          key: 'selection',
        },
      ])
      setDefaultRange([
        {
          startDate: startDateDefault,
          endDate: endDateDefault,
          key: 'selection',
        },
      ])
    }
  }, [data, isLoading])

  return {
    defaultRange,
    ranges,
    setRanges,
  }
}
