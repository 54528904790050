import { FC } from 'react'

import { GlobeAltIcon } from '@heroicons/react/24/outline'
import { usePagination } from 'react-use-pagination'
import { formatCurrency, formatNumber } from '../../helpers'
import { Pagination } from '../../components/Pagination'

export const VendorGroupsTable: FC<any> = ({
  onClick,
  groupFilters,
  isLoading,
  data,
  t,
  suffix,
  totalPages,
  setCurrentPage,
  currentPage,
}) => {
  const items = data.filter((group) => group.total_spend > 0)
  const {
    endIndex,
    nextEnabled,
    setNextPage,
    startIndex,
    totalItems,
    previousEnabled,
  } = usePagination({
    totalItems: items.length,
    initialPageSize: 10,
  })
  return (
    <div className="border border-stone-200/50 rounded-md overflow-hidden">
      <table className="table-auto table w-full font-medium">
        <thead className="bg-stone-100 py-1">
          <tr className="text-left text-sm">
            <th className="py-2 pr-3 font-semibold pl-6" colSpan={2}>
              {t('name')}
            </th>
            <th className="py-2 pl-3 pr-6 font-semibold text-right">
              {t(`total_spend${suffix}`)}
            </th>
            <th className="py-2 pl-3 pr-6 font-semibold">{t('percentage')}</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr className="text-sm text-center border-b border-stone-200/50 active cursor-pointer hover:bg-stone-100">
              <td className="py-2 pr-3 pl-2 capitalize">Loading...</td>
            </tr>
          ) : (
            data
              .filter((group) => group.total_spend > 0)
              .map((group) => (
                <tr
                  key={group.id}
                  className={`text-sm border-b border-stone-200/50 active cursor-pointer hover:bg-stone-100 ${
                    groupFilters.includes(group.id) ? 'bg-green-100' : ''
                  } `}
                  onClick={() => onClick(group.id, group.vendors)}
                >
                  <td className="w-12 pl-6">
                    <GlobeAltIcon className="w-6" />
                  </td>
                  <td className="py-2 pr-3 pl-2 capitalize">{group.name}</td>
                  <td className="py-3 pl-3 pr-6 font-semibold border-l border-stone-200/50 text-right">
                    {group.total_spend
                      ? formatCurrency(group.total_spend / 100)
                      : formatCurrency(0)}
                  </td>
                  <td className="py-3 pl-3 pr-6 font-semibold border-l border-stone-200/50">
                    {group.percentage > 0
                      ? `${formatNumber(group.percentage)}%`
                      : `${formatNumber(0)}%`}
                  </td>
                </tr>
              ))
          )}
        </tbody>
      </table>
      <div className="px-4 pb-2">
        <Pagination
          canNextPage={nextEnabled}
          canPreviousPage={previousEnabled}
          goToNextPage={setNextPage}
          goToPreviousPage={setNextPage}
          startIndex={startIndex}
          endIndex={endIndex}
          totalItems={totalItems}
        />
      </div>
    </div>
  )
}
