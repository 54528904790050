export const ParetoSpendTooltip = ({ serie, content }) => (
  <div className="rounded shadow p-4 bg-white text-sm border border-stone-100 font-medium">
    <div className="flex items-center space-x-2">
      <div
        className="w-2 h-2 rounded-sm"
        style={{ backgroundColor: serie.color }}
      />
      <span>{content?.group.toFixed(2)}% of spend</span>
    </div>
    <div className="ml-4">
      <div>comes from {content?.left.toFixed(2)}% of vendors</div>
      <div>or {content?.data?.length} total vendors</div>
    </div>
  </div>
)
