import {
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  ListButton,
  NumberInput,
  ShowButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
} from 'react-admin'
import TagsInput from '../components/TagsInput'
import SaveWithConfirmButton from '../components/SaveWithConfirmButton'

const TransactionEditActions = () => (
  <TopToolbar>
    <ShowButton />
    <ListButton />
    {/* <MigrateButton /> */}
  </TopToolbar>
)

const EditToolbar = () => {
  /*
    return (
      <Toolbar>
        <SaveWithConfirmButton saveConfirm={false} />
        <DeleteWithConfirmButton />
      </Toolbar>
    )
    */
  return (
    <Toolbar>
      <SaveWithConfirmButton saveConfirm={false} />
      <DeleteWithConfirmButton />
    </Toolbar>
  )
}

const TransactionEdit = () => {
  const transform = (data) => {
    const tags = data.tags.map((t) => {
      return t.id
    })

    return {
      ...data,
      vendor_id: data.vendor.id,
      tag_ids: tags,
    }
  }

  return (
    <Edit
      mutationMode="pessimistic"
      transform={transform}
      actions={<TransactionEditActions />}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="txn_id" sx={{ minWidth: 320 }} />
        <NumberInput
          source="amount"
          format={(formValue) => formValue / 100}
          parse={(inputValue) => Math.round(inputValue * 100)}
          sx={{ minWidth: 320 }}
        />
        <DateInput source="invoice_date" sx={{ minWidth: 320 }} />

        <TextInput
          source="invoice_vendor_name"
          disabled
          sx={{ minWidth: 320 }}
        />
        <TextInput source="vendor.name" disabled sx={{ minWidth: 320 }} />

        <TagsInput source="tags" />
      </SimpleForm>
    </Edit>
  )
}

export default TransactionEdit
