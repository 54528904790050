import {
  createContext,
  FunctionComponent,
  ReactNode,
  useMemo,
  useState,
} from 'react'
import { subYears } from 'date-fns'
import {
  CUSTOM_PERIOD,
  LAST_QUARTER_OF_UPLOAD,
  LAST_YEAR,
  PRECEDING_PERIOD,
  SAME_PERIOD_LAST_YEAR,
  THIS_QUARTER,
  THIS_YEAR_TO_DATE,
} from '../../constants'

interface CompareOption {
  id: string
  name: string
}

const compareDateOptions: CompareOption[] = [
  { id: '', name: 'Compare with...' },
  { id: PRECEDING_PERIOD, name: 'Preceding period' },
  { id: SAME_PERIOD_LAST_YEAR, name: 'Same period last year' },
  { id: THIS_YEAR_TO_DATE, name: 'This year to date' },
  { id: LAST_YEAR, name: 'Last year' },
  // { id: LAST_YEAR_OF_UPLOAD, name: 'Last year of upload' },
  // { id: LAST_MONTH_OF_UPLOAD, name: 'Last month of upload' },
  { id: THIS_QUARTER, name: 'This quarter' },
  { id: LAST_QUARTER_OF_UPLOAD, name: 'Last quarter' },
  { id: CUSTOM_PERIOD, name: 'Select dates...' },
]

interface ChartContextType {
  dateRange: { startDate: any; endDate: any; key: any }
  compareRange: any
  query: string
  setQuery: Function
  addRule: Function
  removeRules: Function
  clearFilter: Function
  rules: any[]
  compareOptions: CompareOption[]
  selectedCompareOption: CompareOption
  setSelectedCompareOption: Function
}

interface ChartContextProviderProps {
  dateRange: { startDate: any; endDate: any; key: any }
  compareRange: any
  clearFilter: Function
  addRule: Function
  removeRules: Function
  query: string
  setQuery: Function
  children: ReactNode
}

const initialChartContext: ChartContextType = {
  dateRange: {
    startDate: subYears(new Date(), 1),
    endDate: new Date(),
    key: 'selection',
  },
  compareRange: null,
  query: '',
  setQuery: () => {},
  addRule: () => {},
  removeRules: () => {},
  rules: [],
  clearFilter: () => {},
  compareOptions: [],
  selectedCompareOption: compareDateOptions[0],
  setSelectedCompareOption: () => {},
}

const ChartContext = createContext(initialChartContext)

const ChartContextProvider: FunctionComponent<ChartContextProviderProps> = ({
  dateRange,
  compareRange,
  query,
  setQuery,
  addRule,
  removeRules,
  children,
  clearFilter,
}) => {
  const [selectedCompareOption, setSelectedCompareOption] = useState(
    compareDateOptions[0]
  )

  const rules = useMemo(() => {
    if (!query) return []
    const parsedQuery = JSON.parse(query)
    if (!parsedQuery || !parsedQuery.rules) return []
    return parsedQuery.rules
  }, [query])

  const value = useMemo(
    () => ({
      dateRange,
      compareRange,
      query,
      setQuery,
      rules,
      addRule,
      removeRules,
      clearFilter,
      compareOptions: compareDateOptions,
      selectedCompareOption,
      setSelectedCompareOption,
    }),
    [
      dateRange,
      compareRange,
      query,
      setQuery,
      rules,
      addRule,
      removeRules,
      clearFilter,
      selectedCompareOption,
    ]
  )
  return <ChartContext.Provider value={value}>{children}</ChartContext.Provider>
}

export { ChartContext, ChartContextProvider }
