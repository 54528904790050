import { formatDateRangeLabel } from '../helpers'
import useChartContext from './charts/useChartContext'

export const CompareLegend = ({ colors }: { colors: string[] }) => {
  const { selectedCompareOption, dateRange } = useChartContext()

  if (selectedCompareOption.id === '') return null

  return (
    <div className="flex items-center justify-center gap-16 text-xs pt-2">
      {colors.map((color, index) => (
        <div className="flex items-center gap-2 font-medium">
          <div
            key={color}
            className="h-1 rounded-full w-[15px]"
            style={{ backgroundColor: color }}
          />
          {index === 0
            ? formatDateRangeLabel(dateRange.startDate, dateRange.endDate)
            : selectedCompareOption.name}
        </div>
      ))}
    </div>
  )
}
