import * as React from 'react'
import {
  Button,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
  useRefresh,
} from 'react-admin'

import CheckIcon from '@mui/icons-material/Check'
import userPermissions from '../Users/userPermissions'

const ConfirmVendorButton = () => {
  const [loading, setLoading] = React.useState(false)
  const { permissions } = usePermissions()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const { selectedIds, data } = useListContext()

  const handleSubmit = () => {
    if (selectedIds.length === 0) {
      return
    }

    setLoading(true)

    dataProvider
      .transactionConfirmVendor({
        ids: selectedIds,
        organisation_id: data[0].organisation.id,
        data: { confirm: true },
        sort: { field: 'group_id', order: 'ASC' },
      })
      .then(() => {
        notify(`Vendors confirmed`, { type: 'success' })
        refresh()
      })
      .catch((e) => {
        console.log('Error')
        notify(`Vendor confirmation error: ${e.message}`, { type: 'warning' })
      })
      .finally(() => {
        setLoading(false)
      })

    setLoading(false)
  }

  if (!(permissions & userPermissions.EDIT_TRANSACTIONS)) {
    return null
  }

  return (
    <Button
      disabled={loading}
      onClick={() => handleSubmit()}
      label="Confirm Vendor"
    >
      <CheckIcon />
    </Button>
  )
}

export default ConfirmVendorButton
