import { SimpleForm, Edit, useInput, NumberInput, TextInput } from 'react-admin'
import ReactJson from 'react-json-view'

const JsonInput = (props) => {
  const {
    field: { name, onChange, value },
  } = useInput(props)

  return (
    <ReactJson
      src={value || {}}
      name={name}
      onEdit={(edit) => {
        onChange(edit.updated_src)
      }}
      onDelete={(del) => {
        onChange(del.updated_src)
      }}
      onAdd={(add) => {
        onChange(add.updated_src)
      }}
      displayDataTypes={false}
    />
  )
}
const ImportLogEdit = (props) => {
  return (
    <Edit title="Upload Import Log" {...props}>
      <SimpleForm>
        <JsonInput source="column_mapping" label="Column Mapping" />
        <NumberInput label="Import Type" source="import_type" />
        <TextInput label="Date Format" source="date_format" />
      </SimpleForm>
    </Edit>
  )
}

export default ImportLogEdit
