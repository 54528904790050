import { useCallback, useMemo, useState } from 'react'
import { useDataProvider } from 'react-admin'
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  useDroppable,
  DragOverlay,
  closestCorners,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { useTranslation } from 'react-i18next'
import { Square2StackIcon } from '@heroicons/react/24/outline'
import { DASHBOARD_WIDGETS } from '../Dashboard/Dashboard'
import { FIXED_WIDGETS } from '../constants'

import { Button } from './common/Button'

const WidgetComponent = ({ id, fixed }: { id: number; fixed?: boolean }) => {
  const widget = DASHBOARD_WIDGETS.find((w) => w.id === id)
  if (!widget) return null

  return (
    <div
      className={`rounded-lg border border-gray-300 overflow-hidden relative flex gap-2 max-w-[450px] ${
        fixed
          ? 'border-transparent cursor-not-allowed bg-white/70'
          : 'shadow-sm hover:ring-2 hover:ring-indigo-700 cursor-grab bg-white'
      }`}
    >
      <div className="py-4 px-3 space-y-0.5 flex-1">
        <h4 className="font-semibold leading-tight">{widget.name}</h4>
        <p className="text-sm text-gray-500">{widget.description}</p>
      </div>
      <div className="group flex flex-col items-end flex-1 py-2.5 pr-3">
        <img
          alt={widget.description}
          className="h-20 object-cover"
          src={`/chartPreviews/${widget.id}.png`}
        />
      </div>
      {fixed && (
        <div className="absolute bottom-2 right-2 z-10 font-medium text-xs bg-indigo-600 text-white py-1.5 px-2 rounded">
          Fixed
        </div>
      )}
    </div>
  )
}

const SortableItem = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <WidgetComponent id={props.id} />
    </div>
  )
}

const Container = ({ id, items }: { id: string; items: number[] }) => {
  const { setNodeRef } = useDroppable({
    id,
  })

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} className="space-y-2">
        {items.map((itemId) => (
          <SortableItem key={itemId} id={itemId} />
        ))}
      </div>
    </SortableContext>
  )
}

export const WidgetPicker = ({
  orderedWidgets,
  onFinish,
}: {
  orderedWidgets: number[]
  onFinish: Function
}) => {
  const { t } = useTranslation()
  const dp = useDataProvider()
  const [items, setItems] = useState({
    dashboard: orderedWidgets.filter((i) => !FIXED_WIDGETS.includes(i)),
    available: DASHBOARD_WIDGETS.filter(
      (i) => !orderedWidgets.includes(i.id)
    ).map((i) => i.id),
  })

  const allDashboardWidgets = useMemo(
    () => [...FIXED_WIDGETS, ...items.dashboard].filter(Boolean),
    [items.dashboard]
  )

  const handleSave = useCallback(() => {
    dp.setUserWidgets(allDashboardWidgets)
    onFinish()
  }, [allDashboardWidgets, dp, onFinish])

  const [activeId, setActiveId] = useState<number>()
  const sensors = useSensors(useSensor(PointerSensor))

  const findContainer = useCallback(
    (id: string) => {
      if (id in items) {
        return id
      }

      return Object.keys(items).find((key) => items[key].includes(id))
    },
    [items]
  )

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    const { id } = active

    // @ts-ignore
    setActiveId(id)
  }

  const handleDragOver = useCallback(
    (event) => {
      const { active, over, draggingRect } = event
      const { id } = active
      const { id: overId } = over

      // Find the containers
      const activeContainer = findContainer(id)
      const overContainer = findContainer(overId)

      if (
        !activeContainer ||
        !overContainer ||
        activeContainer === overContainer
      ) {
        return
      }

      setItems((prev) => {
        const activeItems = prev[activeContainer]
        const overItems = prev[overContainer]

        // Find the indexes for the items
        const activeIndex = activeItems.indexOf(id)
        const overIndex = overItems.indexOf(overId)

        let newIndex
        if (overId in prev) {
          // We're at the root droppable of a container
          newIndex = overItems.length + 1
        } else {
          const isBelowLastItem =
            over &&
            overIndex === overItems.length - 1 &&
            draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height

          const modifier = isBelowLastItem ? 1 : 0

          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1
        }

        return {
          ...prev,
          [activeContainer]: [
            ...prev[activeContainer].filter((item) => item !== active.id),
          ],
          [overContainer]: [
            ...prev[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...prev[overContainer].slice(newIndex, prev[overContainer].length),
          ],
        }
      })
    },
    [findContainer, items]
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      const { id } = active
      // @ts-ignore
      const { id: overId } = over

      // @ts-ignore
      const activeContainer = findContainer(id)
      const overContainer = findContainer(overId)

      if (
        !activeContainer ||
        !overContainer ||
        activeContainer !== overContainer
      ) {
        return
      }

      const activeIndex = items[activeContainer].indexOf(active.id)
      const overIndex = items[overContainer].indexOf(overId)

      if (activeIndex !== overIndex) {
        setItems((currentItems) => ({
          ...currentItems,
          [overContainer]: arrayMove(
            currentItems[overContainer],
            activeIndex,
            overIndex
          ),
        }))
      }

      setActiveId(undefined)
    },
    [findContainer, items]
  )

  // TODO: When request works, you can replace the available widgets with this call
  //   const dp = useDataProvider()
  //   const { data: availableWidgetData, isLoading: availableWidgetIsLoading } =
  //     useQuery(`availableWidgetData`, () => dp.getAvailableWidgets())

  return (
    <>
      <div className="flex space-x-8">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <div className="px-6 py-4 rounded-lg bg-stone-100 flex-1 space-y-3 max-h-[80vh] overflow-y-auto">
            <h4 className="font-semibold">{t('your_dashboard')}</h4>
            <div className="space-y-2">
              {FIXED_WIDGETS.map((id) => (
                <WidgetComponent id={id} key={id} fixed />
              ))}
            </div>
            <Container id="dashboard" items={items.dashboard} />
          </div>
          <div className="flex-1 space-y-3 pr-4">
            <h4 className="font-semibold">{t('available_widgets')}</h4>
            {items.available.length === 0 && (
              <div className="bg-gray-50/50 p-4 rounded-md text-center text-gray-500 text-sm flex flex-col items-center justify-center h-full border border-dashed">
                <Square2StackIcon className="h-6 rotate-2" />
                <p>{t('no_widgets')}</p>
              </div>
            )}
            <Container id="available" items={items.available} />
          </div>
          <DragOverlay>
            {activeId ? (
              <div className="opacity-70 rotate-2">
                <WidgetComponent id={activeId} />
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
      <div className="flex items-center space-x-3 justify-end sticky bottom-0 z-10 border-t pt-2 bg-stone-50">
        <Button secondary onClick={() => onFinish()}>
          {t('cancel')}
        </Button>
        <Button onClick={handleSave}>{t('save_changes')}</Button>
      </div>
    </>
  )
}
