import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'

import { useMemo } from 'react'
import useChartContext from '../Dashboard/charts/useChartContext'
import { getFormattedDataChart, getFormattedDataWithCompareChart } from '.'

export const useChartCompare = ({
  chartType,
  isCurrency,
  serieName,
  maxLength,
}: {
  chartType: string
  isCurrency: boolean
  serieName: string
  maxLength: number
}) => {
  const cc = useChartContext()
  const dp = useDataProvider()

  const { data, isLoading } = useQuery(
    ['charts', 'getGetChart', chartType, cc.query, cc.dateRange],
    () =>
      dp.getChart(
        chartType,
        { query: cc.query, ...cc.dateRange },
        {
          enabled: !!cc.query,
        }
      )
  )

  const { data: compareData, isLoading: compareDataIsLoading } = useQuery(
    ['charts', 'getGetChart', chartType, cc.query, cc.compareRange],
    () =>
      dp.getChart(
        chartType,
        { query: cc.query, ...cc.compareRange },
        {
          enabled: !!cc.query,
        }
      )
  )

  const chartData = useMemo(() => {
    try {
      const _chartData = getFormattedDataWithCompareChart(
        data?.data,
        compareData?.data,
        cc.dateRange,
        cc.compareRange,
        isCurrency
      )
      return [_chartData[0], _chartData[1]]
    } catch (e) {
      return [[], []]
    }
  }, [data?.data, compareData?.data, cc.dateRange, cc.compareRange, isCurrency])

  const formattedData = useMemo(() => {
    if (!cc.compareRange)
      return [
        {
          id: 'transactionCount',
          data: data
            ? getFormattedDataChart(data.data, cc.dateRange, isCurrency)
            : [],
        },
      ]
    return [
      {
        id: serieName,
        color: 'hsl(181, 70%, 50%)',
        data: chartData[0] || [],
      },
      {
        id: 'compare',
        color: 'hsl(50, 70%, 50%)',
        data: chartData[1] || [],
      },
    ]
  }, [cc.compareRange, cc.dateRange, chartData, data, isCurrency, serieName])

  const isChartLong = useMemo(
    () => formattedData[0].data.length > maxLength,
    [formattedData, maxLength]
  )

  return {
    formattedData,
    isLoading: isLoading || compareDataIsLoading,
    isChartLong,
  }
}
