import {
  ArrayInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
} from 'react-admin'

const TagsInput = ({ source }) => {
  return (
    <ArrayInput source={source}>
      <SimpleFormIterator inline>
        <ReferenceInput
          source="tag_category.id"
          reference="tag_categories"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput
            label="Tag category"
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({
            scopedFormData, // The data for this item of the ArrayInput
            getSource = () => {
              return ''
            }, // A function to get the valid source inside an ArrayInput
          }) => {
            if (
              !scopedFormData ||
              !scopedFormData.tag_category ||
              !scopedFormData.tag_category ||
              !scopedFormData.tag_category.id
            )
              return null

            return (
              <ReferenceInput
                reference="tags"
                source={getSource('id')}
                filter={{
                  tag_category_id: scopedFormData.tag_category.id,
                }}
                enableGetChoices={({ q }) => q && q.length >= 2}
                perPage={1000}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <SelectInput
                  label="Tag name"
                  optionText="name"
                  optionValue="id"
                  fullWidth
                />
              </ReferenceInput>
            )
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default TagsInput
