import { formatCurrency } from '../../helpers'

export const ParetoVendorTooltip = ({ serie, content }) => (
  <div className="rounded shadow p-4 bg-white text-sm border border-stone-100 font-medium">
    <div className="flex items-center space-x-2">
      <div
        className="w-2 h-2 rounded-sm"
        style={{ backgroundColor: serie.color }}
      />
      <span>{content?.left.toFixed(2)}% of vendors</span>
    </div>
    <div className="ml-4">
      <div>Results to {formatCurrency(content?.amount)} of Spend</div>
      <div>or {content?.group.toFixed(2)}% of Spend</div>
      <div>{content?.left === 20 ? '(Tail Spend)' : ''}</div>
    </div>
  </div>
)
