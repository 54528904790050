import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'
import { useMemo, useState } from 'react'
import { usePagination } from 'react-use-pagination'

// import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import useChartContext from './charts/useChartContext'
import { formatCurrency, getLabelSuffix } from '../helpers'
// import { Button } from '../components/common/Button'
import { Pagination } from '../components/Pagination'

const onlyUnique = (value, index, self) => self.indexOf(value) === index

const chartType = 'tagBreakdownSumByVendor'

const PaymentTermsTable = () => {
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState('all')
  const cc = useChartContext()
  const dp = useDataProvider()
  const { t } = useTranslation()
  const suffix = getLabelSuffix()
  const { data, isLoading } = useQuery(
    ['charts', 'getGetChart', chartType, cc.query, cc.dateRange],
    () =>
      dp.getChart(chartType, {
        query: cc.query,
        tag_category_name: 'Payment Terms',
        ...cc.dateRange,
      })
  )

  const paymentTerms = useMemo(() => {
    if (isLoading || !data || !data.data.length) return undefined
    return data.data.map((item) => item.tag.name).filter(onlyUnique)
  }, [data, isLoading])

  const paymentTermsByVendor = useMemo(() => {
    if (isLoading || !data || !data.data.length) return []
    if (selectedPaymentTerm === 'all') return data.data
    return data.data.filter((item) => item.tag.name === selectedPaymentTerm)
  }, [data, isLoading, selectedPaymentTerm])

  const {
    // currentPage,
    // totalPages,
    setNextPage,
    setPreviousPage,
    nextEnabled,
    previousEnabled,
    startIndex,
    endIndex,
    // setPage,
    totalItems,
  } = usePagination({
    totalItems: paymentTermsByVendor.length,
    initialPageSize: 10,
  })

  const visiblePaymentTerms = useMemo(
    () =>
      paymentTermsByVendor.filter((_, i) => i >= startIndex && i <= endIndex),
    [endIndex, paymentTermsByVendor, startIndex]
  )

  if (isLoading)
    return <div className="h-[400px] bg-stone-50 rounded-md animate-pulse" />
  if (isLoading || !data || !data.data.length) return null

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-3">
        <h2 className="font-semibold">{t('payment_terms')}</h2>
        <div className="flex items-center text-xs border rounded font-medium text-slate-700 overflow-hidden shadow-sm divide-x divide-slate-400/20">
          <button
            type="button"
            onClick={() => setSelectedPaymentTerm('all')}
            className={`active cursor-pointer text-center w-16 py-2.5 ${
              selectedPaymentTerm === 'all'
                ? 'bg-slate-50 font-semibold text-slate-900'
                : ''
            }`}
          >
            All
          </button>
          {paymentTerms.map((term) => (
            <button
              key={term}
              type="button"
              onClick={() => setSelectedPaymentTerm(term)}
              className={`active cursor-pointer text-center w-16 py-2.5 ${
                selectedPaymentTerm === term
                  ? 'bg-slate-50 font-semibold text-slate-900'
                  : ''
              }`}
            >
              {term}
            </button>
          ))}
        </div>
      </div>
      <table className="table-auto table w-full">
        <thead className="border-b pb-1">
          <tr className="text-left text-sm">
            <th className="py-2 pr-3 pl-1 font-semibold">{t('company')}</th>
            <th className="py-2 px-3 font-semibold">{t('payment_terms')}</th>
            <th className="py-2 px-3 font-semibold text-right">
              {t(`total_spend${suffix}`)}
            </th>
          </tr>
        </thead>
        <tbody>
          {visiblePaymentTerms.map((item) => (
            <tr
              className="text-sm border-b border-gray-100"
              key={`${item.vendor.name}-${item.tag.name}`}
            >
              <td className="py-2 pr-3 pl-1 capitalize">{item.vendor.name}</td>
              <td className="py-2 px-3 capitalize border-l">{item.tag.name}</td>
              <td className="py-3 px-3 font-semibold border-l border-gray-100 text-right">
                {formatCurrency(item.amount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        totalItems={totalItems}
        canNextPage={nextEnabled}
        canPreviousPage={previousEnabled}
        goToNextPage={setNextPage}
        goToPreviousPage={setPreviousPage}
        startIndex={startIndex}
        endIndex={endIndex}
      />
    </div>
  )
}

export default PaymentTermsTable
