import { useContext } from 'react'
import { ChartContext } from './ChartContextProvider'

function useChartContext() {
  const {
    dateRange,
    compareRange,
    query,
    addRule,
    removeRules,
    clearFilter,
    rules,
    setQuery,
    compareOptions,
    selectedCompareOption,
    setSelectedCompareOption,
  } = useContext(ChartContext)

  return {
    dateRange,
    compareRange,
    query,
    addRule,
    removeRules,
    clearFilter,
    rules,
    setQuery,
    compareOptions,
    selectedCompareOption,
    setSelectedCompareOption,
  }
}

export default useChartContext
