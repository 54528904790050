import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { useLoading, useRefresh } from 'ra-core'
import { MouseEvent, useCallback } from 'react'

export const RefreshButton = () => {
  const refresh = useRefresh()
  const loading = useLoading()

  const handleRefreshClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()

      refresh()
    },
    [refresh]
  )

  return (
    <button
      onClick={handleRefreshClick}
      title="Refresh Data"
      type="button"
      disabled={loading}
      className={`px-3 bg-white rounded border border-gray-300 ${
        loading ? '' : 'shadow-sm active hover:bg-slate-50'
      }`}
    >
      <ArrowPathIcon
        className={`h-4 ${loading ? 'animate-spin opacity-75' : ''}`}
      />
    </button>
  )
}
