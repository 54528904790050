import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { FC, useState } from 'react'
import { Field } from 'react-querybuilder'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { QueryBuilderPayload } from '../helpers/useQueryFilter'
import { Button } from './common/Button'
import { Modal } from './common/Modal'
import QueryBuilder from './QueryBuilder'
import { QuerySummary } from './QuerySummary'
import { CATEGORY_DATA, EVENT_CLICKED_FILTER } from '../constants'

interface Props {
  fields?: Field[]
  queryFilter: QueryBuilderPayload
}

export const QueryBuilderModal: FC<Props> = ({ queryFilter, fields }) => {
  const {
    clearFilter,
    isNewQuery,
    deleteCurrentQuery,
    currentQueryIndex,
    numberOfQueries,
    canGoBack,
    canGoForward,
    goToNextQuery,
    goToPreviousQuery,
    currentQuery,
    updateCurrentQuery,
  } = queryFilter
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
      title="Filter your data"
      renderButton={(onClick) => (
        <QuerySummary
          {...queryFilter}
          t={t}
          onClick={() => {
            ReactGA.event({
              category: CATEGORY_DATA,
              action: EVENT_CLICKED_FILTER,
            })
            onClick()
          }}
        />
      )}
    >
      <div className="min-w-[800px] max-w-[800px] bg-white border rounded-lg divide-y overflow-hidden">
        <div className="flex items-center justify-between text-xs p-3 bg-white">
          <div className="flex items-center space-x-2">
            <div className="flex items-center justify-between rounded-md overflow-hidden shadow-sm border border-gray-300 divide-x">
              <button
                title="Load previous filter"
                disabled={!canGoBack}
                type="button"
                className={`p-2.5 ${
                  canGoBack
                    ? 'hover:bg-slate-50 active bg-white'
                    : 'opacity-70 cursor-default bg-slate-100 text-slate-500'
                }`}
                onClick={() => goToPreviousQuery()}
              >
                <ArrowLeftIcon className="h-3" />
              </button>
              <button
                title="Load next filter"
                disabled={!canGoForward}
                type="button"
                className={`p-2.5 ${
                  canGoForward
                    ? 'hover:bg-slate-50 active bg-white'
                    : 'opacity-70 cursor-default bg-slate-100 text-slate-500'
                }`}
                onClick={() => goToNextQuery()}
              >
                <ArrowRightIcon className="h-3" />
              </button>
            </div>
            <div>
              {isNewQuery &&
                numberOfQueries > 0 &&
                `(${numberOfQueries} saved queries)`}
              {!isNewQuery && (
                <span>
                  {currentQueryIndex + 1} of {numberOfQueries} saved queries
                </span>
              )}
            </div>
          </div>
          <Button
            disabled={isNewQuery}
            secondary
            onClick={() => clearFilter()}
            icon={<PlusIcon className="h-4" />}
          >
            New query
          </Button>
        </div>
        <div className="p-3 space-y-4">
          <h2 className="font-semibold">
            {isNewQuery ? (
              'New query'
            ) : (
              <span>Saved query {currentQueryIndex + 1}</span>
            )}
          </h2>
          <QueryBuilder
            isNew={isNewQuery}
            key={currentQueryIndex}
            onClear={clearFilter}
            onDelete={deleteCurrentQuery}
            onUpdate={updateCurrentQuery}
            query={currentQuery}
            onClose={() => setShowModal(false)}
            fields={fields}
          />
        </div>
      </div>
    </Modal>
  )
}
