import { useCallback, useMemo, useState } from 'react'
import { usePagination } from 'react-use-pagination'

import { useTranslation } from 'react-i18next'
import { ParetoQueryData } from '../../types'
import { BOTTOM_VENDOR_COLOR, TOP_VENDOR_COLOR } from '../colors'
import { formatCurrency, getLabelSuffix } from '../../helpers'

import { Pagination } from '../../components/Pagination'

export const ParetoTable = ({
  topVendors,
  bottomVendors,
}: {
  topVendors: ParetoQueryData
  bottomVendors: ParetoQueryData
}) => {
  const [showTop20, setShowTop20] = useState(true)
  const { t } = useTranslation()
  const suffix = getLabelSuffix()
  const vendorList = useMemo(() => {
    if (!topVendors || !bottomVendors) return []
    return showTop20 ? topVendors.data : bottomVendors.data
  }, [bottomVendors, topVendors, showTop20])

  const {
    setNextPage,
    setPreviousPage,
    nextEnabled,
    previousEnabled,
    startIndex,
    endIndex,
    setPage,
    totalItems,
  } = usePagination({
    totalItems: vendorList.length,
    initialPageSize: 15,
  })

  const setTab = useCallback(
    (val: boolean) => {
      setShowTop20(val)
      setPage(0)
    },
    [setPage]
  )

  const visibleItems = useMemo(
    () => vendorList.filter((_, i) => i >= startIndex && i <= endIndex),
    [endIndex, vendorList, startIndex]
  )

  return (
    <div className="overflow-hidden space-y-4">
      <div className="flex bg-stone-100 border items-center space-x-1 text-sm p-1 rounded-md">
        <button
          onClick={() => setTab(true)}
          type="button"
          className={`px-3 py-2.5 rounded transition-all active inline-flex space-x-1.5 items-center justify-center flex-1 ${
            showTop20 ? 'bg-white font-medium' : ''
          }`}
        >
          <div
            className="w-2 h-2 rounded-sm"
            style={{ backgroundColor: TOP_VENDOR_COLOR }}
          />
          <span>{t(`top_20${suffix}`)}</span>
        </button>
        <button
          onClick={() => setTab(false)}
          type="button"
          className={`px-3 py-2.5 rounded transition-all active inline-flex space-x-1.5 items-center justify-center flex-1 ${
            !showTop20 ? 'bg-white font-medium' : ''
          }`}
        >
          <div
            className="w-2 h-2 rounded-sm"
            style={{ backgroundColor: BOTTOM_VENDOR_COLOR }}
          />
          <span>{t('bottom_80')}</span>
        </button>
      </div>
      <div className="text-sm space-y-4">
        <div className="space-y-3">
          {visibleItems.map((vendor) => (
            <div className="flex items-center justify-between flex-1">
              <div className="flex items-center space-x-2">
                <div
                  className="w-2 h-2 rounded-sm"
                  style={{
                    backgroundColor: showTop20
                      ? TOP_VENDOR_COLOR
                      : BOTTOM_VENDOR_COLOR,
                  }}
                />
                <span>{vendor.vendor}</span>
              </div>
              <div className="font-medium">
                {formatCurrency(vendor.amount)} ({vendor.group.toFixed(2)}%)
              </div>
            </div>
          ))}
        </div>
        <div>
          <Pagination
            goToNextPage={setNextPage}
            goToPreviousPage={setPreviousPage}
            canPreviousPage={previousEnabled}
            canNextPage={nextEnabled}
            startIndex={startIndex}
            endIndex={endIndex}
            totalItems={totalItems}
          />
        </div>
      </div>
    </div>
  )
}
