import { ResponsiveChoropleth } from '@nivo/geo'
import Flag from 'react-world-flags'

import { FC, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useDataProvider } from 'react-admin'
import { useTranslation } from 'react-i18next'
import { toInteger } from 'lodash'
import { COLOR_PALETTE_MAP } from '../colors'
import {
  formatCurrencyCompact,
  formatNumber,
  getLabelSuffix,
} from '../../helpers'
import { features } from './features'

import { Card } from '../../components/Card'
import useChartContext from '../charts/useChartContext'

interface Props {
  dataType: 'spending' | 'transactions'
}

export const VendorMap: FC<Props> = ({ dataType }) => {
  const cc = useChartContext()
  const dp = useDataProvider()
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  const { data, isLoading }: any = useQuery(
    ['charts', 'getGetChart', 'country', cc.query, dataType, cc.dateRange],
    () => dp.getChart('country', { query: cc.query, dataType, ...cc.dateRange })
  )
  const total = useMemo(() => {
    if (isLoading || !data) {
      return 0
    }
    return data.data.reduce((prev, curr) => prev + curr.value, 0)
  }, [data, isLoading])

  const highest = useMemo(() => {
    if (isLoading || !data) {
      return null
    }
    return data.data.reduce(
      (prev, curr) => (curr.value > prev ? curr.value : prev),
      0
    )
  }, [data, isLoading])

  const top5 = useMemo(() => {
    if (isLoading || !data) return []
    return data.data.sort((a, b) => b.value - a.value).slice(0, 5)
  }, [data, isLoading])

  const mapData = useMemo(() => {
    if (isLoading || !data) return []
    return data.data
  }, [data, isLoading])

  return (
    <div className="relative">
      <div className="absolute inset-4 flex items-center z-10 justify-between pointer-events-none">
        <Card className="pt-3 pb-2 pointer-events-auto">
          <h4 className="font-semibold text-sm mb-3">
            {dataType === 'spending'
              ? t('top_by_spend')
              : t(`top_by_transactions${suffix}`)}
          </h4>
          <ul className="divide-y -mx-6 border-t">
            {top5.map((row) => {
              const percentage = (row.value * 100) / total
              return (
                <li
                  className="text-sm w-56 space-y-1 py-3 px-6 hover:bg-slate-50"
                  key={row.id}
                >
                  <div className="flex items-center justify-between font-medium">
                    <div className="flex items-center space-x-2">
                      <Flag code={row.id} className="w-6 shadow-sm" />
                      <span>{row.id}</span>
                    </div>
                    <span className="font-semibold">
                      {dataType === 'spending'
                        ? formatCurrencyCompact(row.value)
                        : toInteger(row.value * 100)}
                    </span>
                  </div>
                  <div className="flex justify-between space-x-3 items-center">
                    <div className="bg-gray-200 rounded-full h-2 relative overflow-hidden flex-1">
                      <div
                        className="h-2 bg-stone-700 absolute left-0 top-0 bottom-0"
                        style={{ width: `${percentage}%` }}
                      />
                    </div>
                    <div className="font-medium w-12 text-right">
                      {percentage.toFixed(2)}%
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </Card>
        <div className="flex space-x-2">
          <div className="flex flex-col justify-between items-end text-xs font-medium">
            <span>
              {dataType === 'spending'
                ? formatCurrencyCompact(highest)
                : formatNumber(highest)}
            </span>
            <span>0</span>
          </div>
          <div className="flex flex-col-reverse rounded-full overflow-hidden">
            {COLOR_PALETTE_MAP.map((color) => (
              <div
                className="h-6 w-2"
                style={{ backgroundColor: color }}
                key={color}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="h-[540px]">
        <ResponsiveChoropleth
          data={mapData}
          domain={[0, highest]}
          colors={COLOR_PALETTE_MAP}
          features={features}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          projectionScale={190}
          projectionTranslation={[0.5, 0.67]}
          projectionRotation={[0, 0, 0]}
          fillColor="#ffffff"
          borderWidth={0.7}
          borderColor="#7cd4fc"
          enableGraticule
          graticuleLineColor="#bbe7fc"
          unknownColor="#ffffff"
          label="properties.name"
          valueFormat={dataType === 'spending' ? '.2s' : ''}
        />
      </div>
    </div>
  )
}
