import {
  // Button,
  Datagrid,
  DeleteButton,
  List,
  TextField,
  usePermissions,
  // useNotify,
  // usePermissions,
  // useRecordContext,
  // useStore,
} from 'react-admin'
// import { useQueryClient } from 'react-query'
// import MergeIcon from '@mui/icons-material/Merge'
import { useTranslation } from 'react-i18next'
import { Title } from '../components/Title'
import userPermissions from '../Users/userPermissions'

// const WorkAsButton = () => {
//   const record = useRecordContext()
//   const defaultActing: { organisationId: any; name: string } = {
//     organisationId: 0,
//     name: '',
//   }
//
//   // eslint-disable-next-line no-unused-vars
//   const [actingAs, setActingAs] = useStore(
//     'organisation.actingAs',
//     defaultActing
//   )
//
//   const notify = useNotify()
//
//   const queryClient = useQueryClient()
//
//   const setOrganisation = (e) => {
//     e.stopPropagation()
//
//     notify(`Organisation changed to ${record.name}`, {
//       type: 'success',
//       undoable: false,
//     })
//
//     queryClient.invalidateQueries()
//     setActingAs({ organisationId: record.id, name: record.name })
//   }
//
//   return (
//     <Button onClick={(e) => setOrganisation(e)} label="Work as">
//       <MergeIcon />
//     </Button>
//   )
// }

const OrganisationList = () => {
  const { permissions } = usePermissions()
  const { t } = useTranslation()

  return (
    <section>
      <Title>{t('organisations')}</Title>
      <List>
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="id" label={t('id')} />
          <TextField source="name" label={t('name')} />
          <TextField
            source="currency.code"
            label={t('currency')}
            sortable={false}
          />
          {permissions & userPermissions.SUPER_ADMIN && (
            <DeleteButton mutationMode="pessimistic" />
          )}
        </Datagrid>
      </List>
    </section>
  )
}

export default OrganisationList
