import { fetchUtils } from 'ra-core'

const getAccessToken = () => {
  const token = localStorage.getItem('access_token')
  return Promise.resolve(token)
}

const externalHttpClient = (url, options: any = {}) => {
  const noptions = options

  if (!noptions.headers) {
    noptions.headers = new Headers({ Accept: 'application/json' })
  }
  return getAccessToken().then((token) => {
    if (token) {
      noptions.headers.set('Authorization', `Bearer ${token}`)
    }
    return fetchUtils.fetchJson(url, noptions)
  })
}

export default externalHttpClient
