import { XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export type Segment = {
  label: string
  color: string
  amount: number
}

export const SEGMENT_DEFAULT_COLORS = {
  green: '#afc949',
  yellow: '#ebc346',
  red: '#e66262',
}

export const SegmentChart = ({
  segments,
  selected,
  onSelect,
}: {
  segments: Segment[]
  selected?: string
  onSelect: (s?: string) => void
}) => {
  const { t } = useTranslation()
  const total = useMemo(
    () =>
      segments.reduce((acc, segment) => {
        return acc + segment.amount
      }, 0),
    [segments]
  )

  const items = useMemo(() => segments.filter((s) => s.amount > 0), [segments])

  return (
    <div className="py-2 space-y-2">
      <div className="flex gap-2">
        {items.map((segment, i) => {
          const percentage = Math.round((segment.amount / total) * 100)
          return (
            <div
              className={classNames(
                'relative text-center group active cursor-pointer hover:scale-[1.02] transition-all pt-4',
                {
                  'opacity-40': selected && selected !== segment.label,
                }
              )}
              key={segment.label}
              style={{
                width: `${percentage}%`,
              }}
              onClick={() => onSelect(segment.label)}
            >
              <div
                className={classNames(
                  'text-sm font-medium text-stone-500 absolute right-1 left-1 bottom-14 flex capitalize leading-4',
                  {
                    'text-left justify-start': i === 0,
                    'text-right justify-end': i === items.length - 1,
                    'justify-center': i > 0 && i < items.length - 1,
                  }
                )}
              >
                <span>{segment.label}</span>
              </div>
              <div
                className="h-3 rounded-full transition-all w-full"
                style={{
                  backgroundColor: segment.color,
                }}
              />
              <span className="pt-2 inline-flex flex-col gap-1 items-center">
                <span className="text-stone-700 font-semibold text-sm leading-none">
                  {segment.amount}
                </span>
                <span className="font-normal text-xs text-stone-500 leading-none">
                  {percentage}%
                </span>
              </span>
            </div>
          )
        })}
      </div>
      <div className="flex justify-center">
        {selected && (
          <button
            onClick={() => onSelect(undefined)}
            type="button"
            className="active px-3 py-1.5 rounded-full font-medium text-xs bg-stone-50 border border-indigo-600 ring-2 ring-indigo-100 hover:border-gray-300 hover:bg-white hover:shadow"
          >
            <XMarkIcon className="h-3 inline-block pr-2" />
            {t('clear_selection')}
          </button>
        )}
      </div>
    </div>
  )
}

export const SegmentChartLoading = () => (
  <div className="py-2">
    <div className="flex items-center justify-between pt-4">
      <div className="bg-gray-100 rounded-xl w-6 h-4" />
      <div className="bg-gray-100 rounded-xl w-8 h-4" />
      <div className="bg-gray-100 rounded-xl w-4 h-4" />
    </div>
    <div className="pt-2">
      <div className="w-full h-3 bg-gray-200 rounded-full" />
    </div>
    <div className="flex items-center justify-between pt-1">
      <div className="bg-gray-100 rounded-xl w-6 h-4" />
      <div className="bg-gray-100 rounded-xl w-12 h-4" />
      <div className="bg-gray-100 rounded-xl w-4 h-4" />
    </div>
  </div>
)
