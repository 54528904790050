import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin'
import { useTranslation } from 'react-i18next'

const OrganisationShow = () => {
  const { t } = useTranslation()
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label={t('summary')}>
          <TextField label={t('id')} source="id" />
          <TextField source="name" label={t('name')} />
          <TextField source="currency.code" label={t('currency')} />
          {/* <TextField source="import_config" /> */}
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default OrganisationShow
