import { ValueEditorProps } from 'react-querybuilder'
import { addDays, format } from 'date-fns'
import { useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

const DateRangeValueEditor = (props: ValueEditorProps) => {
  const dateFormat = 'yyyy-MM-dd'

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ])

  return (
    <DateRangePicker
      onChange={({ selection }) => {
        props.handleOnChange(
          [
            !selection.startDate ? '' : format(selection.startDate, dateFormat),
            !selection.endDate ? '' : format(selection.endDate, dateFormat),
          ].join(',')
        )
        setState([selection])
      }}
      showSelectionPreview
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
      preventSnapRefocus
      calendarFocus="backwards"
    />
  )
}

export default DateRangeValueEditor
