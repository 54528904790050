import { useMemo, useState } from 'react'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import { SectionTitle } from '../../components/Title'
import { formatCurrency, getLabelSuffix } from '../../helpers'
import { NoData } from '../NoData'
import { SegmentChart } from '../../components/charts/SegmentChart'
import { PAGE_SIZE } from '../../constants'
import { useCreditScoreData } from '../../helpers/useCreditScoreData'

type CreditScoreLine = {
  id: number
  credit_score: number
  name: string
  spend: number
}

const CreditScoreTable = ({ data }: { data: CreditScoreLine[] }) => {
  const { t } = useTranslation()
  const suffix = getLabelSuffix()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: t('name'),
        headerClassName: 'bg-stone-100',
      },
      {
        field: 'credit_score',
        headerName: t('credit_score'),
        headerClassName: 'bg-stone-100',
      },
      {
        field: 'spend',
        flex: 0.5,
        headerName: t(`total_spend${suffix}`),
        headerClassName: 'bg-stone-100',
        renderCell: (params: GridRenderCellParams<Date>) => (
          <div>{formatCurrency(params.value / 100)}</div>
        ),
      },
    ],
    [suffix, t]
  )

  return (
    <DataGrid
      rows={data}
      disableColumnMenu
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: PAGE_SIZE,
          },
        },
        sorting: {
          sortModel: [
            {
              field: 'credit_score',
              sort: 'asc',
            },
          ],
        },
      }}
      pageSizeOptions={[PAGE_SIZE]}
      disableRowSelectionOnClick
    />
  )
}

const CreditScoreLoading = () => {
  return (
    <div className="w-full space-y-6">
      <div className="rounded-full h-4 bg-stone-100 w-full" />
      <div className="rounded-lg h-[300px] bg-stone-100 w-full" />
    </div>
  )
}

export const CreditScoreAnalysisSection = ({ isDnb }: { isDnb?: boolean }) => {
  const { t } = useTranslation()

  const [selectedCreditScore, setSelectedCreditScore] = useState<string>()

  const { segments, tableData, isLoading, hasData } = useCreditScoreData(
    !!isDnb,
    selectedCreditScore
  )

  return (
    <div className="space-y-6">
      <SectionTitle
        title={t(isDnb ? 'home.chart_title_3_alt' : 'home.chart_title_3')}
        subtitle={t('home.chart_desc_3')}
      />

      {isLoading ? (
        <CreditScoreLoading />
      ) : (
        <>
          {segments && (
            <SegmentChart
              segments={segments}
              selected={selectedCreditScore}
              onSelect={setSelectedCreditScore}
            />
          )}
          {tableData && hasData && <CreditScoreTable data={tableData} />}
          {!hasData && <NoData />}
        </>
      )}
    </div>
  )
}
