import * as React from 'react'
import {
  AutocompleteInput,
  Button,
  Form,
  ReferenceInput,
  SaveButton,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'

import MergeIcon from '@mui/icons-material/Merge'
import CancelIcon from '@mui/icons-material/Cancel'

import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import { GridCloseIcon } from '@mui/x-data-grid'

const MigrateTransactionDialog = ({ open, handleClose }) => {
  const [loading, setLoading] = React.useState(false)
  const dp = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleSubmit = (values) => {
    setLoading(true)
    const data = {
      vendor_id: values.id,
      group_id: values.group.id,
    }
    dp.assignGroup({ data })
      .then((resp) => {
        notify(`Vendor changed`, { type: 'success' })
        refresh()
        handleClose(resp)
      })
      .catch((e) => {
        console.log('Error')
        notify(`Vendor change error: ${e.message}`, { type: 'warning' })
      })
      .finally(() => {
        setLoading(false)
      })

    setLoading(false)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Assign Vendor to Group</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <ReferenceInput
                label="Groups"
                source="group.id"
                reference="groups"
                sort={{ field: 'group_id', order: 'ASC' }}
                enableGetChoices={({ q }) => q && q.length >= 3}
              >
                <AutocompleteInput
                  optionText="name"
                  optionValue="id"
                  label="Group"
                  shouldRenderSuggestions={(val) => {
                    return val.trim().length >= 3
                  }}
                  noOptionsText="Start typing name to find group"
                  helperText="Enter 3 characters to start searching"
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12}>
              <Button disabled={loading} onClick={handleClose} label="Cancel">
                <CancelIcon />
              </Button>

              <SaveButton disabled={loading} />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const AssignGroupButton = () => {
  const record = useRecordContext()
  const [openDialog, setOpenDialog] = React.useState(false)
  const dp = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleUnAssign = () => {
    const data = {
      vendor_id: record.id,
      group_id: null,
    }
    dp.assignGroup({ data })
      .then(() => {
        notify(`Vendor group unassigned`, { type: 'success' })
        refresh()
        setOpenDialog(false)
      })
      .catch((e: any) => {
        console.log('Error')
        notify(`Vendor change error: ${e.message}`, { type: 'warning' })
      })
      .finally(() => {})
  }

  return (
    <>
      <MigrateTransactionDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false)
        }}
      />
      {record.group ? (
        <Button onClick={handleUnAssign} label="Unassign Group">
          <GridCloseIcon />
        </Button>
      ) : (
        <Button
          onClick={() => {
            setOpenDialog(true)
          }}
          label="Assign Group"
        >
          <MergeIcon />
        </Button>
      )}
    </>
  )
}

export default AssignGroupButton
