import { AutocompleteInput, Form, ReferenceInput } from 'react-admin'
import { ValueEditorProps } from 'react-querybuilder'

const TerritoryValueEditor = (props: ValueEditorProps) => {
  const handleChange = (value) => {
    props.handleOnChange(value)
  }

  const defaultValues = {
    id: null,
  }

  if (props.field && props.value) {
    defaultValues.id = props.value
  }

  return (
    <Form defaultValues={defaultValues}>
      <ReferenceInput label="Country" source="id" reference="territories">
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          label="Country name"
          fullWidth
          onChange={handleChange}
        />
      </ReferenceInput>
    </Form>
  )
}

export default TerritoryValueEditor
