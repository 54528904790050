import { Fragment, useMemo } from 'react'
import { Tab } from '@headlessui/react'
import { BuildingStorefrontIcon } from '@heroicons/react/24/outline'

import { useTranslation } from 'react-i18next'
import useChartContext from './charts/useChartContext'
import { Card } from '../components/Card'
import PaymentTermsTable from './PaymentTermsTable'
import { Button } from '../components/common/Button'
import { TopVendors } from './TopVendors'
import { VENDOR_TAG_ID } from '../constants'
import { SectionTitle } from '../components/Title'
import { getLabelSuffix } from '../helpers'

export const VendorsSection = () => {
  const { t } = useTranslation()
  const { clearFilter, rules } = useChartContext()
  const suffix = getLabelSuffix()

  const vendorFilterApplied = useMemo(
    () => rules.filter((rule) => rule.field === VENDOR_TAG_ID).length > 0,
    [rules]
  )

  if (vendorFilterApplied)
    return (
      <div className="relative">
        <div className="grid grid-cols-2 gap-6 opacity-30 pointer-events-none">
          <Card className="h-[600px] space-y-4">
            <div className="h-10 bg-slate-100 rounded-lg w-32" />
            {new Array(10).fill('').map((_, i) => (
              <div
                className="h-8 bg-slate-100 rounded-lg"
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              />
            ))}
          </Card>
          <Card className="h-[600px] space-y-4">
            <div className="h-10 bg-slate-100 rounded-lg w-32" />
            {new Array(10).fill('').map((_, i) => (
              <div
                className="h-8 bg-slate-100 rounded-lg"
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              />
            ))}
          </Card>
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-white rounded-xl shadow-sm p-8 flex flex-col space-y-4 items-center justify-center border hover:shadow text-center">
            <div className="p-3 rounded-xl bg-green-200 flex items-center justify-center">
              <BuildingStorefrontIcon className="h-8" />
            </div>
            <div className="space-y-1">
              <h3 className="text-xl font-semibold">
                {t(`vendor_filter_applied${suffix}`)}
              </h3>
              <p className="text-slate-600 text-sm">
                {t(`clear_vendor_filter${suffix}`)}
              </p>
            </div>
            <Button onClick={() => clearFilter()}>{t('clear_filter')}</Button>
          </div>
        </div>
      </div>
    )

  return (
    <div>
      <Tab.Group>
        <div className="flex items-center justify-between pb-6 px-6 -mx-6 mb-6 border-b border-stone-200/70">
          <SectionTitle
            title={t('home.chart_title_7')}
            subtitle={t(`home.chart_desc_7${suffix}`)}
          />
          <Tab.List className="flex bg-stone-100 border items-center space-x-1 text-sm p-1 rounded-md">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={`px-3 py-2.5 rounded transition-all font-semibold active
              ${
                selected
                  ? 'bg-white'
                  : 'text-stone-500 hover:text-stone-700 hover:bg-white/70'
              }
                  `}
                >
                  {t('top_10_by_spending')}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={`px-3 py-2.5 rounded transition-all font-semibold active
              ${
                selected
                  ? 'bg-white'
                  : 'text-stone-500 hover:text-stone-700 hover:bg-white/70'
              }
                  `}
                >
                  {t(`top_10_by_transactions${suffix}`)}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={`px-3 py-2.5 rounded transition-all font-semibold active
              ${
                selected
                  ? 'bg-white'
                  : 'text-stone-500 hover:text-stone-700 hover:bg-white/70'
              }
              `}
                >
                  {t('payment_terms')}
                </button>
              )}
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels>
          <Tab.Panel>
            <TopVendors dataType="spending" />
          </Tab.Panel>
          <Tab.Panel>
            <TopVendors dataType="transactions" />
          </Tab.Panel>
          <Tab.Panel>
            <PaymentTermsTable />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
