import { PointTooltipProps, ResponsiveLine, Serie } from '@nivo/line'
import { useCallback, useMemo } from 'react'
import {
  formatCurrency,
  formatCurrencyCompact,
  formatNumber,
} from '../../helpers'

const theme = {
  grid: {
    line: {
      stroke: '#f0f0f0',
    },
  },
}

const Tooltip = ({ date, itemName, value, color }) => (
  <div className="rounded shadow p-3 bg-white text-sm border border-stone-100 font-medium space-x-3 flex items-stretch">
    <div className="h-16 w-1 rounded-md" style={{ backgroundColor: color }} />
    <div className="flex-1">
      <div className="italic text-stone-500">{itemName}</div>

      <h3 className="font-medium">{date}</h3>
      <h4 className="font-semibold text-lg">{value}</h4>
    </div>
  </div>
)

export const PriceEvolutionChart = ({
  data,
  itemName,
  isPrice,
}: {
  data: { amount: number; month: string }[]
  itemName: string
  isPrice: boolean
}) => {
  const renderTooltip = useCallback(
    (props: PointTooltipProps) => {
      const { data: pointData, serieColor } = props.point
      return (
        <Tooltip
          color={serieColor}
          date={pointData.xFormatted}
          itemName={itemName}
          value={
            isPrice ? formatCurrency(pointData.y) : formatNumber(pointData.y)
          }
        />
      )
    },
    [isPrice, itemName]
  )

  const chartData: Serie[] = useMemo(() => {
    return [
      {
        id: 'Evolution',
        data: data.map((p) => ({
          x: p.month,
          y: p.amount,
        })),
      },
    ]
  }, [data])

  return (
    <ResponsiveLine
      data={chartData}
      curve="monotoneX"
      theme={theme}
      enablePoints
      enableArea
      margin={{
        top: 15,
        right: 35,
        bottom: 50,
        left: 50,
      }}
      lineWidth={3}
      pointSize={6}
      pointColor="white"
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      axisLeft={{
        format: (value) =>
          isPrice ? formatCurrencyCompact(value) : formatNumber(value),
      }}
      axisBottom={{
        tickRotation: 40,
      }}
      colors={isPrice ? ['#4338ca'] : ['#eab308']}
      areaOpacity={0.1}
      areaBlendMode="normal"
      useMesh
      tooltip={renderTooltip}
      enableCrosshair={false}
      enableGridX={false}
    />
  )
}
