import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  List,
  TextField,
  TopToolbar,
  EditButton,
} from 'react-admin'

import Box from '@mui/material/Box'

import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Title } from '../components/Title'

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
)

const Empty = () => (
  <Box textAlign="center" m={1}>
    <CreateButton />
  </Box>
)

const ImportLogList = () => {
  const { t } = useTranslation()

  // @ts-ignore
  return (
    <section>
      <div className="flex items-center justify-between">
        <Title>{t('import_logs')}</Title>
        <div className="p-2 rounded-full bg-white flex items-center justify-center shadow-sm">
          <CloudArrowUpIcon className="h-6" />
        </div>
      </div>
      <List actions={<ListActions />} empty={<Empty />}>
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="id" emptyText="n/a" />
          <TextField source="filename" emptyText="n/a" />
          <TextField source="user.name" emptyText="n/a" />
          <TextField source="organisation.name" emptyText="n/a" />
          <DateField source="created" label="Date uploaded" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </section>
  )
}

export default ImportLogList
