export const Tagline = ({ floating = false }) => (
  <div
    className={`text-center text-xs ${
      floating ? 'fixed bottom-4 left-0 right-0' : ''
    }`}
  >
    © {new Date().getFullYear()}{' '}
    <a
      target="_blank"
      className="text-indigo-700 font-medium hover:underline"
      href="https://www.procurato.co.uk/"
      rel="noreferrer"
    >
      Procurato
    </a>
  </div>
)
