import { SelectInput, SimpleForm, useDataProvider } from 'react-admin'
import { useTranslation } from 'react-i18next'
import { Card } from '@mui/material'
import { useQuery } from 'react-query'
import { Title } from '../components/Title'
import PasswordEdit from './PasswordEdit'
import ProfileEdit, { MyToolbar } from './ProfileEdit'

const LANGUAGES = [
  { id: 'en', name: 'English' },
  { id: 'it', name: 'Italian' },
  { id: 'es', name: 'Spanish' },
  { id: 'pl', name: 'Polish' },
]

export const ProfilePage = () => {
  const { t, i18n } = useTranslation()
  const dp = useDataProvider()

  const { data } = useQuery(`getUserProfile`, () => dp.getProfile())

  const handleSubmit = async (values) => {
    console.log(values)
    try {
      const response = await dp.updateProfile({ data: { lang: values.locale } })
      console.log(response)
      i18n.changeLanguage(values.locale)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <section className="space-y-12">
      <Title>{t('your_profile')}</Title>
      <div className="space-y-10">
        <div>
          <h2 className="font-semibold">{t('edit_profile')}</h2>
          <ProfileEdit />
        </div>
        <div className="space-y-4">
          <h2 className="font-semibold">{t('edit_password')}</h2>
          <PasswordEdit userId={null} />
        </div>
        {data && data.data && (
          <div className="space-y-4">
            <h2 className="font-semibold">{t('change_language')}</h2>
            <Card>
              <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar />}>
                <SelectInput
                  source="locale"
                  defaultValue={data.data.lang}
                  sx={{ minWidth: 320 }}
                  choices={LANGUAGES}
                />
              </SimpleForm>
            </Card>
          </div>
        )}
      </div>
    </section>
  )
}
