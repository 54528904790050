import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'

const OrganisationCreate = () => {
  const transform = (data) => {
    try {
      const jsonData = JSON.parse(data.import_config)
      data.import_config = JSON.stringify(jsonData)
      console.log(data)
      return data
    } catch (e) {
      return data
    }
  }

  return (
    <Create transform={transform}>
      <SimpleForm>
        <h2 className="font-semibold mb-4">Create a new organisation</h2>
        <TextInput source="name" sx={{ minWidth: 300 }} />
        <TextInput source="import_config" multiline fullWidth />
        <ReferenceInput source="currency_id" reference="currencies">
          <SelectInput label="Currency" optionText="name" optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default OrganisationCreate
