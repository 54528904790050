import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDataProvider } from 'react-admin'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Listbox } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

import { formatCurrency, formatNumber } from '../helpers'
import {
  PriceDashboard,
  PriceDashboardResponse,
  PriceTagOption,
} from '../types'

import { SectionTitle } from '../components/Title'
import { PriceEvolutionChart } from './charts/PriceEvolutionChart'
import { ItemSupplierTable } from './tables/ItemSupplierTable'
import useChartContext from './charts/useChartContext'

const formatPrice = (price: number) => price / 100

export const PricingSection = ({
  minPrice,
  maxPrice,
  avgPrice,
}: {
  minPrice: number
  maxPrice: number
  avgPrice: number
}) => {
  const difference = maxPrice - minPrice
  const percentageDifference = (Math.abs(difference) / Math.abs(minPrice)) * 100

  return (
    <div className="flex justify-start items-center group">
      <div className="px-6 py-3.5 border rounded-l-md border-r-0 space-y-0.5 shadow-sm group-hover:shadow transition-all bg-white">
        <h3 className="text-xl font-semibold">
          {formatCurrency(minPrice, '', 2)}
        </h3>
        <h4 className="text-sm opacity-70">Min. Price</h4>
      </div>
      <div className="px-6 py-5 border rounded-md shadow-sm group-hover:shadow transition-all text-center bg-white">
        <h3 className="text-xl font-semibold">
          {formatCurrency(avgPrice, '', 2)}
        </h3>
        <h4 className="text-sm opacity-70">Avg. Price</h4>
      </div>
      <div className="px-6 py-3.5 border rounded-r-md border-l-0 shadow-sm relative group-hover:shadow transition-all text-right bg-white">
        <div className="space-y-0.5">
          <h3 className="text-xl font-semibold">
            {formatCurrency(maxPrice, '', 2)}
          </h3>
          <h4 className="text-sm opacity-70">Max. Price</h4>
        </div>
        <div className="absolute -bottom-4 left-0 right-0 flex justify-center">
          {percentageDifference > 0 && (
            <div
              className="z-10 px-1.5 py-1 rounded shadow-sm bg-white text-xs text-indigo-700 font-semibold border border-indigo-700"
              title={`${percentageDifference} more expensive than the minimum`}
            >
              {percentageDifference.toFixed(2)}% higher
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const PriceTrackingDetail = ({
  tagId,
  itemName,
}: {
  tagId: string
  itemName: string
}) => {
  const dp = useDataProvider()
  const { data, isLoading } = useQuery<PriceDashboardResponse>(
    ['getPriceDashboard'],
    () => dp.getPriceDashboard(tagId)
  )
  const [chartType, setChartType] = useState<
    'quantityEvolution' | 'priceEvolution'
  >('priceEvolution')

  const formattedData: PriceDashboard | undefined = useMemo(() => {
    if (!data || !data.data) return undefined

    return {
      minPrice: formatPrice(data.data.min_price),
      maxPrice: formatPrice(data.data.max_price),
      avgPrice: formatPrice(data.data.avg_price),
      totalSpend: formatPrice(data.data.total_spend),
      totalQuantity: data.data.total_quantity,
      totalSpendQuantity: data.data.total_spend_over_qty,
      priceOverTime: data.data.price_over_time.map((p) => ({
        amount: formatPrice(p.amount),
        month: p.month,
      })),
      quantityOverTime: data.data.quantity_over_time,
      suppliers: data.data.suppliers.map((s) => ({
        minPrice: formatPrice(s.min_price),
        maxPrice: formatPrice(s.max_price),
        avgPrice: formatPrice(s.avg_price),
        totalQuantity: s.total_quantity,
        vendorName: s.vendor_name,
      })),
    }
  }, [data])

  if (!formattedData || isLoading) return null

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between space-x-12 px-6 py-4 -mx-6 bg-stone-100/40 border-b">
        <div className="space-y-1 flex-1">
          <h2 className="font-semibold">Pricing</h2>
          <PricingSection {...formattedData} />
        </div>
        <div className="space-y-3 flex-1">
          <h2 className="font-semibold text-right">Spending</h2>
          <div className="flex-1 flex justify-end">
            <div className="border rounded-md divide-x items-center flex shadow-sm overflow-hidden">
              <div className="px-5 py-3.5 text-left space-y-0.5 bg-white">
                <h3 className="text-xl font-semibold">
                  {formatNumber(formattedData.totalQuantity)}
                </h3>
                <h4 className="text-sm opacity-70">Total Quantity</h4>
              </div>
              <div className="px-5 py-3.5 space-y-0.5 bg-white text-center">
                <h3 className="text-xl font-semibold">
                  {formatCurrency(formattedData.totalSpend)}
                </h3>
                <h4 className="text-sm opacity-70">Total Spend</h4>
              </div>
              <div className="px-5 py-3.5 text-right space-y-0.5 bg-white">
                <h3 className="text-xl font-semibold">
                  {formatCurrency(formattedData.totalSpendQuantity, '', 2)}
                </h3>
                <h4 className="text-sm opacity-70">Avg. Price Per Unit</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <h2 className="font-semibold flex items-center space-x-3">
          <span>Evolution over time</span>
          <div className="relative">
            <Listbox value={chartType} onChange={setChartType}>
              <Listbox.Button className="border px-3 py-1.5 rounded-md active text-sm flex items-center space-x-3">
                <span>
                  {chartType === 'priceEvolution' ? 'Prices' : 'Quantities'}
                </span>
                <ChevronDownIcon className="h-3" />
              </Listbox.Button>
              <Listbox.Options className="absolute left-0 right-0 mt-0.5 shadow rounded-b-md border bg-white z-50">
                <Listbox.Option
                  value="priceEvolution"
                  className="py-2 px-3 hover:bg-slate-50 active text-xs cursor-pointer flex items-center justify-between"
                >
                  <span>Prices</span>
                </Listbox.Option>
                <Listbox.Option
                  value="quantityEvolution"
                  className="py-2 px-3 hover:bg-slate-50 active text-xs cursor-pointer flex items-center justify-between"
                >
                  <span>Quantities</span>
                </Listbox.Option>
              </Listbox.Options>
            </Listbox>
          </div>
        </h2>
        <div className="w-full h-[350px]">
          {formattedData.priceOverTime && (
            <PriceEvolutionChart
              data={
                chartType === 'priceEvolution'
                  ? formattedData.priceOverTime
                  : formattedData.quantityOverTime
              }
              itemName={itemName}
              isPrice={chartType === 'priceEvolution'}
            />
          )}
        </div>
      </div>
      {formattedData.suppliers.length > 0 && (
        <div className="space-y-4">
          <h2 className="font-semibold">
            Suppliers ({formattedData.suppliers.length})
          </h2>
          <ItemSupplierTable suppliers={formattedData.suppliers} />
        </div>
      )}
    </div>
  )
}

export const PriceTrackingSection = () => {
  const dp = useDataProvider()
  const [selected, setSelected] = useState<PriceTagOption | undefined>()
  const { query, dateRange } = useChartContext()
  const [currentPage] = useState(1)
  const ITEMS_PER_PAGE = 10 // Adjust as necessary

  const filterParams: any = {
    filter: {
      query,
      ...dateRange,
    },
    sort: { field: 'total_spend', order: 'desc' },
    pagination: { page: currentPage, perPage: ITEMS_PER_PAGE },
  }

  const { data, isLoading } = useQuery(
    ['getPriceTrackingTags', currentPage, query, dateRange],
    () => dp.getPriceTrackingTags(filterParams)
  )

  const choices = useMemo(() => {
    return data
      ? (data.data.map((tag) => ({
          id: tag.id,
          name: tag.name,
          tagCategoryId: tag.tag_category.id,
          tagCategoryName: tag.tag_category.name,
        })) as PriceTagOption[])
      : undefined
  }, [data])

  const onSelect = (_, option) => {
    setSelected(option)
  }

  return (
    <div>
      <SectionTitle
        title="Price tracking"
        subtitle="Explore and compare costs for various materials or services"
      />
      <div className="border-t border-b border-stone-200/50 px-6 -mx-6 py-2 bg-stone-100/40 flex items-center space-x-4 mt-4">
        <span>Select your item or service:</span>
        {!choices || isLoading ? (
          <div className="h-5 rounded bg-stone-100/80 animate-pulse w-[500px]" />
        ) : (
          <Autocomplete
            value={selected}
            options={choices}
            getOptionLabel={(option: any) =>
              `${option.name} (${option.tagCategoryName})`
            }
            style={{ width: 500 }}
            onChange={onSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Item or service"
                variant="outlined"
              />
            )}
          />
        )}
      </div>
      {selected && (
        <PriceTrackingDetail
          tagId={selected.id}
          key={selected.id}
          itemName={selected.name}
        />
      )}
    </div>
  )
}
