import { Datagrid, DateField, List, TextField } from 'react-admin'
import { Title } from '../components/Title'

const EventList = () => {
  return (
    <section className="space-y-6">
      <Title>Audit logs</Title>
      <List sort={{ field: 'created', order: 'DESC' }}>
        <Datagrid bulkActionButtons={false}>
          <DateField source="created" label="Date" />
          <TextField source="event_type" label="Event Type" />
          <TextField source="actor.name" label="Actor" emptyText="n/a" />
          <TextField source="message" emptyText="n/a" />
          <TextField source="organisation.name" emptyText="n/a" />
        </Datagrid>
      </List>
    </section>
  )
}

export default EventList
