import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'

const TagCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <h2 className="font-semibold mb-4">Create a new tag</h2>
        <TextInput source="name" sx={{ minWidth: 300 }} />
        <ReferenceInput source="tag_category_id" reference="tag_categories">
          <SelectInput
            label="Tag category"
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default TagCreate
