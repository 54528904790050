import { Datagrid, List, TextField } from 'react-admin'
import { useTranslation } from 'react-i18next'
import { Title } from '../components/Title'

const UserList = () => {
  const { t } = useTranslation()
  return (
    <section className="space-y-6">
      <Title>{t('users')}</Title>
      <List perPage={25} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid bulkActionButtons={false} rowClick="edit">
          <TextField source="name" />
          <TextField source="email" />
          <TextField source="organisation.name" />
        </Datagrid>
      </List>
    </section>
  )
}

export default UserList
