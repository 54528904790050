import { formatCurrency, formatNumber } from '../../helpers'
import { ItemSupplier } from '../../types'

export const ItemSupplierTable = ({
  suppliers,
}: {
  suppliers: ItemSupplier[]
}) => {
  return (
    <table className="table-auto border rounded w-full">
      <thead className="font-medium text-left border-b bg-stone-100/80">
        <tr className="divide-x">
          <th className="p-3 font-medium">Supplier name</th>
          <th className="p-3 font-medium">Avg. Price</th>
          <th className="p-3 font-medium">Min. Price</th>
          <th className="p-3 font-medium">Max. Price</th>
          <th className="p-3 font-medium text-right">Total Quantity</th>
        </tr>
      </thead>
      <tbody className="divide-y">
        {suppliers.map((s) => (
          <tr
            key={s.vendorName}
            className="bg-white divide-x hover:bg-stone-100/40"
          >
            <td className="p-3">{s.vendorName} </td>
            <td className="p-3">{formatCurrency(s.avgPrice, '', 2)}</td>
            <td className="p-3">{formatCurrency(s.minPrice, '', 2)}</td>
            <td className="p-3">{formatCurrency(s.maxPrice, '', 2)}</td>
            <td className="p-3 text-right">{formatNumber(s.totalQuantity)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
