import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { hotjar } from 'react-hotjar'
import Front from './Front'
import Admin from './admin/Admin'

const App = () => {
  useEffect(() => {
    hotjar.initialize(3463657, 6)
    const options: any = {
      // testMode: process.env.REACT_APP_DEBUG === 'true',
      gaOptions: {},
    }
    let user: any = localStorage.getItem('user')
    if (user) {
      user = JSON.parse(user)
      options.gaOptions.userId = user.id
      hotjar.identify(user.email, {})
    }
    ReactGA.initialize('G-H41B4T1R5R', options)
  })
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Front />} />
        <Route path="/admin/*" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
