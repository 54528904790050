import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  TextInput,
} from 'react-admin'

const ImportLogCreate = (props) => {
  return (
    <Create title="Upload Import Log" {...props}>
      <SimpleForm>
        <FileInput
          source="file"
          label="File"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          <FileField source="src" title="title" />
        </FileInput>
        {/* Add other fields if necessary */}
        <p>OR</p>
        <TextInput source="filename" />
      </SimpleForm>
    </Create>
  )
}

export default ImportLogCreate
