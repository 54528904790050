import { useCallback, useMemo } from 'react'
import { removeFromArray, replaceInArray } from '.'
import { useLocalStorage } from './useLocalStorage'

const NEW_QUERY_ID = -1
const MAX_NUMBER_OF_STORED_QUERIES = 8
const EMPTY_QUERY = {
  combinator: 'and',
  rules: [],
}

const STORED_QUERIES = 'txnFilters'
const CURRENT_QUERY = 'txnFilters_idx'

export interface QueryBuilderPayload {
  currentQuery: any
  currentQueryRaw: string

  currentQueryIndex: number
  numberOfQueries: number
  numberOfRules: number

  isNewQuery: boolean

  canGoForward: boolean
  canGoBack: boolean

  clearFilter: Function
  deleteCurrentQuery: Function
  updateCurrentQuery: Function
  goToPreviousQuery: Function
  goToNextQuery: Function
  addRuleToQuery: Function
  removeRulesFromQuery: Function
}

export const useQueryFilter = (): QueryBuilderPayload => {
  const [rawStoredQueries, setRawStoredQueries] = useLocalStorage(
    STORED_QUERIES,
    []
  )

  const storedQueries = useMemo(
    () => rawStoredQueries?.map((q) => JSON.parse(q)),
    [rawStoredQueries]
  )

  const setStoredQueries = useCallback(
    (queries) => setRawStoredQueries(queries.map((q) => JSON.stringify(q))),
    [setRawStoredQueries]
  )

  const [rawCurrentQueryIndex, setCurrentQueryIndex] = useLocalStorage(
    CURRENT_QUERY,
    NEW_QUERY_ID
  )
  const currentQueryIndex = useMemo(
    () => Number(rawCurrentQueryIndex),
    [rawCurrentQueryIndex]
  )

  const isNewQuery = useMemo(
    () => currentQueryIndex === NEW_QUERY_ID,
    [currentQueryIndex]
  )
  const currentQuery = useMemo(() => {
    const query = storedQueries[currentQueryIndex]
    if (currentQueryIndex === NEW_QUERY_ID || !query) return EMPTY_QUERY
    return query
  }, [currentQueryIndex, storedQueries])

  const currentQueryRaw = useMemo(
    () => JSON.stringify(currentQuery),
    [currentQuery]
  )

  const numberOfQueries = useMemo(() => storedQueries.length, [storedQueries])
  const numberOfRules = useMemo(() => currentQuery.rules.length, [currentQuery])

  const canGoBack = useMemo(() => currentQueryIndex > 0, [currentQueryIndex])
  const canGoForward = useMemo(
    () => currentQueryIndex < storedQueries.length - 1,
    [currentQueryIndex, storedQueries]
  )
  const goToPreviousQuery = useCallback(() => {
    const nextQuery = canGoBack
      ? currentQueryIndex - 1
      : storedQueries.length - 1
    setCurrentQueryIndex(nextQuery)
  }, [canGoBack, currentQueryIndex, setCurrentQueryIndex, storedQueries.length])

  const goToNextQuery = useCallback(() => {
    const nextQuery = canGoForward
      ? currentQueryIndex + 1
      : storedQueries.length - 1
    setCurrentQueryIndex(nextQuery)
  }, [
    canGoForward,
    currentQueryIndex,
    setCurrentQueryIndex,
    storedQueries.length,
  ])

  const deleteCurrentQuery = () => {
    setStoredQueries(removeFromArray(storedQueries, currentQueryIndex))
    setCurrentQueryIndex(NEW_QUERY_ID)
  }

  const updateCurrentQuery = (query) => {
    const formattedQuery = JSON.parse(query)
    if (isNewQuery) {
      const result = [
        ...storedQueries.slice(-MAX_NUMBER_OF_STORED_QUERIES),
        formattedQuery,
      ]
      setStoredQueries(result)
      setCurrentQueryIndex(result.length - 1)
    } else {
      setStoredQueries(
        replaceInArray(storedQueries, currentQueryIndex, formattedQuery)
      )
    }
  }

  // We currently don't support two tag rules,
  // so we strip out the older one when adding a new rule
  const incompatibleRuleTagIds = [14, 15, 16]
  const hasIncompatibleTagId = (field) =>
    incompatibleRuleTagIds.filter((id) => field === `tag_id[${id}]`).length > 0

  const removeIncompatibleRules = (field, rules) => {
    if (!hasIncompatibleTagId(field)) return rules
    return rules.filter((r) => !hasIncompatibleTagId(r.field))
  }

  const addRuleToQuery = (field, value) => {
    updateCurrentQuery(
      JSON.stringify({
        ...currentQuery,
        not: false,
        rules: [
          ...removeIncompatibleRules(field, currentQuery.rules),
          { field, value, operator: 'in', valueSource: 'value' },
        ],
      })
    )
  }

  const removeRulesFromQuery = (indices: number[]) => {
    updateCurrentQuery(
      JSON.stringify({
        ...currentQuery,
        rules: currentQuery.rules.filter((_, i) => !indices.includes(i)),
      })
    )
  }

  const clearFilter = () => {
    setCurrentQueryIndex(NEW_QUERY_ID)
  }

  return {
    currentQueryIndex,
    currentQuery,
    currentQueryRaw,
    numberOfRules,
    numberOfQueries,
    canGoBack,
    canGoForward,
    goToPreviousQuery,
    goToNextQuery,
    deleteCurrentQuery,
    updateCurrentQuery,
    clearFilter,
    addRuleToQuery,
    removeRulesFromQuery,
    isNewQuery,
  }
}
