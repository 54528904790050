import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin'
import FileActionButtons from './FileActionButtons'

const ImportLogShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="filename" emptyText="n/a" />
          <TextField source="user.name" emptyText="n/a" />
          <TextField source="organisation.name" emptyText="n/a" />
          <DateField source="created" label="Date uploaded" />
          <TextField
            source="status.import_log_status"
            label="Import Log Status"
          />
          <TextField
            source="status.import_log_total"
            label="Import Log Total"
          />
          <TextField
            source="status.import_log_current"
            label="Import Log Current"
          />
          <TextField
            source="status.organisation_status"
            label="Organisation Map Status"
          />
          <TextField
            source="status.organisation_total"
            label="Organisation Map Total"
          />
          <TextField
            source="status.organisation_current"
            label="Organisation Map Current"
          />
          <FileActionButtons />
        </Tab>
        <Tab label="vendors">
          <ReferenceManyField
            reference="vendors"
            target="import_log_id"
            source="id"
            pagination={<Pagination />}
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" label="Vendor" />
              <TextField source="territory.name" label="Territory" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="transactions">
          <ReferenceManyField
            reference="transactions"
            target="import_log_id"
            source="id"
            pagination={<Pagination />}
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="invoice_vendor_name" label="Vendor" />
              <TextField source="amount" label="Amount" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default ImportLogShow
