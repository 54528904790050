import * as React from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  Form,
  ReferenceInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin'

import MergeIcon from '@mui/icons-material/Merge'
import CancelIcon from '@mui/icons-material/Cancel'

import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'

import SaveWithConfirmButton from '../components/SaveWithConfirmButton'
// import userPermissions from '../Users/userPermissions'

const MigrateTransactionDialog = ({ open, handleClose }) => {
  const [loading, setLoading] = React.useState(false)

  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  // const { permissions } = usePermissions()

  const handleSubmit = (values) => {
    setLoading(true)
    const ndata = {
      id: values.id,
      vendor_id: values.vendor_id,
      organisation_id: values.organisation.id,
      apply_all: values.apply_all,
      apply_all_original: values.apply_all_original,
      apply_globably: false, // values.apply_globally,
      update_tags: values.update_tags,
    }

    dataProvider
      .transactionChangeVendor({ id: ndata.id, data: ndata })
      .then((resp) => {
        notify(`Vendor changed`, { type: 'success' })
        refresh()
        handleClose(resp)
      })
      .catch((e) => {
        console.log('Error')
        notify(`Vendor change error: ${e.message}`, { type: 'warning' })
      })
      .finally(() => {
        setLoading(false)
      })

    setLoading(false)
  }
  /* Removed from ui because it hasn't been thought through
   *            {permissions & userPermissions.SUPER_ADMIN && (
                <Grid item xs={6}>
                  <BooleanInput
                    source="apply_globally"
                    label="Migrate all transactions across all organisations"
                  />
                </Grid>
              )}
  */

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change vendor</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <ReferenceInput
                label="To vendor"
                source="vendor_id"
                reference="vendors"
                enableGetChoices={({ q }) => q && q.length >= 3}
              >
                <AutocompleteInput
                  optionText="name"
                  optionValue="id"
                  label="To vendor"
                  shouldRenderSuggestions={(val) => {
                    return val.trim().length >= 3
                  }}
                  noOptionsText="Start typing name to find vendor"
                  helperText="Enter 3 characters to start searching"
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12}>
              <BooleanInput
                source="apply_all"
                label="Migrate all transactions matching selected vendor"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <BooleanInput
                source="apply_all_original"
                label="Migrate all transactions matching original vendor"
              />
            </Grid> */}
            <Grid item xs={12}>
              <BooleanInput
                source="update_tags"
                label="Update tags on transactions matching vendor"
              />
            </Grid>
            <Grid item xs={12}>
              <Button disabled={loading} onClick={handleClose} label="Cancel">
                <CancelIcon />
              </Button>
              <SaveWithConfirmButton saveConfirm={false} disabled={loading} />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const MigrateButton = () => {
  const [openDialog, setOpenDialog] = React.useState(false)

  return (
    <>
      <MigrateTransactionDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
      <Button onClick={() => setOpenDialog(true)} label="Change vendor">
        <MergeIcon />
      </Button>
    </>
  )
}

export default MigrateButton
