import { FunctionComponent, HTMLProps, ReactNode } from 'react'

type Props = HTMLProps<HTMLButtonElement> & {
  children?: ReactNode
  icon?: ReactNode
  secondary?: boolean
}

export const Button: FunctionComponent<Props> = (props) => {
  const { className, children, icon, secondary, disabled } = props
  return (
    <button
      {...props}
      type="button"
      className={`text-sm border py-2 rounded inline-flex space-x-2 items-center transition-all  ${
        secondary
          ? 'bg-white shadow-sm text-slate-800 border-gray-300 px-3 hover:bg-slate-50 font-medium'
          : 'bg-indigo-700 border-indigo-800 text-white px-4 font-semibold'
      } ${
        disabled
          ? 'opacity-50 pointer-events-none'
          : 'active hover:bg-opacity-90'
      } ${className}`}
    >
      {children && <span>{children}</span>}
      {icon && icon}
    </button>
  )
}
