import {
  BooleanInput,
  Edit,
  ListButton,
  ShowButton,
  SimpleForm,
  TextInput,
  TopToolbar,
} from 'react-admin'

const TagCategoryEditActions = () => (
  <TopToolbar>
    <ShowButton />
    <ListButton />
  </TopToolbar>
)

const TagCategoryEdit = () => {
  return (
    <Edit mutationMode="pessimistic" actions={<TagCategoryEditActions />}>
      <SimpleForm>
        <TextInput source="name" />
        <BooleanInput source="price_tracking" />
      </SimpleForm>
    </Edit>
  )
}

export default TagCategoryEdit
