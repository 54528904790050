import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'
import { BanknotesIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useChartContext from '../charts/useChartContext'
import { Stat } from './Stat'
import { formatCurrency, getLabelSuffix } from '../../helpers'

const chartType = 'totalSpend'

export const TotalSpend = ({ onClick, isSelected }) => {
  const cc = useChartContext()
  const dp = useDataProvider()
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  const { data, isLoading } = useQuery(
    [`charts`, 'getGetChart', chartType, cc.query, cc.dateRange],
    () => dp.getChart(chartType, { query: cc.query, ...cc.dateRange })
  )

  const { data: compareData, isLoading: compareIsLoading } = useQuery(
    [`charts`, 'getGetChart', chartType, cc.query, cc.compareRange],
    () => dp.getChart(chartType, { query: cc.query, ...cc.compareRange })
  )

  const amount = useMemo(() => {
    if (isLoading) return undefined
    if (!data || !data.data) return undefined
    return formatCurrency(data.data / 100)
  }, [data, isLoading])

  const compareAmount = useMemo(() => {
    if (compareIsLoading) return undefined
    if (!compareData || !compareData.data) return undefined
    return formatCurrency(compareData.data / 100)
  }, [compareData, compareIsLoading])

  const deltaChange = useMemo(() => {
    if (!data || !data.data) return 0
    if (!compareData || !compareData.data) return 0
    return ((data.data - compareData.data) / compareData.data) * 100
  }, [compareData, data])

  return (
    <Stat
      label={t(`total_spend${suffix}`)}
      onClick={onClick}
      loading={isLoading}
      icon={<BanknotesIcon className="h-4" />}
      value={amount}
      compareValue={cc.compareRange ? compareAmount : undefined}
      deltaChange={deltaChange}
      background="bg-purple-200"
      isSelected={isSelected}
    />
  )
}
