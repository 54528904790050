import { Datagrid, List, TextField } from 'react-admin'
import { Title } from '../components/Title'

const TagList = () => (
  <section>
    <Title>Tags</Title>
    <List sort={{ field: 'name', order: 'ASC' }}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="name" />
      </Datagrid>
    </List>
  </section>
)

export default TagList
