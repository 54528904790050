import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { AreaBumpComputedSerie, ResponsiveAreaBump } from '@nivo/bump'

import { useTranslation } from 'react-i18next'
import useChartContext from './useChartContext'
import { ParetoQuery } from '../../types'
import { BOTTOM_VENDOR_COLOR, TOP_VENDOR_COLOR } from '../colors'

import { NoData } from '../NoData'
import { ParetoTable } from '../tables/ParetoTable'
import { ParetoSections } from './ParetoSections'
import { ParetoVendorLabel } from './ParetoVendorLabel'
import { ParetoSpendLabel } from './ParetoSpendLabel'
import { ChartView } from '../stats/ChartView'
import { ParetoVendorTooltip } from './ParetoVendorTooltip'
import { ParetoSpendTooltip } from './ParetoSpendTooltip'
import { getLabelSuffix } from '../../helpers'

const chartType = 'paretoAnalysis'

const PlaceHolder = () => (
  <div className="grid grid-cols-2 gap-10 min-h-[600px]">
    <div className="h-[600px] bg-stone-100 rounded-lg" />
    <div className="h-[600px] bg-stone-100 rounded-lg" />
  </div>
)

const PADDING = 12
const MARGIN = { top: 14, right: 0, bottom: 0, left: 0 }

const ParetoChart = ({ view, topIndex, bottomIndex }) => {
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  const cc = useChartContext()
  const dp = useDataProvider()

  const { data, isLoading } = useQuery<ParetoQuery>(
    [`charts`, chartType, cc.query, cc.dateRange],
    () => dp.getChart(chartType, { query: cc.query, ...cc.dateRange })
  )

  const topVendors = useMemo(() => {
    if (!data?.data) return null
    return data.data[topIndex]
  }, [data?.data, topIndex])

  const bottomVendors = useMemo(() => {
    if (!data?.data) return null
    return data.data[bottomIndex]
  }, [data?.data, bottomIndex])

  const chartData = useMemo(() => {
    if (!topVendors || !bottomVendors) return []
    const vendorViewData = [
      {
        id: ParetoSections.top20,
        data: [
          {
            x: 0,
            y: topVendors.left,
          },
          {
            x: 1,
            y: topVendors.group,
          },
        ],
      },
      {
        id: ParetoSections.bottom80,
        data: [
          {
            x: 0,
            y: bottomVendors.left,
          },
          {
            x: 1,
            y: bottomVendors.group,
          },
        ],
      },
    ]
    const spendViewData = [
      {
        id: ParetoSections.top20,
        data: [
          {
            x: 0,
            y: topVendors.group,
          },
          {
            x: 1,
            y: topVendors.left,
          },
        ],
      },
      {
        id: ParetoSections.bottom80,
        data: [
          {
            x: 0,
            y: bottomVendors.group,
          },
          {
            x: 1,
            y: bottomVendors.left,
          },
        ],
      },
    ]
    if (view === ChartView.vendor) {
      return vendorViewData
    }
    return spendViewData
  }, [topVendors, bottomVendors, view])

  const renderTooltip = ({
    serie,
  }: {
    serie: AreaBumpComputedSerie<
      {
        x: number
        y: number
      },
      {
        id: string
        data: {
          x: number
          y: number
        }[]
      }
    >
  }) => {
    const content =
      serie.id === ParetoSections.top20 ? topVendors : bottomVendors

    if (view === ChartView.vendor) {
      return <ParetoVendorTooltip serie={serie} content={content} />
    }
    return <ParetoSpendTooltip serie={serie} content={content} />
  }

  if (isLoading) return <PlaceHolder />
  if (!data?.data || !topVendors || !bottomVendors) return <NoData />

  return (
    <div className="grid grid-cols-2 gap-10 min-h-[600px]">
      <div className="flex space-x-4 h-[600px]">
        {view === ChartView.vendor ? (
          <ParetoVendorLabel
            topVendors={topVendors}
            suffix={suffix}
            t={t}
            bottomVendors={bottomVendors}
          />
        ) : (
          <ParetoSpendLabel
            topVendors={topVendors}
            t={t}
            bottomVendors={bottomVendors}
          />
        )}
        <div className="h-[600px] flex-1">
          <ResponsiveAreaBump
            tooltip={renderTooltip}
            data={chartData}
            margin={MARGIN}
            spacing={PADDING}
            colors={[TOP_VENDOR_COLOR, BOTTOM_VENDOR_COLOR]}
            blendMode="multiply"
            axisTop={null}
            axisBottom={null}
            enableGridX={false}
          />
        </div>
        {view === ChartView.vendor ? (
          <ParetoSpendLabel
            topVendors={topVendors}
            t={t}
            bottomVendors={bottomVendors}
          />
        ) : (
          <ParetoVendorLabel
            topVendors={topVendors}
            suffix={suffix}
            t={t}
            bottomVendors={bottomVendors}
          />
        )}
      </div>
      <ParetoTable topVendors={topVendors} bottomVendors={bottomVendors} />
    </div>
  )
}

export default ParetoChart
