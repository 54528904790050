import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'

import { ResponsiveBar } from '@nivo/bar'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useChartContext from './useChartContext'

import { NoData } from '../NoData'
import {
  formatCurrency,
  formatCurrencyCompact,
  formatNumber,
  getAllMonths,
} from '../../helpers'

interface ByMonths {
  [key: string]: number | string
}

const theme = {
  grid: {
    line: {
      stroke: '#f0f0f0',
    },
  },
}

const MAX_X_LENGTH = 12

const MonthlySpendByTags = () => {
  const chartType = 'spendPerMonthByTags'

  const cc = useChartContext()
  const dp = useDataProvider()

  const { t } = useTranslation()

  const { data, isLoading } = useQuery(
    ['charts', 'getGetChart', chartType, cc.query, cc.dateRange],
    () =>
      dp.getChart(
        chartType,
        {
          query: cc.query,
          tag_category_name: 'Preferred Supplier',
          ...cc.dateRange,
        },
        {
          enabled: !!cc.query,
        }
      )
  )

  const getColor = (bar) => {
    const colors = {
      'preferred supplier': '#cea9ff',
      'non-preferred supplier': '#ece1fb',
      unclassified: '#cccccc',
    }

    return colors[bar.id.toLowerCase()]
  }

  const formattedData = useMemo(() => {
    if (!data) {
      return []
    }

    const ret: ByMonths[] = []
    const byMonths: ByMonths[] = getAllMonths(cc.dateRange)

    data.data.forEach((d) => {
      d.data.forEach((dd) => {
        if (!byMonths[dd.month]) {
          byMonths[dd.month] = { month: dd.month }
        }
        byMonths[dd.month][d.tag] = dd.amount / 100
      })
    })

    for (const m in byMonths) {
      if ({}.hasOwnProperty.call(byMonths, m)) {
        ret.push(byMonths[m])
      }
    }
    return ret
  }, [cc.dateRange, data])

  const keys = useMemo(() => {
    const allKeys: { [key: string]: number } = {}

    formattedData.forEach((d) => {
      for (const k in d) {
        if (k !== 'month') {
          allKeys[k] = 1
        }
      }
    })

    return Object.keys(allKeys)
  }, [formattedData])

  const responsiveBarLabel: any = (d) => {
    const v2Key =
      d.id === 'Preferred supplier'
        ? 'Non-preferred supplier'
        : 'Preferred supplier'
    let totalBarValue = d.value
    if (d.data[v2Key]) {
      totalBarValue += d.data[v2Key]
    }
    let percentage = 0
    if (d.value) {
      percentage = Math.round((d.value / totalBarValue) * 100)
    }

    return (
      <>
        <tspan
          x="33"
          dy="0em"
          style={{
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          {formatCurrencyCompact(d.value) || 0}
        </tspan>
        {d.id !== 'Unclassified' && (
          <tspan x="33" dy="1.4em" style={{ opacity: 0.9, fontSize: 11 }}>
            {formatNumber(percentage) || 0}%
          </tspan>
        )}
      </>
    )
  }

  const isChartLong = useMemo(() => {
    const long = formattedData.filter((d) => d.length > MAX_X_LENGTH)

    return !!long.length
  }, [formattedData])

  if (isLoading || !data)
    return (
      <div className="space-y-4 flex-1">
        <h2 className="font-semibold">{t('spending_per_month')}</h2>
        <div className="h-[300px] bg-stone-50/70 rounded-md animate-pulse" />
      </div>
    )

  // @ts-ignore
  return (
    <div className="space-y-4 flex-1">
      <h2 className="font-semibold">{t('spending_per_month')}</h2>
      <div className="h-[400px]">
        {data.data.length ? (
          <ResponsiveBar
            padding={0.2}
            borderRadius={2}
            borderWidth={1.1}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.3]],
            }}
            label={responsiveBarLabel}
            labelSkipHeight={20}
            theme={theme}
            data={formattedData}
            keys={keys}
            indexBy="month"
            margin={{
              top: 50,
              right: 15,
              bottom: isChartLong ? 35 : 20,
              left: 50,
            }}
            enableGridX={false}
            valueFormat={(value) => formatCurrency(value)}
            axisLeft={{
              format: (value) => formatCurrencyCompact(value),
              tickValues: 20,
            }}
            axisBottom={{
              tickRotation: isChartLong ? -40 : 0,
            }}
            colors={getColor}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -50,
                itemsSpacing: 100,
                itemDirection: 'left-to-right',
                itemWidth: 150,
                itemHeight: 20,
                itemOpacity: 0.75,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  )
}

export default MonthlySpendByTags
