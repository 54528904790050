import './App.css'
import { Link } from 'react-admin'
import { ArrowRightIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import logo from './logo.png'
import { Tagline } from './admin/components/Tagline'
import { Card } from './admin/components/Card'

function Front() {
  const renderGetInfoButton = () => {
    if (window.location.hostname !== 'www.spendqube.com') {
      return (
        <Link to="/admin">
          <div className="group active flex space-x-3 items-center bg-purple-600 text-white font-semibold pr-4 px-7 py-4 rounded-full hover:scale-[1.01] transition-all text-sm hover:bg-gradient-to-b from-purple-600 to-purple-700">
            <span>Get started</span>
            <ArrowRightIcon className="h-4 group-hover:translate-x-0.5 transition-all" />
          </div>
        </Link>
      )
    }
    // we're the brochure site, so don't have the 'get started' button to take
    // you to the login.
    return ''
  }

  return (
    <div className="min-h-screen bg-brand text-white">
      <section className="bg-gradient-to-br from-transparent to-transparent via-gray-900/60">
        <div className="container mx-auto px-12 pt-16 space-y-16">
          <div className="flex flex-col items-center space-y-14">
            <div className="items-center space-x-2.5 flex pb-3">
              <img src={logo} className="h-8" alt="logo" />
              <div>
                <h2 className="font-medium text-xl">
                  Spend<span className="font-semibold">Qube</span>
                </h2>
              </div>
            </div>
            <h1 className="font-bold text-4xl md:text-7xl tracking-tighter leading-[1.1] flex-1 text-center">
              Your Data, <br />
              Our Digital Solution
            </h1>
            {renderGetInfoButton()}
          </div>
          <div className="bg-white px-2 pt-2 rounded-t-md transition-all hover:scale-[1.005] space-y-2">
            <div className="flex items-center justify-between space-x-4">
              <div className="rounded-md py-1.5 px-3 bg-stone-50 text-xs text-stone-600 font-medium flex-1 flex items-center space-x-2 border border-stone-100">
                <LockClosedIcon className="h-3" />
                <span>www.spendqube.com</span>
              </div>
              <div className="space-x-1.5 flex items-center justify-end">
                <div className="h-2 w-2 bg-stone-100 rounded-full" />
                <div className="h-2 w-2 bg-stone-100 rounded-full" />
                <div className="h-2 w-2 bg-stone-100 rounded-full" />
              </div>
            </div>
            <img
              src="/screenshot.png"
              className="rounded-md min-h-[500px]"
              alt="Screenshot of the SpendQube application"
            />
          </div>
        </div>
      </section>
      <section className="bg-white py-4 flex justify-center text-sm font-medium items-center space-x-2 border-t border-b border-stone-100 text-black">
        <span>Powered by</span>
        <img src="/procurato.png" className="h-8" alt="Procurato logo" />
      </section>
      <section className="container mx-auto p-12 mt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-16 gap-y-24">
          <div>
            <div className="flex h-80 items-center">
              <Card className="-rotate-6 -mr-16">
                <img
                  src="/pie.png"
                  className="w-96"
                  alt="Screenshot showing a pie chart from the SpendQube app"
                />
              </Card>
              <Card className="rotate-3 h-72 -ml-16">
                <img
                  src="/waterfall.png"
                  className="w-96"
                  alt="Screenshot showing a waterfall chart from the SpendQube app"
                />
              </Card>
            </div>
            <p className="text-xs text-center mt-5">
              Multiple visualisation options
            </p>
          </div>
          <div className="flex items-center">
            <p className="text-lg leading-relaxed md:pl-12 text-center md:text-left">
              Our SaaS spend analysis tool allows you to bring your data from
              multiple systems together with the ability to cleanse, code,
              organise and review all supplier spend to truly understand the
              spend and make informed decisions such as identifying savings
              opportunities.
            </p>
          </div>
          <div className="flex items-center">
            <p className="text-lg leading-relaxed flex items-center md:pr-12 text-center md:text-left">
              SpendQube is one of the most user-friendly tools with flexible
              engine inside which helped many companies to visualise their
              spend, lower their cost and facilitate daily processes. Powered by
              a procurement consulting company, we provide consultancy advice
              and support to our clients who want to get the most out of it.
            </p>
          </div>
          <div>
            <Card>
              <img
                src="/map.png"
                alt="Screenshot of the SpendQube app displaying location data"
              />
            </Card>
            <p className="text-xs text-center mt-4">
              Visualisations based on your location data
            </p>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            {renderGetInfoButton()}
          </div>
        </div>
      </section>
      <div className="mt-16 pb-4">
        <Tagline />
      </div>
    </div>
  )
}

export default Front
