import { useMemo } from 'react'
import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'
// import { useTranslation } from 'react-i18next'
import { DnBRating } from '../types'
import { getDnBRating } from '.'
import useChartContext from '../Dashboard/charts/useChartContext'

type CreditScoreLine = {
  id: number
  credit_score: number
  name: string
  spend: number
}

type CreditScoreType = 'Excellent' | 'Good' | 'Poor'

type CreditScoreData = {
  Excellent: CreditScoreLine[]
  Good: CreditScoreLine[]
  Poor: CreditScoreLine[]
}
type CreditScoreResponse = {
  data: CreditScoreData[]
}

const CREDIT_SCORE_TYPES = ['Poor', 'Good', 'Excellent'] as CreditScoreType[]
const COLORS = {
  Excellent: '#afc949',
  Good: '#ebc346',
  Poor: '#e66262',
}

const DNB_SCORE_TYPES = [
  '1',
  '2',
  '3',
  '4',
  'Insufficient Information',
  'Everything else',
] as DnBRating[]

const DNB_COLORS = {
  1: '#afc949',
  2: '#eab308',
  3: '#f97316',
  4: '#e66262',
  'Everything else': '#0f172a',
  'Insufficient Information': '#d3d3d3',
}

const creditScoreChartType = 'creditScoreAnalysis'
const dnbChartType = 'dnbRatingAnalysis'

const groupDnbData = (data) =>
  data.reduce((acc, d) => {
    const group = getDnBRating(d.credit_score)
    const current = acc[group] ?? []
    return {
      ...acc,
      [group]: [...current, d],
    }
  }, {})

export const useCreditScoreData = (
  isDnb: boolean,
  selectedCreditScore?: string
) => {
  // const { t } = useTranslation()

  const cc = useChartContext()
  const dp = useDataProvider()

  const ratingSettings = useMemo(
    () =>
      isDnb
        ? {
            chartType: dnbChartType,
            colors: DNB_COLORS,
            types: DNB_SCORE_TYPES,
          }
        : {
            chartType: creditScoreChartType,
            colors: COLORS,
            types: CREDIT_SCORE_TYPES,
          },
    [isDnb]
  )

  const { data, isLoading } = useQuery<CreditScoreResponse>(
    ['charts', 'getGetChart', ratingSettings.chartType, cc.query, cc.dateRange],
    () =>
      dp.getChart(
        ratingSettings.chartType,
        {
          query: cc.query,
          ...cc.dateRange,
        },
        {
          enabled: !!cc.query,
        }
      )
  )

  const hasData = useMemo(() => {
    if (!data || !data?.data || isLoading) return false
    if (!data?.data?.[0]) return false
    if (
      !isDnb &&
      !data.data[0].Excellent.length &&
      !data.data[0].Good.length &&
      !data.data[0].Poor.length
    )
      return false
    return true
  }, [data, isDnb, isLoading])

  const chartData = useMemo(() => {
    if (!data?.data) return undefined
    return isDnb ? groupDnbData(data?.data) : data?.data?.[0]
  }, [data?.data, isDnb])

  const tableData = useMemo(() => {
    if (!data?.data) return undefined
    if (isLoading) return undefined
    if (selectedCreditScore) return chartData?.[selectedCreditScore]
    if (isDnb) {
      return data.data
    }
    return chartData?.Poor.concat(chartData?.Good, chartData?.Excellent)
  }, [chartData, data?.data, isDnb, isLoading, selectedCreditScore])

  const segments = useMemo(() => {
    if (!chartData) return undefined

    return ratingSettings.types.map((key) => ({
      label: key,
      amount: chartData?.[key]?.length ?? 0,
      color: ratingSettings.colors[key],
    }))
  }, [chartData, ratingSettings.colors, ratingSettings.types])

  return {
    segments,
    tableData,
    isLoading,
    hasData,
  }
}
