import {
  PasswordInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useDataProvider,
  useGetIdentity,
  useNotify,
} from 'react-admin'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useMutation } from 'react-query'
import { Card } from '@mui/material'

const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol character')
      .required(),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required()

export const MyToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
)

const PasswordEdit = ({ userId }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const identity = useGetIdentity()

  const { mutate } = useMutation(
    ['password', 'password', {}],
    (values) =>
      dataProvider.updatePassword(userId || identity.identity?.id, values),
    {
      onSuccess: () => {
        notify('Password updated', { type: 'success' })
      },
      onError: (e: Error) => {
        notify(`Password error: ${e.message}`, { type: 'warning' })
      },
    }
  )

  const handleSubmit = (values) => {
    mutate(values)
  }

  return (
    <Card>
      <SimpleForm
        toolbar={<MyToolbar />}
        onSubmit={handleSubmit}
        resolver={yupResolver(schema)}
      >
        <PasswordInput source="password" sx={{ minWidth: 320 }} />
        <PasswordInput source="confirm_password" sx={{ minWidth: 320 }} />
      </SimpleForm>
    </Card>
  )
}

export default PasswordEdit
