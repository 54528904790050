import { Sidebar } from './Sidebar'
import { Tagline } from './Tagline'

const SQLayout = (props) => (
  <main className="bg-stone-100/80 text-slate-900">
    <div className="flex min-h-screen container mx-auto items-start px-12 gap-12 justify-between">
      <Sidebar />
      <div className="pt-10 pb-4 flex-1 min-h-screen flex flex-col justify-between space-y-12">
        <div>{props.children}</div>
        <Tagline />
      </div>
    </div>
  </main>
)

export default SQLayout
