import { ResponsivePie } from '@nivo/pie'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { WaterfallChart } from '../../components/charts/WaterfallChart'

import { formatCurrency } from '../../helpers'

const COLOR_TOTAL = '#374151'

export const CategoryChartWaterfall = (props) => {
  const { t } = useTranslation()
  const { tags, addRule, total, colors } = props

  const items = useMemo(
    () => [
      {
        label: t('total'),
        value: total,
        transparent: 0,
      },
      ...tags.map((tag) => ({
        label: tag.label,
        value: tag.value,
        tagId: tag.tagId,
      })),
    ],
    [tags, total, t]
  )

  const itemsWithOffsets = useMemo(
    () =>
      items.reduce((accum, current, index) => {
        const topOffset =
          index < 2
            ? 0
            : items
                .filter((_, i) => i > 0 && i < index)
                .reduce((p, c) => p + c.value, 0)
        const bottomOffset = index < 1 ? 0 : total - topOffset - current.value
        return [
          ...accum,
          {
            ...current,
            topOffset,
            bottomOffset,
          },
        ]
      }, []),
    [items, total]
  )

  const getColor = useCallback(
    (bar) => {
      if (bar.id !== 'value') return 'transparent'
      if (bar.index === 0) return COLOR_TOTAL
      return colors[bar.index - 1]
    },
    [colors]
  )

  const formatLabel = useCallback(
    (a) => {
      if (a.id !== 'value') return ''
      return <tspan y={-10}>{((a.value * 100) / total).toFixed(2)}%</tspan>
    },
    [total]
  )

  return (
    <div className="space-y-4 h-full flex-1 flex flex-col">
      <WaterfallChart
        formatLabel={formatLabel}
        getColor={getColor}
        items={itemsWithOffsets}
        onClick={(a) => {
          if (a.id !== 'value' || a.index === 0) return
          addRule(a.data.tagId)
        }}
      />
      <div className="space-x-2 flex items-center justify-center text-sm font-medium">
        <div
          className="h-3 w-3 rounded"
          style={{ backgroundColor: COLOR_TOTAL }}
        />
        <span>Total: {formatCurrency(total)}</span>
      </div>
    </div>
  )
}

export const CategoryChart = (props) => {
  const { tags, addRule, total, colors, chartType } = props
  if (chartType === 'waterfall') return <CategoryChartWaterfall {...props} />
  return (
    <>
      <div className="absolute inset-0 flex items-center justify-center text-xl font-semibold z-10 pointer-events-none">
        <div className="bg-white p-2 rounded-lg">{formatCurrency(total)}</div>
      </div>
      <ResponsivePie
        data={tags}
        colors={colors}
        enableArcLabels={false}
        valueFormat={(value) => formatCurrency(value)}
        margin={{ top: 10, right: 15, bottom: 10, left: 15 }}
        sortByValue
        innerRadius={0.65}
        padAngle={1.1}
        cornerRadius={4}
        activeOuterRadiusOffset={8}
        borderWidth={1.5}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        arcLinkLabel={(d) => `${((d.value * 100) / total).toFixed(2)}%`}
        arcLinkLabelsSkipAngle={8}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={0}
        arcLinkLabelsDiagonalLength={0}
        arcLinkLabelsColor={{ from: 'color' }}
        // @ts-ignore
        onClick={(a) => addRule(a.data.tagId)}
      />
    </>
  )
}

export const CategoryChartLoading = ({ square }) => (
  <div className="h-full flex flex-col items-center">
    <div
      className={`h-[375px] bg-stone-100 animate-pulse ${
        square ? 'rounded-md w-[450px]' : 'rounded-full w-[375px]'
      }`}
    />
  </div>
)
