import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { FC } from 'react'
import { formatCurrency } from '../../helpers'

interface Props {
  tags: any[]
  colors: string[]
  total: number
  canClick: boolean
  // eslint-disable-next-line no-unused-vars
  onClick: (id: number) => void
  // eslint-disable-next-line no-unused-vars
  onVendorHighlight: (color: string) => void
}

export const CategoryTable: FC<Props> = ({
  tags,
  colors,
  total,
  onClick,
  onVendorHighlight,
  canClick,
}) => (
  <div className="space-y-2 flex flex-col justify-center">
    {tags.map((t, i) => (
      <button
        disabled={!canClick}
        type="button"
        onMouseEnter={() => onVendorHighlight(colors[i])}
        onMouseLeave={() => onVendorHighlight('')}
        key={`${t.id}-${t.value}`}
        onClick={() => onClick(t.tagId)}
        className={`hover:bg-stone-50 border border-transparent hover:border-slate-200 cursor-pointer w-full flex px-4 py-2.5 rounded-md items-center space-x-2 text-left -mx-4 text-sm transition-all font-medium ${
          canClick ? 'active group' : 'cursor-default'
        }`}
      >
        <div
          className="h-4 w-4 rounded transition-all"
          style={{ backgroundColor: colors[i] }}
        />
        <div className="font-semibold w-14">
          {((t.value * 100) / total).toFixed(2)}%
        </div>
        <div className="flex-1 capitalize">{t.label}</div>
        <span className="font-semibold">{formatCurrency(t.value)}</span>
        <ArrowRightIcon className="h-3 transition-all opacity-0 group-hover:opacity-100" />
      </button>
    ))}
  </div>
)

export const CategoryTableLoading = () => (
  <div className="space-y-6 flex flex-col justify-center pr-8">
    {new Array(7).fill('').map((_, i) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className="h-8 w-full bg-stone-200/50 rounded-md animate-pulse"
      />
    ))}
  </div>
)
