import {
  ArrowLeftOnRectangleIcon,
  ChartPieIcon,
  ChevronUpDownIcon,
  Cog8ToothIcon,
} from '@heroicons/react/24/outline'
import { Menu } from '@headlessui/react'

import { UserCircleIcon } from '@heroicons/react/24/solid'
import {
  Form,
  useBasename,
  useCreatePath,
  useGetIdentity,
  useLogout,
  useResourceDefinitions,
} from 'ra-core'
import { createElement, useMemo } from 'react'
import {
  Link,
  ReferenceInput,
  SelectInput,
  useDataProvider,
  usePermissions,
  useRefresh,
} from 'react-admin'
import { useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from './common/Button'
import userPermissions from '../Users/userPermissions'
import { getLabelSuffix } from '../helpers'

const UserMenu = () => {
  const { t, i18n } = useTranslation()
  const { permissions } = usePermissions()
  const { isLoading, identity } = useGetIdentity()
  const dp = useDataProvider()
  const logout = useLogout()
  const refresh = useRefresh()

  if (isLoading || !identity) return null

  let user: any = localStorage.getItem('user')
  if (user) {
    user = JSON.parse(user)
  }

  const onChangeOrg = async (e) => {
    if (e.target.value) {
      const org = await dp.getOne('organisations', { id: e.target.value })
      console.log(org)
      await dp.update('users', {
        previousData: undefined,
        id: identity?.id,
        data: {
          organisation_id: e.target.value,
          name: identity?.fullName,
          email: identity?.email,
        },
      })
      e.preventDefault()
      e.stopPropagation()
      refresh()
      user = {
        ...user,
        organisation: org.data,
        organisation_id: e.target.value,
      }
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('defaultCurrency', org.data?.currency?.code || 'GBP')
      localStorage.setItem('defaultNoun', org.data?.noun || 'SPEND')
    }
  }

  return (
    <div className="text-sm space-y-2 relative">
      <Menu>
        <Menu.Button className="w-full p-3 border rounded-md active hover:bg-white">
          <div className="text-left flex items-center justify-between w-full space-x-2">
            <UserCircleIcon className="opacity-50 h-4" />
            <div className="font-medium flex-1">{identity?.fullName}</div>
            <ChevronUpDownIcon className="h-4" />
          </div>
        </Menu.Button>
        <Menu.Items className="bottom-12 bg-white rounded-md absolute left-0 shadow overflow-hidden border border-b-0 p-3 w-64 z-30">
          <Menu.Item>
            <div className="space-y-3">
              <div className="space-y-1">
                <div className="font-medium">{identity?.fullName}</div>
                <div className="text-xs opacity-70">{identity?.email}</div>
              </div>
              <div className="border-t border-b py-3 -mx-3 px-3">
                <Link
                  to="/admin/profile"
                  className="w-full flex items-center space-x-2 text-indigo-600 font-medium"
                >
                  <Cog8ToothIcon className="h-4" />
                  <span>{t('sidebar.manage')}</span>
                </Link>
              </div>
              <Button
                className="w-full justify-center"
                onClick={() => {
                  i18n.changeLanguage('en')
                  logout()
                }}
                icon={<ArrowLeftOnRectangleIcon className="h-5" />}
              >
                {t('logout')}
              </Button>
            </div>
          </Menu.Item>
        </Menu.Items>
      </Menu>

      {permissions & userPermissions.SUPER_ADMIN ? (
        <Form record={identity}>
          <ReferenceInput source="organisation_id" reference="organisations">
            <SelectInput
              className="w-full"
              label="Organisation"
              optionText="name"
              optionValue="id"
              onChange={onChangeOrg}
            />
          </ReferenceInput>
        </Form>
      ) : null}
    </div>
  )
}

const NavigationItem = ({ icon, label, url }) => {
  const match = useMatch(url)
  return (
    <Link
      to={url}
      key={label}
      className={`text-sm hover:bg-stone-200/70 active flex items-center space-x-3 px-4 py-3 text-black rounded-lg min-w-[180px] border ${
        match
          ? '!font-medium bg-white/80 border-stone-300/70'
          : 'border-transparent'
      }`}
    >
      {icon}
      <span className="text-black pr-2">{label}</span>
    </Link>
  )
}

const Navigation = () => {
  const resources = useResourceDefinitions()
  const createPath = useCreatePath()
  const { t } = useTranslation()
  const basename = useBasename()
  const suffix = getLabelSuffix()

  const items = useMemo(() => {
    const getLabel = (name) => {
      if (name === 'transactions' && suffix === '_alt') return t(`claims`)
      if (name === 'vendors' && suffix === '_alt') return t(`suppliers`)
      return t(`${name}`)
    }

    return [
      {
        icon: <ChartPieIcon className="h-5 text-black" />,
        label: t('dashboard'),
        url: basename,
      },
      ...Object.keys(resources)
        .filter((name) => resources[name].hasList && name !== 'tags')
        .map((name) => ({
          icon: resources[name].icon ? (
            createElement(resources[name].icon, { className: 'h-5 text-black' })
          ) : (
            <>-</>
          ),
          url: createPath({
            resource: name,
            type: 'list',
          }),
          label: getLabel(name),
        })),
    ]
  }, [basename, createPath, resources, suffix, t])

  return (
    <nav className="space-y-1 -ml-4">
      {items.map((item) => (
        <NavigationItem
          url={item.url}
          icon={item.icon}
          label={item.label}
          key={`${item.label}-${item.url}`}
        />
      ))}
    </nav>
  )
}

export const Sidebar = () => (
  <aside className="min-h-screen flex flex-col justify-between sticky top-0 py-12 pr-8 border-r border-stone-200/60 w-[230px]">
    <div className="space-y-16">
      <a className="flex items-center space-x-3 active" href="/admin">
        <img src="/logo.png" className="h-7" alt="SpendQube logo" />
        <h1>
          Spend<span className="font-medium">Qube</span>
        </h1>
      </a>
      <Navigation />
    </div>
    <UserMenu />
  </aside>
)
