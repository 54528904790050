import { BOTTOM_VENDOR_COLOR, TOP_VENDOR_COLOR } from '../colors'
import { formatCurrencyCompact } from '../../helpers'

export const ParetoSpendLabel = ({ topVendors, bottomVendors, t }) => (
  <div className="flex flex-col justify-between">
    <h3 className="text-sm font-medium">{t('spend')}</h3>
    <div className="flex flex-col flex-1 justify-between">
      <div
        style={{ height: `${topVendors?.group}%` }}
        className="flex flex-col justify-center"
      >
        <div className="font-medium flex space-x-1.5 items-center justify-start">
          <div
            className="h-2 w-2 rounded-sm"
            style={{ backgroundColor: TOP_VENDOR_COLOR }}
          />
          <span>{topVendors?.group.toFixed(2)}%</span>
        </div>
        <div className="opacity-90 ml-3">
          {formatCurrencyCompact(topVendors?.amount)}
        </div>
      </div>
      <div
        style={{ height: `${bottomVendors?.group}%` }}
        className="flex flex-col justify-center"
      >
        <div className="font-medium flex space-x-1.5 items-center justify-start">
          <div
            className="h-2 w-2 rounded-sm"
            style={{ backgroundColor: BOTTOM_VENDOR_COLOR }}
          />
          <span>{bottomVendors?.group.toFixed(2)}%</span>
        </div>
        <div className="opacity-90 ml-3">
          {formatCurrencyCompact(bottomVendors?.amount)}
        </div>
      </div>
    </div>
  </div>
)
