import {
  Datagrid,
  EditButton,
  List,
  TextField,
  useRecordContext,
} from 'react-admin'

import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

import { TagIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Title } from '../components/Title'

const LinkToRelatedTags = () => {
  const record = useRecordContext()
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/admin/tags',
        search: `filter=${JSON.stringify({ tag_category_id: record.id })}`,
      }}
    >
      <TagIcon />
      Tags
    </Button>
  ) : null
}

const TagCategoryList = () => {
  const { t } = useTranslation()
  return (
    <section>
      <Title>{t('tag_categories')}</Title>
      <List sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <LinkToRelatedTags />
          <EditButton label="" />
        </Datagrid>
      </List>
    </section>
  )
}

export default TagCategoryList
