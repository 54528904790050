import classNames from 'classnames'
import { useMemo } from 'react'
import {
  ArrowsRightLeftIcon,
  CalendarDaysIcon,
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Listbox } from '@headlessui/react'

import useChartContext from '../Dashboard/charts/useChartContext'
import { formatDateRangeLabel } from '../helpers'

export const InvoiceDateFilterSummary = ({
  startDate,
  endDate,
  onClick,
  onCompareClick,
  compareWithEnabled,
}) => {
  const { compareOptions, selectedCompareOption, setSelectedCompareOption } =
    useChartContext()

  const handleDropdownChange = (val) => {
    const option = compareOptions.find((o) => o.id === val)
    setSelectedCompareOption(option)
    onCompareClick(val)
  }

  const deselectCompareOption = () => {
    setSelectedCompareOption(compareOptions[0])
    onCompareClick(compareOptions[0].id)
  }

  const showCompareUI = useMemo(
    () => selectedCompareOption.id !== '',
    [selectedCompareOption]
  )

  return (
    <div
      className={classNames(
        'flex items-center justify-between rounded-md border text-sm font-medium bg-white shadow-sm divide-x',
        {
          'border-indigo-600 divide-indigo-600':
            selectedCompareOption.id !== '',
          'border-gray-300 divide-gray-300': selectedCompareOption.id === '',
        }
      )}
    >
      <button
        type="button"
        onClick={() => onClick()}
        className="px-6 py-2 rounded-md hover:bg-slate-50 flex space-x-3 items-center h-full active flex-1"
      >
        <CalendarDaysIcon className="h-4" />
        <span className="font-semibold">
          {formatDateRangeLabel(startDate, endDate)}
        </span>
      </button>
      <div className="relative">
        {showCompareUI && (
          <div className="absolute top-0 bottom-0 -left-3 flex items-center justify-center z-20 pointer-events-none">
            <div className="p-1.5 rounded-full bg-indigo-600 text-white flex items-center justify-center">
              <ArrowsRightLeftIcon className="h-3" />
            </div>
          </div>
        )}
        <div className="relative">
          {showCompareUI && (
            <div className="absolute z-10 -top-3 -right-2.5">
              <button
                className="bg-indigo-600 text-white rounded-full p-1 active hover:bg-opacity-80"
                type="button"
                onClick={deselectCompareOption}
              >
                <XMarkIcon className="h-3.5" />
              </button>
            </div>
          )}
          {compareWithEnabled && (
            <Listbox
              value={selectedCompareOption}
              onChange={handleDropdownChange}
            >
              <Listbox.Button className="text-sm font-semibold flex items-center space-x-4 hover:bg-slate-50 pl-6 pr-3 py-2 rounded-r-md min-w-[200px] justify-between flex-1">
                <div className="flex items-center space-x-2">
                  <CalendarDaysIcon className="h-4" />
                  <span>{selectedCompareOption.name}</span>
                </div>
                <ChevronDownIcon className="h-3" />
              </Listbox.Button>
              <Listbox.Options className="absolute left-0 right-0 mt-0.5 shadow rounded-b-md border bg-white">
                {compareOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    value={option.id}
                    className={`py-2 px-3 hover:bg-slate-50 active text-xs cursor-pointer flex items-center justify-between ${
                      option.id === selectedCompareOption.id
                        ? 'font-semibold'
                        : ''
                    }}`}
                  >
                    <span>{option.name}</span>
                    {option.id === selectedCompareOption.id && (
                      <CheckIcon className="h-3" />
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          )}
        </div>
      </div>
    </div>
  )
}
