import { Fragment } from 'react'
import { Tab } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { VendorMap } from './maps/VendorMap'
import { SectionTitle } from '../components/Title'
import { NoData } from './NoData'
import { getLabelSuffix } from '../helpers'

const mapOn = true

let displayMap1 = <NoData />
let displayMap2 = <NoData />

if (mapOn) {
  displayMap1 = <VendorMap dataType="spending" />
  displayMap2 = <VendorMap dataType="transactions" />
}

export const MapSection = () => {
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  return (
    <div>
      <Tab.Group>
        <div className="flex items-center justify-between">
          <SectionTitle
            title={t(`home.chart_title_6${suffix}`)}
            subtitle={t(`home.chart_desc_6${suffix}`)}
          />
          <Tab.List className="flex bg-stone-100 border items-center space-x-1 text-sm p-1 rounded-md">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={`px-3 py-2.5 rounded transition-all font-semibold active
                ${
                  selected
                    ? 'bg-white'
                    : 'text-stone-500 hover:text-stone-700 hover:bg-white/70'
                }
                `}
                >
                  {t(`total_spend${suffix}`)}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={`px-3 py-2.5 rounded transition-all font-semibold active
                ${
                  selected
                    ? 'bg-white'
                    : 'text-stone-500 hover:text-stone-700 hover:bg-white/70'
                }
                `}
                >
                  {t(`no_transactions${suffix}`)}
                </button>
              )}
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels className="border-t mt-6 border-stone-200/70 -mx-6 bg-gradient-to-b from-sky-100 via-sky-200/70 to-sky-200/90 -mb-6">
          <Tab.Panel>{displayMap1}</Tab.Panel>
          <Tab.Panel>{displayMap2}</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
