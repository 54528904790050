import {
  BooleanInput,
  DeleteWithConfirmButton,
  Edit,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin'
import userPermissions from '../Users/userPermissions'

const EditToolbar = (props: any) => {
  const record = useRecordContext()

  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmContent="You will not be able to recover this record. Are you sure?"
        translateOptions={{ name: record.name }}
      />
    </Toolbar>
  )
}

const OrganisationEdit = () => {
  const { permissions } = usePermissions()

  const transform = (data) => {
    try {
      data.import_config = JSON.stringify(data.import_config)
      return data
    } catch (e) {
      console.log(e)
      return data
    }
  }

  return (
    <Edit transform={transform}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="name" />
        <TextInput source="price_field" />
        <TextInput
          source="import_config"
          format={(v) => {
            return JSON.stringify(v)
          }}
          parse={(v) => {
            return JSON.parse(v)
          }}
          multiline
          fullWidth
        />
        <ReferenceInput source="currency_id" reference="currencies">
          <SelectInput label="Currency" optionText="name" optionValue="id" />
        </ReferenceInput>
        <TextInput source="groups_report_extra_tags" />
        {permissions & userPermissions.SUPER_ADMIN ? (
          <BooleanInput source="is_reference" />
        ) : null}
        {permissions & userPermissions.SUPER_ADMIN ? (
          <SelectInput
            source="noun"
            choices={[
              { id: 'CLAIM', name: 'CLAIM' },
              { id: 'SPEND', name: 'SPEND' },
            ]}
          />
        ) : null}
        {permissions & userPermissions.SUPER_ADMIN ? (
          <NumberInput source="priority" />
        ) : null}
      </SimpleForm>
    </Edit>
  )
}

export default OrganisationEdit
