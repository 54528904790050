import { ChartPieIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { Button } from '../components/common/Button'
import useChartContext from './charts/useChartContext'

export const NoData = () => {
  const { t } = useTranslation()
  const { clearFilter } = useChartContext()
  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="space-y-3 text-center text-slate-500 -rotate-1 border border-yellow-100 shadow bg-yellow-50 flex flex-col items-center px-6 py-8 rounded-lg hover:shadow-md">
        <ChartPieIcon className="h-10" />
        <h3 className="text-sm font-medium">{t('not_enough_data')}</h3>
        <Button onClick={() => clearFilter()}>{t('clear_filter')}</Button>
      </div>
    </div>
  )
}
