import { useTranslation } from 'react-i18next'
import { SectionTitle } from '../../components/Title'
import { getLabelSuffix } from '../../helpers'
import MonthlySpendByTagsChart from '../charts/MonthlySpendByTagsChart'

export const TotalSpendByPreferredStatusSection = () => {
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  return (
    <div className="space-y-6">
      <SectionTitle
        title={t('home.chart_title_5')}
        subtitle={t(`home.chart_desc_1${suffix}`)}
      />
      <MonthlySpendByTagsChart />
    </div>
  )
}
