import { Autocomplete, TextField } from '@mui/material'
import { useDataProvider } from 'react-admin'
import { ValueEditorProps } from 'react-querybuilder'
import { useEffect, useState } from 'react'

const VendorValueEditor = (props: ValueEditorProps) => {
  const [loading, setLoading]: any = useState(true)
  const [dataLoading, setDataLoading]: any = useState(true)
  const [options, setOptions]: any = useState([])
  const [defaultValue, setDefaultValue]: any = useState([])
  const dp = useDataProvider()

  const handleChange = (value) => {
    props.handleOnChange(value)
  }

  const defaultValues = {
    id: null,
  }

  useEffect(() => {
    console.log('props.value', props.value)
    console.log(props.value)
    dp.getList('vendors', {
      filter: null,
      sort: { field: 'name', order: 'asc' },
      pagination: { page: 1, perPage: 10000 },
    }).then((response) => {
      console.log(response)
      setOptions(response.data)
      setLoading(false)
    })
    if (props.value && props.value.length) {
      dp.getMany('vendors', { ids: props.value.join(',') }).then(
        (response: any) => {
          console.log(response)
          setDefaultValue(response.data)
          setDataLoading(false)
        }
      )
    } else {
      setDataLoading(false)
    }
  }, [dp, props])

  if (props.field && props.value) {
    defaultValues.id = props.value
  }

  return (
    <div>
      {!loading && !dataLoading && (
        <Autocomplete
          multiple
          style={{ minWidth: '500px', maxWidth: '500px' }}
          id="tags-standard"
          options={options}
          getOptionLabel={(option: any) => option.name}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Multiple values"
              placeholder="Vendors"
            />
          )}
          onChange={(e, value) => {
            console.log('onChange')
            console.log(e)
            console.log(value)
            const newVal = value.map((v) => {
              return v.id
            })
            console.log(newVal)
            handleChange(newVal)
          }}
          fullWidth
        />
      )}
    </div>
  )
}

export default VendorValueEditor
