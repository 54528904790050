import * as React from 'react'
import {
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useAuthProvider,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin'

export const MyToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
)

const ProfileEdit = () => {
  const authProvider = useAuthProvider()
  const [identity, setIdentity] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  React.useEffect(() => {
    authProvider
      .getIdentity?.()
      .then(({ id }) => {
        setIdentity(String(id))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [authProvider])

  if (loading || identity === '') {
    return null
  }

  const onSuccess = async (data) => {
    const details = {
      fullName: data.name,
      ...data,
    }
    localStorage.setItem('user', JSON.stringify(details))
    notify(`Changes saved`)
    redirect('/admin/users')
    refresh()
  }

  return (
    <Edit
      resource="users"
      id={identity}
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm toolbar={<MyToolbar />}>
        <TextInput source="name" sx={{ minWidth: 320 }} />
        {/* <TextInput source="email" sx={{ minWidth: 320 }} /> */}
      </SimpleForm>
    </Edit>
  )
}

export default ProfileEdit
