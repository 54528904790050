import { PointTooltipProps, ResponsiveLine } from '@nivo/line'
import { useTranslation } from 'react-i18next'

import { formatCurrency, formatCurrencyCompact } from '../../helpers'
import { useChartCompare } from '../../helpers/useChartCompare'

import { NoData } from '../NoData'
import { CompareLegend } from '../CompareLegend'

const theme = {
  grid: {
    line: {
      stroke: '#f0f0f0',
    },
  },
}

const MAX_X_LENGTH = 20
const CHART_COLORS = ['#904a9e', '#4338ca']

const renderTooltip = ({ point }: PointTooltipProps) => {
  return (
    <div className="rounded shadow p-3 bg-white text-sm border border-stone-100 flex space-x-3 text-left">
      <div
        className="w-1 h-10 rounded-lg"
        style={{ backgroundColor: point.serieColor }}
      />

      <div className="space-y-0.5">
        {point.serieId === 'compare' ? (
          <div className="font-medium text-xs text-indigo-600">Compare</div>
        ) : (
          <div className="text-gray-500 font-medium">
            {point.data.xFormatted}
          </div>
        )}
        <div className="font-semibold">{formatCurrency(point.data.y)}</div>
      </div>
    </div>
  )
}

const MonthlySpend = () => {
  const chartType = 'spendPerMonth'
  const { t } = useTranslation()

  const { formattedData, isLoading, isChartLong } = useChartCompare({
    chartType,
    isCurrency: true,
    serieName: 'spending',
    maxLength: MAX_X_LENGTH,
  })

  if (isLoading)
    return (
      <div className="space-y-4 flex-1">
        <h2 className="font-semibold">{t('spending_per_month')}</h2>
        <div className="h-[300px] bg-stone-50/70 rounded-md animate-pulse" />
      </div>
    )

  return (
    <div className="space-y-4 flex-1">
      <h2 className="font-semibold">{t('spending_per_month')}</h2>
      <div className="h-[300px]">
        {formattedData.length ? (
          <ResponsiveLine
            curve="monotoneX"
            enablePoints
            theme={theme}
            data={formattedData}
            margin={{
              top: 10,
              right: 15,
              bottom: isChartLong ? 35 : 20,
              left: 50,
            }}
            lineWidth={3}
            pointSize={6}
            pointColor="white"
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            enableGridX
            axisLeft={{
              format: (value) => formatCurrencyCompact(value),
            }}
            axisBottom={{
              tickRotation: isChartLong ? -40 : 0,
            }}
            colors={CHART_COLORS}
            enableArea
            areaOpacity={0.1}
            areaBlendMode="normal"
            useMesh
            tooltip={renderTooltip}
            enableCrosshair={false}
          />
        ) : (
          <NoData />
        )}
      </div>
      <CompareLegend colors={CHART_COLORS} />
    </div>
  )
}

export default MonthlySpend
