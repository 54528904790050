import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'
import { FC } from 'react'
import { QueryBuilderPayload } from '../helpers/useQueryFilter'

interface Props extends QueryBuilderPayload {
  onClick: Function
  t: any
}

export const QuerySummary: FC<Props> = ({
  t,
  numberOfRules,
  clearFilter,
  onClick,
}) => {
  if (numberOfRules < 1)
    return (
      <button
        type="button"
        onClick={() => onClick()}
        className="px-3 py-2 rounded-md border border-gray-300 hover:bg-slate-50 text-sm font-medium flex space-x-3 items-center h-full bg-white shadow-sm active"
      >
        <AdjustmentsHorizontalIcon className="h-4" />
        <span>{t('filter')}</span>
      </button>
    )

  return (
    <div className="flex items-center rounded-md text-sm font-medium h-full divide-x bg-indigo-600 text-white divide-indigo-700/60 overflow-hidden">
      <button
        type="button"
        onClick={() => onClick()}
        className="active py-2 px-3 rounded hover:bg-indigo-600"
      >
        <span className="font-semibold">{numberOfRules}</span> filter(s) applied
      </button>
      <button
        className="py-2 px-3 bg-indigo-700 hover:bg-opacity-10 h-full active"
        type="button"
        onClick={() => clearFilter()}
      >
        {t('clear_filter')}
      </button>
    </div>
  )
}
