import { Show, SimpleShowLayout, TextField } from 'react-admin'

const TagCategoryShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" emptyText="N/A" />
      </SimpleShowLayout>
    </Show>
  )
}

export default TagCategoryShow
