import {
  BooleanInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
} from 'react-admin'

import { useTranslation } from 'react-i18next'
import userPermissions from './userPermissions'
import { getLabelSuffix } from '../helpers'

const UserCreate = () => {
  const { t } = useTranslation()
  const { permissions } = usePermissions()
  const suffix = getLabelSuffix()

  const transform = (values) => {
    let perm = 0

    for (const p in values._perm) {
      if (values._perm[p] === true) {
        if (userPermissions[p]) {
          perm += userPermissions[p]
        } else {
          console.log(p)
        }
      }
    }

    values.permission_flags = perm
    return values
  }

  const defaultPerms: any = []
  defaultPerms.LOGIN = true
  defaultPerms.VIEW_TRANSACTIONS = true

  const defaultValues = () => ({
    _perm: defaultPerms,
  })

  return (
    <Create transform={transform}>
      <SimpleForm defaultValues={defaultValues}>
        {permissions & userPermissions.SUPER_ADMIN ? (
          <ReferenceInput source="organisation_id" reference="organisations">
            <SelectInput
              label={t('organisation')}
              optionText="name"
              optionValue="id"
            />
          </ReferenceInput>
        ) : null}
        <TextInput label={t('name')} source="name" />
        <TextInput label={t('email')} source="email" />
        <h3>{t('permissions')}</h3>
        <h4>{t('login')}</h4>
        <span>{t('can_login')}</span>
        <BooleanInput label="Can login" source="_perm[LOGIN]" />
        <h4>{t('users')}</h4>
        <span>{t('view_users')}</span>
        <BooleanInput label="Can view" source="_perm[VIEW_USERS]" />
        <span>{t('create_users')}</span>
        <BooleanInput label="Can create" source="_perm[CREATE_USERS]" />
        <span>{t('edit_users')}</span>
        <BooleanInput label="Can edit" source="_perm[EDIT_USERS]" />
        <h4>
          {t('data')} / {t(`transaction${suffix}`)} / {t(`vendors${suffix}`)}
        </h4>
        <span>{t('view_transactions')}</span>
        <BooleanInput label="Can view" source="_perm[VIEW_TRANSACTIONS]" />
        <span>{t('create_transactions')}</span>
        <BooleanInput label="Can create" source="_perm[CREATE_TRANSACTIONS]" />
        <span>{t(`edit_transactions${suffix}`)}</span>
        <BooleanInput label="Can edit" source="_perm[EDIT_TRANSACTIONS]" />
        <span>{t(`delete_transactions${suffix}`)}</span>
        <BooleanInput label="Can delete" source="_perm[DELETE_TRANSACTIONS]" />
        <h4>{t('tag_categories')}</h4>
        <span>{t('view_tag_cat')}</span>
        <BooleanInput label="Can view" source="_perm[VIEW_TAG_CATS]" />

        {permissions & userPermissions.SUPER_ADMIN ? (
          <>
            <h4>{t('superuser')}</h4>
            <BooleanInput
              label={t('superuser_permissions')}
              source="_perm[SUPER_ADMIN]"
            />
          </>
        ) : null}
      </SimpleForm>
    </Create>
  )
}

export default UserCreate
