import { FC } from 'react'
import Flag from 'react-world-flags'

import { sanitiseCountryCode } from '../../helpers'
import { VendorRow } from '../../types'

interface Props {
  t: any
  suffix: any
  showTotalSpend: boolean
  // eslint-disable-next-line no-unused-vars
  onClick: (id: number) => void
  vendors: VendorRow[]
}

export const TopVendorsTable: FC<Props> = ({
  showTotalSpend,
  onClick,
  vendors,
  t,
  suffix,
}) => (
  <div className="border border-stone-200/50 rounded-md overflow-hidden">
    <table className="table-auto table w-full font-medium">
      <thead className="bg-stone-100 py-1">
        <tr className="text-left text-sm">
          <th className="py-2 pr-3 font-semibold pl-6" colSpan={2}>
            {t('company')}
          </th>
          <th className="py-2 pl-3 pr-6 font-semibold text-right">
            {showTotalSpend
              ? t(`total_spend${suffix}`)
              : t(`transactions${suffix}`)}
          </th>
        </tr>
      </thead>
      <tbody>
        {vendors.map((vendor) => (
          <tr
            key={vendor.id}
            className="text-sm border-b border-stone-200/50 active cursor-pointer hover:bg-stone-100"
            onClick={() => onClick(vendor.id)}
          >
            <td className="w-12 pl-6">
              <Flag
                code={sanitiseCountryCode(vendor.country)}
                className="w-6"
              />
            </td>
            <td className="py-2 pr-3 pl-2 capitalize">{vendor.label}</td>
            <td className="py-3 pl-3 pr-6 font-semibold border-l border-stone-200/50 text-right">
              {vendor.formattedValue}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)
