export const VENDOR_TAG_ID = 'vendor_id'
export const GROUP_ID = 'group_id'
export const PREFERRED_SUPPLIER_STATUS_CAT_ID = 18

export const CATEGORY_USER = 'User'
export const CATEGORY_DATA = 'Data'
export const CATEGORY_DASHBOARD = 'Dashboard'
export const EVENT_LOGGED_IN = 'Logged In'
export const EVENT_CLICKED_FILTER = 'Clicked Filter Button'

export const FIXED_WIDGETS = [1, 2]

export const PRECEDING_PERIOD = '1'
export const SAME_PERIOD_LAST_YEAR = '2'
export const THIS_YEAR_TO_DATE = '5'
export const LAST_YEAR = '6'
export const LAST_YEAR_OF_UPLOAD = '7'
export const LAST_MONTH_OF_UPLOAD = '8'
export const THIS_QUARTER = '9'
export const LAST_QUARTER_OF_UPLOAD = '10'
export const CUSTOM_PERIOD = '11'

export const PAGE_SIZE = 5
