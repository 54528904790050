import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { getLabelSuffix } from './helpers'

const LabelContext = createContext({})

const SPEND = 'SPEND'
const CLAIM = 'CLAIM'

const useLabel: any = () => {
  const context = useContext(LabelContext)
  if (!context) {
    throw new Error('useLabel must be used within a LabelProvider')
  }
  return context
}

const LabelProvider = ({ children }) => {
  const [suffix, setSuffix] = useState(getLabelSuffix())

  // Add a listener to local storage changes
  useEffect(() => {
    const handleStorageChange = () => {
      setSuffix(getLabelSuffix())
    }

    // Listen for the storage event on the window
    window.addEventListener('storage', handleStorageChange)

    // Remove the listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, []) // Empty dependency array means this effect runs once after initial render

  const value = useMemo(() => {
    return { suffix }
  }, [suffix])

  return <LabelContext.Provider value={value}>{children}</LabelContext.Provider>
}

export { SPEND, CLAIM, LabelProvider, useLabel }
