import { Create, SimpleForm, TextInput } from 'react-admin'
import TagsInput from '../components/VendorTagsInput'

const VendorCreate = () => {
  const transform = (data) => {
    const tags = data.tags.map((t) => {
      return t.id
    })

    return {
      ...data,
      territory_id: 1,
      tags,
    }
  }

  return (
    <Create transform={transform} redirect="show">
      <SimpleForm>
        <TextInput source="name" />
        <TagsInput source="tags" />
      </SimpleForm>
    </Create>
  )
}

export default VendorCreate
