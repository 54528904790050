import {
  ArrayField,
  Datagrid,
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceManyField,
  usePermissions,
  SingleFieldList,
  ChipField,
} from 'react-admin'
import { useRecordContext } from 'ra-core'
import get from 'lodash/get'
import userPermissions from '../Users/userPermissions'

const ReferenceVendorTagsField = (props) => {
  const { source } = props
  const record = useRecordContext(props)
  const value = get(record, source)
  return (
    <div>
      <div className="border border-stone-200/50 rounded-md overflow-hidden">
        <table className="table-auto table w-full">
          <thead className="py-1">
            <tr className="text-left text-sm">
              <th className="py-2 pr-3 pl-2 font-semibold">
                Tag category name
              </th>
              <th className="py-2 pl-3 pr-6 font-semibold">Name</th>
            </tr>
          </thead>
          <tbody>
            {value.map((tag) => (
              <tr
                key={tag.id}
                className="text-sm border-b border-stone-200/50 active cursor-pointer hover:bg-stone-100"
              >
                <td className="py-2 pr-3 pl-2 capitalize">
                  {tag.tag_category}
                </td>
                <td className="py-3 pl-3 pr-6 border-l border-stone-200/50">
                  {tag.tag}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const VendorShow = () => {
  const { permissions } = usePermissions()

  let user: any = localStorage.getItem('user')
  if (user) {
    user = JSON.parse(user)
  }

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" emptyText="N/A" />

        <ReferenceManyField
          reference="vendor_names"
          target="vendor_id"
          label="Also known as"
        >
          {permissions & userPermissions.SUPER_ADMIN ? (
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" />
              <TextField source="source" />
              <TextField source="priority" />
              <ReferenceVendorTagsField source="reference_vendor_coding" />
            </Datagrid>
          ) : (
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          )}
        </ReferenceManyField>

        {user && [1, 4].includes(user.organisation_id) && (
          <TextField source="company_number" emptyText="N/A" />
        )}
        {user && [1, 4].includes(user.organisation_id) && (
          <TextField source="credit_score" emptyText="N/A" />
        )}
        {user && [1, 4].includes(user.organisation_id) && (
          <TextField source="address_line_1" emptyText="N/A" />
        )}
        {user && [1, 4].includes(user.organisation_id) && (
          <TextField source="post_code" emptyText="N/A" />
        )}
        {user && [1, 4].includes(user.organisation_id) && (
          <TextField source="company_category" emptyText="N/A" />
        )}
        {user && [1, 4].includes(user.organisation_id) && (
          <TextField source="company_status" emptyText="N/A" />
        )}
        {user && [1, 4].includes(user.organisation_id) && (
          <TextField source="origin_country" emptyText="N/A" />
        )}
        {user && [1, 4].includes(user.organisation_id) && (
          <DateField source="dissolution_date" emptyText="N/A" />
        )}
        {user && [1, 4].includes(user.organisation_id) && (
          <DateField source="change_of_name_date" emptyText="N/A" />
        )}
        {user && [1, 4].includes(user.organisation_id) && (
          <TextField source="previous_company_name" emptyText="N/A" />
        )}
        <ArrayField source="tags" label="Organisation tags">
          <Datagrid bulkActionButtons={false}>
            <TextField source="tag_category.name" />
            <TextField source="name" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}

export default VendorShow
