import { useMemo } from 'react'
import useChartContext from '../Dashboard/charts/useChartContext'

export const CurrentQueryTerms = () => {
  const { rules } = useChartContext()
  const invoiceDates = useMemo(
    () => rules.filter((rule) => rule.field === 'invoice_date'),
    [rules]
  )

  if (invoiceDates.length === 0) {
    return null
  }

  const values = invoiceDates.map((i) => {
    const dates = i.value.split(',')
    const fromDate = new Date(dates[0])
    const toDate = new Date(dates[1])
    const dateTxt = `from ${fromDate.toLocaleDateString()} to ${toDate.toLocaleDateString()}`

    return dateTxt
  })

  const dispTxt = `Dates filtered - ${values.join('; ')}`

  return <span className="text-slate-500 text-sm my-auto">{dispTxt}</span>
}
