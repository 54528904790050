import { FC, Fragment, ReactNode } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

interface Props {
  children: ReactNode
  // eslint-disable-next-line no-unused-vars
  renderButton: (onClick: Function) => ReactNode
  title?: string
  isOpen: boolean
  onClose: Function
  onOpen: Function
  className?: string
}

export const Modal: FC<Props> = ({
  children,
  renderButton,
  title,
  isOpen,
  onClose,
  onOpen,
  className,
}) => {
  return (
    <>
      {renderButton(() => onOpen())}
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          open={isOpen}
          onClose={() => onClose()}
          className="relative z-50"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center p-4">
              {/* The actual dialog panel  */}
              <Dialog.Panel
                className={`${
                  className ?? ''
                } rounded-lg bg-stone-50 overflow-hidden`}
              >
                <div className="px-6 py-5">
                  <div className="flex items-center justify-between mb-2">
                    {title && (
                      <h2 className="text-lg font-semibold text-slate-800">
                        {title}
                      </h2>
                    )}
                    <button
                      type="button"
                      onClick={() => onClose()}
                      className="p-2 rounded hover:bg-stone-100 active -mr-2"
                    >
                      <XMarkIcon className="h-5" />
                    </button>
                  </div>
                  {children}
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  )
}
