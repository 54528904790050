const VendorMatchFlags = [
  // { id: '1', name: 'Manually matched' },
  // { id: '2', name: 'Confirmed match' },
  { id: '4', name: 'Perfect match' },
  // { id: '8', name: 'Organisation match' },
  { id: '16', name: 'Partial match' },
  { id: '32', name: 'No match' },
  // { id: '64', name: 'Vendor created' },
]

export default VendorMatchFlags
