const userPermissions = {
  LOGIN: 1,
  VIEW_USERS: 2,
  CREATE_USERS: 4,
  EDIT_USERS: 8,
  DELETE_USERS: 16,
  VIEW_TRANSACTIONS: 32,
  CREATE_TRANSACTIONS: 64,
  EDIT_TRANSACTIONS: 128,
  DELETE_TRANSACTIONS: 256,
  VIEW_TAG_CATS: 512,
  SUPER_ADMIN: 1024,
}

export default userPermissions
