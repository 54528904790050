import {
  ArrayInput,
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'

const TagEdit = () => {
  const transform = (data) => {
    return {
      ...data,
      tag_category_id: data.tag_category.id,
      parent_id: null,
    }
  }

  return (
    <Edit mutationMode="pessimistic" transform={transform}>
      <SimpleForm>
        <h2 className="font-semibold mb-4">Edit a tag</h2>
        <TextInput source="name" sx={{ minWidth: 300 }} />
        <ReferenceInput source="tag_category.id" reference="tag_categories">
          <SelectInput
            label="Tag category"
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
        <ArrayInput source="translations">
          <SimpleFormIterator inline>
            <SelectInput
              source="language"
              choices={[
                { id: 'es', name: 'Espanol' },
                { id: 'it', name: 'Italian' },
                { id: 'pl', name: 'Polish' },
              ]}
            />
            <TextInput source="name" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default TagEdit
