import React, { ReactElement, useCallback, useState } from 'react'

interface Props {
  onConfirm: () => void
  // eslint-disable-next-line no-unused-vars
  render: (confirming: boolean, handleClick: () => void) => ReactElement
  timeout?: number
}

export const Confirm: React.FC<Props> = ({ onConfirm, render, timeout }) => {
  const [confirming, setConfirming] = useState(false)
  const [seconds] = useState(timeout || 2000)

  const handleClick = useCallback(() => {
    if (confirming) {
      onConfirm()
    } else {
      setConfirming(true)
      setTimeout(() => {
        setConfirming(false)
      }, seconds)
    }
  }, [confirming, onConfirm, setConfirming, seconds])

  return render(confirming, handleClick)
}
