import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionTitle } from '../../components/Title'
import ParetoChart from '../charts/ParetoChart'
import { ChartView } from './ChartView'

export const ParetoAnalysisSection = () => {
  const { t } = useTranslation()
  const [view, setView] = useState(ChartView.vendor)
  const [topIndex, setTopIndex] = useState(0)
  const [bottomIndex, setBottomIndex] = useState(1)

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between mb-6">
        <SectionTitle
          title={t('home.chart_title_4')}
          subtitle={t('home.chart_desc_4')}
        />
        <button
          type="button"
          onClick={() => {
            if (view === ChartView.vendor) {
              setView(ChartView.spend)
            } else {
              setView(ChartView.vendor)
            }
            if (topIndex === 0) {
              setTopIndex(2)
              setBottomIndex(3)
            } else {
              setTopIndex(0)
              setBottomIndex(1)
            }
          }}
          className="px-3.5 py-2.5 rounded-md transition-all font-semibold bg-stone-100 active text-sm hover:bg-opacity-70"
        >
          {t('toggle_view')}
        </button>
      </div>
      <ParetoChart view={view} topIndex={topIndex} bottomIndex={bottomIndex} />
    </div>
  )
}
