import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
} from '@heroicons/react/24/outline'
import { FC, ReactNode } from 'react'

interface Props {
  label: string
  value?: ReactNode
  compareValue?: ReactNode
  deltaChange?: number
  icon: ReactNode
  loading?: boolean
  background: string
  onClick?: any
  isSelected: boolean
}

const DeltaChange = ({ value }: { value?: number }) => {
  if (value === undefined || value === 0) return null
  return (
    <div className="pt-0.5">
      <div className="text-xs font-semibold bg-indigo-600 text-white px-2 py-1 rounded-full inline-flex items-center space-x-1">
        {value > 0 ? (
          <>
            <ArrowTrendingUpIcon className="h-3.5" />
            <span>+{value.toFixed(0)}%</span>
          </>
        ) : (
          <>
            <ArrowTrendingDownIcon className="h-3.5" />
            <span>-{Math.abs(value).toFixed(2)}%</span>
          </>
        )}
      </div>
    </div>
  )
}

export const Stat: FC<Props> = ({
  label,
  value,
  compareValue,
  deltaChange,
  icon,
  loading,
  background,
  onClick,
  isSelected,
}) => (
  <button
    type="button"
    className={`flex-1 flex items-center space-x-4 px-6 py-3 active text-left border-b w-full ${
      isSelected
        ? 'bg-white border-transparent'
        : 'bg-stone-50/50 hover:bg-white border-stone-200/70'
    }`}
    onClick={onClick}
  >
    <div
      className={`${background} rounded-lg h-9 w-9 flex items-center justify-center transition-all hover:-rotate-1`}
    >
      {icon}
    </div>
    <div>
      <div className="flex items-center space-x-2">
        <h2 className="font-medium text-sm text-slate-500 leading-relaxed">
          {label}
        </h2>
      </div>
      <div className="flex items-baseline space-x-2">
        <div className="font-semibold text-xl">
          {loading ? (
            <div className="bg-stone-100 h-6 w-32 rounded-md animate-pulse" />
          ) : (
            value || <span className="font-normal opacity-50 text-sm">-</span>
          )}
        </div>
        {compareValue && (
          <div className="text-sm font-medium">/ {compareValue}</div>
        )}
      </div>
      {compareValue && <DeltaChange value={deltaChange} />}
    </div>
  </button>
)
