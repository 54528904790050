import { addMonths, differenceInMonths, format } from 'date-fns'
import { CLAIM } from '../labelProvider'
import { DnBRating } from '../types'

export const formatNumber = (amount) =>
  new Intl.NumberFormat(undefined, {
    notation: 'standard',
  }).format(amount)

export const replaceInArray = (arr: any[], index: number, item: any) => [
  ...arr.slice(0, index),
  item,
  ...arr.slice(index),
]

export const removeFromArray = (arr: any[], index: number) => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1),
]

const defaultCurrency = 'GBP'
const defaultNoun = 'SPEND'

export const formatCurrency = (amount, currency: any = '', digits: any = 0) => {
  if (!currency) {
    currency = localStorage.getItem('defaultCurrency') || defaultCurrency
  }

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    maximumFractionDigits: digits,
  })

  return formatter.format(amount)
}

export const getLabelSuffix = () => {
  const noun = localStorage.getItem('defaultNoun') || defaultNoun
  if (noun === CLAIM) {
    return '_alt'
  }
  return ''
}

export const formatCurrencyCompact = (amount, currency: any = '') => {
  if (!currency) {
    currency = localStorage.getItem('defaultCurrency') || defaultCurrency
  }
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    notation: 'compact',
  })

  return formatter.format(amount)
}

export const sanitiseCountryCode = (code: string): string => {
  switch (code) {
    case 'UK':
      return 'GB'
    default:
      return code
  }
}

export const getTotalMonthsInRange = (dateRange) => {
  let totalMonthsInRange = 12
  if (dateRange) {
    totalMonthsInRange = differenceInMonths(
      dateRange.endDate,
      dateRange.startDate
    )
  }
  return totalMonthsInRange
}

export const getFormattedDataChart = (data, dateRange, divide = false) => {
  const ret: any = []

  if (data && dateRange) {
    const totalMonthsInRange = getTotalMonthsInRange(dateRange)
    const arr = Array.from({ length: totalMonthsInRange + 1 }, (x, i) => i)
    arr.forEach((index) => {
      const d = addMonths(dateRange.startDate, index)
      const x = `${d.getMonth() + 1}/${d.getFullYear()}`
      const foundData = data ? data.find((f) => f.month === x) : null
      let y
      if (divide) {
        y = foundData ? foundData.amount / 100 : 0
      } else {
        y = foundData ? foundData.amount : 0
      }
      ret.push({ x, y })
    })
  }

  return ret
}

export const getFormattedDataWithCompareChart = (
  data,
  compareData,
  dateRange,
  compareRange,
  divide = false
) => {
  const ret: any = []
  const compareRet: any = []

  if (data && dateRange) {
    const totalMonthsInRange = getTotalMonthsInRange(dateRange)
    const arr = Array.from({ length: totalMonthsInRange + 1 }, (x, i) => i)
    arr.forEach((index) => {
      const d = addMonths(dateRange.startDate, index)
      const dCompare = addMonths(compareRange.startDate, index)
      const x = `${d.getMonth() + 1}/${d.getFullYear()}`
      const xCompare = `${dCompare.getMonth() + 1}/${dCompare.getFullYear()}`

      const foundData = data ? data.find((f) => f.month === x) : null
      const foundCompareData =
        compareData?.find((f) => f.month === xCompare) ?? null
      let y
      let compareY
      if (divide) {
        y = foundData ? foundData.amount / 100 : 0
        compareY = foundCompareData ? foundCompareData.amount / 100 : 0
      } else {
        y = foundData ? foundData.amount : 0
        compareY = foundCompareData ? foundCompareData.amount : 0
      }
      ret.push({ x, y })
      compareRet.push({ x, y: compareY })
    })
  }

  return [ret, compareRet]
}

export const getAllMonths = (dateRange) => {
  const ret: any = []

  if (dateRange) {
    const totalMonthsInRange = getTotalMonthsInRange(dateRange)
    const arr = Array.from({ length: totalMonthsInRange + 1 }, (x, i) => i)
    arr.forEach((index) => {
      const d = addMonths(dateRange.startDate, index)
      const x = `${d.getMonth() + 1}/${d.getFullYear()}`
      ret[x] = { month: x }
    })
  }
  return ret
}

const DATE_FORMAT = 'LLL dd, yyyy'
export const formatDate = (date: Date) => format(date, DATE_FORMAT)

export const formatDateRangeLabel = (startDate: Date, endDate: Date) =>
  [
    !startDate ? '' : formatDate(startDate),
    !endDate ? '' : formatDate(endDate),
  ].join(' - ')

const dateFormat = 'yyyy-MM-dd'
export const createDateRangeQueryRule = (startDate: Date, endDate: Date) => {
  return {
    field: 'invoice_date',
    value: [format(startDate, dateFormat), format(endDate, dateFormat)].join(
      ','
    ),
    operator: 'between',
    valueSource: 'value',
  }
}

export const addDateRangeToQuery = (
  query: string,
  startDate: Date,
  endDate: Date
) => ({
  combinator: 'and',
  not: false,
  rules: [JSON.parse(query), createDateRangeQueryRule(startDate, endDate)],
})

const NO_RATING = [
  'NORAT',
  'DS',
  'BR',
  '1R',
  '2R',
  'ER',
  '3AS',
  '4AS',
  '5AS',
  'N',
  'O',
]

export const getDnBRating = (rating: string): DnBRating => {
  const isNoRating = NO_RATING.some((prefix) => rating.startsWith(prefix))
  if (isNoRating) return 'Everything else'

  const lastRatingChar = rating[rating.length - 1]
  if (lastRatingChar === '-') return 'Insufficient Information'
  if (parseInt(lastRatingChar, 10) > 5) return 'Everything else'

  return lastRatingChar as DnBRating
}
