import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { Feature } from 'flagged'
import { useTranslation } from 'react-i18next'
import { Title } from '../components/Title'
import { ChartContextProvider } from './charts/ChartContextProvider'
import { TotalSpendSection } from './stats/TotalSpendSection'
import { TotalSpendByPreferredStatusSection } from './stats/TotalSpendByPreferredStatusSection'
import { RefreshButton } from '../components/common/RefreshButton'
import { useQueryFilter } from '../helpers/useQueryFilter'
import { QueryBuilderModal } from '../components/QueryBuilderModal'
import { CurrentQueryTerms } from '../components/CurrentQueryTerms'
import { VendorsSection } from './VendorsSection'
import { CategorySection } from './CategorySection'
import { MapSection } from './MapSection'
import { InvoiceDateFilterModal } from '../components/InvoiceDateFilterModal'
import { CustomisationModal } from '../components/CustomisationModal'
import { Widget } from '../types'
import { FIXED_WIDGETS } from '../constants'
// import { CreditScoreAnalysisSection } from './stats/CreditScoreAnalysisSection'
import { ParetoAnalysisSection } from './stats/ParetoAnalysisSection'
import { CreditScoreAnalysisSection } from './stats/CreditScoreAnalysisSection'
import { VendorGroupSection } from './VendorGroupSection'
import { useDateRangeFilter } from '../helpers/useDateRangeFilter'
import { SectionWidget } from '../components/SectionWidget'
import { PriceTrackingSection } from './PriceTrackingSection'
import { ESGSection } from './ESGSection'
// import { CustomChartModal } from '../components/customCharts/CustomChartModal'

export const DASHBOARD_WIDGETS: Widget[] = [
  {
    id: 1,
    chart: <TotalSpendSection />,
    name: 'Total spend',
    description: 'A summary of your spending, vendors and transactions.',
    supportsComparisons: true,
  },
  {
    id: 2,
    chart: <CategorySection />,
    name: 'Spending by category',
    description: 'Your spending broken down by category.',
  },
  {
    id: 3,
    chart: <VendorsSection />,
    name: 'Vendors',
    description: 'Your top vendors by spending and transactions',
  },
  {
    id: 4,
    chart: <MapSection />,
    name: 'Vendor map',
    description: 'A map of your where your vendors are.',
  },
  {
    id: 5,
    chart: <TotalSpendByPreferredStatusSection />,
    name: 'Suppliers',
    description: 'Your spending broken down by supplier.',
  },
  {
    id: 6,
    chart: <ParetoAnalysisSection />,
    name: 'Pareto',
    description: 'Control your 20/80% split and focus on your tail spend',
  },
  {
    id: 7,
    chart: <CreditScoreAnalysisSection />,
    name: 'Credit Score',
    description: 'A credit score analysis of your vendors',
  },
  {
    id: 11,
    chart: <CreditScoreAnalysisSection isDnb />,
    name: 'Credit Score (D&B)',
    description: 'A credit score analysis of your vendors',
  },
  {
    id: 8,
    chart: <VendorGroupSection />,
    name: 'Vendor Groups',
    description: 'Your vendor groups',
  },
  {
    id: 9,
    chart: <PriceTrackingSection />,
    name: 'Price Tracking',
    description: 'Explore and compare costs for various materials or services',
  },
  {
    id: 10,
    chart: <ESGSection />,
    name: 'ESG Ranking',
    description: "Click on a ESG rating section to filter the vendors' list",
  },
  // re-enabe the stuff below to get the custom widget demos working
  // {
  //   id: 20,
  //   chart: <CustomChartModal />,
  //   name: 'New Custom Chart',
  //   description: 'Create a new custom chart',
  // },
]

const Dashboard = () => {
  const queryFilter = useQueryFilter()
  const dateRangeFilter = useDateRangeFilter()
  const dp = useDataProvider()
  const { t } = useTranslation()

  const { data: tagCategoriesData, isLoading } = useQuery(
    `tagCategoriesData`,
    () => dp.getFilterFields()
  )

  const { data: userWidgetData } = useQuery(`userWidgetData`, () =>
    dp.getUserWidgets()
  )

  // const { data: availableWidgetData } = useQuery(`availableWidgetData`, () =>
  //   dp.getAvailableWidgets()
  // )

  const orderedWidgetIds: number[] = useMemo(
    () =>
      userWidgetData
        ? [
            ...FIXED_WIDGETS,
            ...userWidgetData.filter((i) => !FIXED_WIDGETS.includes(i)),
          ]
        : FIXED_WIDGETS,
    [userWidgetData]
  )

  return (
    dateRangeFilter.ranges && (
      <div className="min-h-screen">
        <ChartContextProvider
          addRule={queryFilter.addRuleToQuery}
          removeRules={queryFilter.removeRulesFromQuery}
          dateRange={dateRangeFilter.ranges[0]}
          compareRange={dateRangeFilter.ranges[1]}
          query={queryFilter.currentQueryRaw}
          setQuery={queryFilter.updateCurrentQuery}
          clearFilter={queryFilter.clearFilter}
        >
          <div className="flex justify-between sticky top-0 -mx-12 px-12 py-4 -my-4 z-50 bg-stone-100 bg-opacity-80 backdrop-blur border-b border-stone-50">
            <Title>{t('dashboard')}</Title>
            <div className="flex space-x-2.5">
              <CurrentQueryTerms />
              <RefreshButton />
              {userWidgetData && (
                <CustomisationModal orderedWidgets={orderedWidgetIds} />
              )}
              {!isLoading && (
                <Feature name="DATE_FILTER">
                  <InvoiceDateFilterModal
                    defaultRange={dateRangeFilter.defaultRange}
                    ranges={dateRangeFilter.ranges}
                    setRanges={dateRangeFilter.setRanges}
                    compareWithEnabled="true"
                  />
                </Feature>
              )}
              {!isLoading && tagCategoriesData ? (
                <QueryBuilderModal
                  queryFilter={queryFilter}
                  fields={tagCategoriesData.data}
                />
              ) : null}
            </div>
          </div>
          <div className="pt-4">
            {orderedWidgetIds.map((widgetId) => {
              const widget = DASHBOARD_WIDGETS.find((i) => i.id === widgetId)
              if (!widget) return null
              return <SectionWidget widget={widget} />
            })}
          </div>
        </ChartContextProvider>
      </div>
    )
  )
}

export default Dashboard
