import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'
import { CalculatorIcon } from '@heroicons/react/24/outline'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useChartContext from '../charts/useChartContext'
import { Stat } from './Stat'
import { formatNumber, getLabelSuffix } from '../../helpers'

const chartType = 'transactionCount'

export const TransactionCount = ({ onClick, isSelected }) => {
  const cc = useChartContext()
  const dp = useDataProvider()
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  const { data, isLoading } = useQuery(
    ['charts', 'getGetChart', chartType, cc.query, cc.dateRange],
    () => dp.getChart(chartType, { query: cc.query, ...cc.dateRange })
  )

  const { data: compareData, isLoading: compareIsLoading } = useQuery(
    [`charts`, 'getGetChart', chartType, cc.query, cc.compareRange],
    () => dp.getChart(chartType, { query: cc.query, ...cc.compareRange })
  )

  const formattedData = useMemo(() => {
    if (isLoading) return undefined
    if (!data || !data.data) return undefined
    return formatNumber(data.data)
  }, [data, isLoading])

  const formattedCompareData = useMemo(() => {
    if (compareIsLoading) return undefined
    if (!compareData || !compareData.data) return undefined
    return formatNumber(compareData.data)
  }, [compareData, compareIsLoading])

  const deltaChange = useMemo(() => {
    if (!data || !data.data) return 0
    if (!compareData || !compareData.data) return 0
    return ((data.data - compareData.data) / compareData.data) * 100
  }, [compareData, data])

  return (
    <Stat
      icon={<CalculatorIcon className="h-4" />}
      onClick={onClick}
      label={t(`no_transactions${suffix}`)}
      loading={isLoading || compareIsLoading}
      value={formattedData}
      compareValue={cc.compareRange ? formattedCompareData : undefined}
      deltaChange={deltaChange}
      background="bg-amber-200"
      isSelected={isSelected}
    />
  )
}
