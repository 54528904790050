import { Create, SimpleForm, TextInput } from 'react-admin'

const GroupCreate = () => {
  const transform = (data) => {
    return data
  }

  return (
    <Create transform={transform} redirect="show">
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  )
}

export default GroupCreate
