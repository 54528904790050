import {
  BooleanInput,
  Edit,
  ListButton,
  ShowButton,
  SimpleForm,
  TextInput,
  TopToolbar,
  useEditController,
  useGetIdentity,
  useNotify,
  usePermissions,
  useRedirect,
  useStore,
  useUpdate,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import TagsInput from '../components/VendorTagsInput'
import userPermissions from '../Users/userPermissions'

const VendorEditActions = () => (
  <TopToolbar>
    <ShowButton />
    <ListButton />
  </TopToolbar>
)

const VendorEdit = () => {
  const transform = (data) => {
    const tags = data.tags.map((t) => {
      return t.id
    })

    return {
      ...data,
      territory_id: 1,
      tags,
    }
  }
  const { id } = useParams()

  const notify = useNotify()
  const redirect = useRedirect()

  const { isLoading: identityLoading } = useGetIdentity()
  const { record, isLoading } = useEditController({
    resource: 'vendors',
    id,
  })
  const [actingAs] = useStore('organisation.actingAs')
  const { permissions } = usePermissions()

  const [update] = useUpdate()
  if (identityLoading || isLoading) return null

  let fullEdit = false

  if (
    permissions & userPermissions.SUPER_ADMIN &&
    actingAs?.organisationId === record.organisation.id
  ) {
    fullEdit = true
  }

  const vendorSave = (rawdata) => {
    const data = transform(rawdata)

    if (fullEdit) {
      update(
        'vendors',
        { id, data },
        {
          onError: (error) => {
            let errorMessage = 'Failed to update'
            if (error instanceof Error) {
              errorMessage = error.message
            }
            notify(`${errorMessage}`, { type: 'error' })
          },
          onSuccess: () => {
            notify(`Vendor updated`, { type: 'success' })
            redirect('show', 'vendors', id)
          },
        }
      )
    } else {
      update(
        'vendors_tags',
        { id, data },
        {
          onError: (error) => {
            let errorMessage = 'Failed to update'
            if (error instanceof Error) {
              errorMessage = error.message
            }
            notify(`${errorMessage}`, { type: 'error' })
          },
          onSuccess: () => {
            notify(`Vendor updated`, { type: 'success' })
            redirect('show', 'vendors', id)
          },
        }
      )
    }
  }

  return (
    <Edit
      mutationMode="pessimistic"
      actions={<VendorEditActions />}
      transform={transform}
    >
      <SimpleForm onSubmit={vendorSave}>
        {fullEdit && <TextInput source="name" />}

        <TagsInput source="tags" />

        <BooleanInput
          source="update_transaction_tags"
          label="Apply tags update to past transactions"
        />
      </SimpleForm>
    </Edit>
  )
}

export default VendorEdit
