import { useState } from 'react'

import { Confirm, SaveButton } from 'react-admin'
import { useForm, useFormContext } from 'react-hook-form'

const SaveWithConfirmButton = ({ saveConfirm, disabled = false }) => {
  const [open, setOpen] = useState(false)

  const form = useForm()
  const cts = useFormContext()

  const confirmSave = (e) => {
    if (saveConfirm) {
      e.preventDefault()
      setOpen(true)
      return true
    }
    if (window.confirm('Are you sure?')) {
      return true
    }
    e.preventDefault()
    return false
  }

  const handleConfirm = () => {
    setOpen(false)
    if (saveConfirm) {
      console.log(cts)
      console.log(form.formState)
      //      saveConfirm()
    }
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  return (
    <>
      <SaveButton label="Save" onClick={confirmSave} disabled={disabled} />
      <Confirm
        isOpen={open}
        title="Update"
        content="Are you sure?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default SaveWithConfirmButton
