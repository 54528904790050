import { BOTTOM_VENDOR_COLOR, TOP_VENDOR_COLOR } from '../colors'

export const ParetoVendorLabel = ({ topVendors, bottomVendors, t, suffix }) => (
  <div className="text-right flex flex-col justify-between">
    <h3 className="text-sm font-medium">{t(`vendors${suffix}`)}</h3>
    <div className="flex flex-col flex-1 justify-between">
      <div style={{ height: '80%' }} className="flex flex-col justify-center">
        <div className="font-medium flex space-x-1.5 items-center justify-end">
          <div
            className="h-2 w-2 rounded-sm"
            style={{ backgroundColor: BOTTOM_VENDOR_COLOR }}
          />
          <span>{bottomVendors?.left.toFixed(2)}%</span>
        </div>
        <div className="opacity-80">{bottomVendors?.data.length}</div>
      </div>

      <div style={{ height: '20%' }} className="flex flex-col justify-center">
        <div className="font-medium flex space-x-1.5 items-center justify-end">
          <div
            className="h-2 w-2 rounded-sm"
            style={{ backgroundColor: TOP_VENDOR_COLOR }}
          />
          <span>{topVendors?.left.toFixed(2)}%</span>
        </div>
        <div className="opacity-80">{topVendors?.data.length}</div>
      </div>
    </div>
  </div>
)
