import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useQuery } from 'react-query'
import { useDataProvider } from 'react-admin'
import { SectionTitle } from '../components/Title'
import {
  SEGMENT_DEFAULT_COLORS,
  SegmentChart,
  SegmentChartLoading,
} from '../components/charts/SegmentChart'
import { ESGRanking } from '../types'
import { formatCurrency, getLabelSuffix } from '../helpers'
import { PAGE_SIZE } from '../constants'
import useChartContext from './charts/useChartContext'
import { NoData } from './NoData'

type ESGRankingLine = {
  id: number
  ranking: number
  name: string
  spend: number
}

const RANKING_TYPES = ['Green', 'Yellow', 'Red', 'Grey'] as ESGRanking[]
const COLORS = {
  Green: SEGMENT_DEFAULT_COLORS.green,
  Yellow: SEGMENT_DEFAULT_COLORS.yellow,
  Red: SEGMENT_DEFAULT_COLORS.red,
  Grey: '#d3d3d3',
}

export const ESGTable = ({ tableData }: { tableData: ESGRankingLine[] }) => {
  const { t } = useTranslation()
  const suffix = getLabelSuffix()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: t('name'),
        headerClassName: 'bg-stone-100',
      },
      {
        field: 'ranking',
        headerName: t('esg_ranking'),
        headerClassName: 'bg-stone-100',
      },
      {
        field: 'spend',
        flex: 0.5,
        headerName: t(`total_spend${suffix}`),
        headerClassName: 'bg-stone-100',
      },
    ],
    [suffix, t]
  )

  return (
    <DataGrid
      rows={tableData}
      disableColumnMenu
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: PAGE_SIZE,
          },
        },
        sorting: {
          sortModel: [
            {
              field: 'ranking',
              sort: 'asc',
            },
          ],
        },
      }}
      pageSizeOptions={[PAGE_SIZE]}
      disableRowSelectionOnClick
    />
  )
}

type RankingItem = {
  id: number
  name: string
  ranking: string
  spend: number
}

type ESGRankingData = {
  Green: RankingItem[]
  Grey: RankingItem[]
  Red: RankingItem[]
  Yellow: RankingItem[]
}

type ESGRankingResponse = {
  data: ESGRankingData[]
}

const chartType = 'esgRankingAnalysis'

export const ESGSection = () => {
  const { t } = useTranslation()
  const cc = useChartContext()
  const dp = useDataProvider()

  const [selectedRanking, setSelectedRanking] = useState<string>()

  const { data, isLoading } = useQuery<ESGRankingResponse>(
    ['charts', 'getGetChart', chartType, cc.query, cc.dateRange],
    () =>
      dp.getChart(
        chartType,
        {
          query: cc.query,
          ...cc.dateRange,
        },
        {
          enabled: !!cc.query,
        }
      )
  )

  const loading = useMemo(() => isLoading || !data, [isLoading, data])
  const noData = useMemo(() => {
    if (loading) return false
    return (
      data?.data?.[0] &&
      !data?.data?.[0]?.Green?.length &&
      !data?.data?.[0]?.Yellow?.length &&
      !data?.data?.[0]?.Red?.length &&
      !data?.data?.[0]?.Grey?.length
    )
  }, [loading, data])

  const segments = useMemo(() => {
    if (!data?.data?.[0]) return undefined
    return RANKING_TYPES.map((rating) => ({
      label: rating,
      color: COLORS[rating],
      amount: data?.data[0]?.[rating]?.length,
    }))
  }, [data?.data])

  const tableData = useMemo(() => {
    if (!data?.data?.[0]) return []

    const ESGData = data?.data?.[0]
    if (selectedRanking) return ESGData[selectedRanking]
    return ESGData.Yellow.concat(ESGData.Green, ESGData.Red, ESGData.Grey)
  }, [data?.data, selectedRanking])

  const formattedTableData = useMemo(
    () =>
      tableData.map((item) => ({
        ...item,
        spend: formatCurrency(item.spend / 100),
      })),
    [tableData]
  )

  return (
    <div className="space-y-6">
      <SectionTitle
        title={t('home.chart_title_9')}
        subtitle={t('home.chart_desc_9')}
      />

      {noData ? (
        <NoData />
      ) : (
        <>
          {segments ? (
            <SegmentChart
              segments={segments}
              onSelect={setSelectedRanking}
              selected={selectedRanking}
            />
          ) : (
            <SegmentChartLoading />
          )}
          {loading ? (
            <div className="bg-gray-200 rounded-lg w-full h-[100px]" />
          ) : (
            <ESGTable tableData={formattedTableData} />
          )}
        </>
      )}
    </div>
  )
}
