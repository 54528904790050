import { ResponsiveBar } from '@nivo/bar'
import { useCallback } from 'react'
import { formatCurrency, formatCurrencyCompact } from '../../helpers'
import { CategoryTooltip } from './CategoryTooltip'

const theme = {
  grid: {
    line: {
      stroke: '#f5f5f5',
    },
  },
}

export const WaterfallChart = ({ items, getColor, onClick, formatLabel }) => {
  const renderTooltip = useCallback(
    (props) => {
      const { id, value, data } = props
      // eslint-disable-next-line react/jsx-no-useless-fragment
      if (id !== 'value') return <></>
      return (
        <CategoryTooltip
          color={getColor(props)}
          label={data.label}
          value={formatCurrency(value)}
        />
      )
    },
    [getColor]
  )
  return (
    <ResponsiveBar
      theme={theme}
      tooltip={(a) => renderTooltip(a)}
      borderWidth={1.2}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.3]],
      }}
      borderRadius={2}
      data={items}
      keys={['bottomOffset', 'value', 'topOffset']}
      indexBy="value"
      margin={{ top: 15, right: 0, bottom: 5, left: 40 }}
      padding={0.25}
      valueFormat={(v) => formatCurrency(v)}
      colors={getColor}
      axisTop={null}
      axisBottom={null}
      label={formatLabel}
      onClick={onClick}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: (v) => formatCurrencyCompact(v),
      }}
    />
  )
}
