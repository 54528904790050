import { useCallback, useMemo, useState } from 'react'
import { Field, formatQuery, QueryBuilder as QB } from 'react-querybuilder'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { materialControlElements } from '@react-querybuilder/material'
import { CheckIcon, TrashIcon } from '@heroicons/react/24/outline'
import CustomValueEditor from '../components/CustomValueEditor'

import 'react-querybuilder/dist/query-builder.scss'
import './QueryBuilder.css'
import { Button } from './common/Button'
import { Confirm } from './common/Confirm'

export const ops = [
  { name: '=', label: 'is' },
  { name: '!=', label: 'is not' },
]

const DEFAULT_FIELDS: Field[] = [
  {
    label: 'Vendor',
    name: 'vendor_id',
    id: 'vendor_id',
    operators: ops,
    datatype: 'vendor',
  },
]

const muiTheme = createTheme()

const QueryBuilder = ({
  onUpdate,
  onClear,
  onDelete,
  onClose,
  query,
  isNew,
  fields = DEFAULT_FIELDS,
}) => {
  const [liveQuery, setLiveQuery] = useState(query)

  const handleClearFilter = useCallback(() => {
    onClear()
    setLiveQuery(query)
    onClose()
  }, [onClear, onClose, query])

  const handleApplyFilter = useCallback(() => {
    onUpdate(formatQuery(liveQuery, 'json_without_ids'))
    onClose()
  }, [liveQuery, onClose, onUpdate])

  // TODO: Flesh validation out
  // Very simple currently: checks that there are rules and they are not empty, does not work for groups
  const isQueryValid = useMemo(() => {
    if (!liveQuery || !liveQuery.rules) return false
    if (liveQuery?.rules?.length === 0) return false
    const nonEmptyQueries = liveQuery.rules.filter((rule) => rule.value !== '')
    if (nonEmptyQueries.length === 0) return false
    const nonEmptyArrayQueries = liveQuery.rules.filter(
      (rule) => rule.value.length
    )
    if (nonEmptyArrayQueries.length === 0) return false
    return true
  }, [liveQuery])

  return (
    <div className="relative min-h-[250px] flex flex-col justify-between space-y-4">
      <div className="flex-1">
        <ThemeProvider theme={muiTheme}>
          <QB
            controlElements={{
              ...materialControlElements,
              valueEditor: CustomValueEditor,
            }}
            showCloneButtons
            fields={fields}
            query={liveQuery}
            onQueryChange={setLiveQuery}
          />
        </ThemeProvider>
      </div>
      <div className="flex items-center space-x-3 justify-between pt-3 border-t border-gray-100">
        {!isNew ? (
          <Confirm
            onConfirm={() => onDelete()}
            render={(confirming, onClick) => (
              <button
                type="button"
                onClick={() => onClick()}
                className="bg-red-500 hover:bg-red-600 border-red-600 text-white rounded-lg active p-2.5 text-sm font-medium"
              >
                {confirming ? 'Are you sure?' : <TrashIcon className="h-4" />}
              </button>
            )}
          />
        ) : (
          ''
        )}
        <div className="flex items-center space-x-3 justify-end flex-1">
          <Button secondary onClick={handleClearFilter}>
            Clear filter
          </Button>
          <Button
            disabled={!isQueryValid}
            icon={<CheckIcon className="h-4" />}
            onClick={handleApplyFilter}
          >
            {isNew ? 'Apply filter' : 'Update filter'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default QueryBuilder
