import { useMemo } from 'react'
import { useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'

import { BuildingStorefrontIcon } from '@heroicons/react/24/outline'

import { useTranslation } from 'react-i18next'
import useChartContext from '../charts/useChartContext'
import { formatNumber, getLabelSuffix } from '../../helpers'

import { Stat } from './Stat'

const CHART_TYPE = 'vendorCount'

export const VendorCount = ({ onClick, isSelected }) => {
  const cc = useChartContext()
  const dp = useDataProvider()
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  const { data, isLoading } = useQuery(
    ['charts', 'getGetChart', CHART_TYPE, cc.query, cc.dateRange],
    () => dp.getChart(CHART_TYPE, { query: cc.query, ...cc.dateRange })
  )

  const { data: compareData, isLoading: compareIsLoading } = useQuery(
    [`charts`, 'getGetChart', CHART_TYPE, cc.query, cc.compareRange],
    () => dp.getChart(CHART_TYPE, { query: cc.query, ...cc.compareRange })
  )

  const formattedAmount = useMemo(() => {
    if (!data || !data.data) return undefined
    return formatNumber(data.data)
  }, [data])

  const formattedCompareAmount = useMemo(() => {
    if (!compareData || !compareData.data) return undefined
    return formatNumber(compareData.data)
  }, [compareData])

  const deltaChange = useMemo(() => {
    if (!data || !data.data) return 0
    if (!compareData || !compareData.data) return 0
    return ((data.data - compareData.data) / compareData.data) * 100
  }, [compareData, data])

  return (
    <Stat
      loading={isLoading || compareIsLoading}
      label={t(`no_vendors${suffix}`)}
      onClick={onClick}
      icon={<BuildingStorefrontIcon className="h-4" />}
      value={formattedAmount}
      compareValue={cc.compareRange ? formattedCompareAmount : undefined}
      deltaChange={deltaChange}
      background="bg-green-200"
      isSelected={isSelected}
    />
  )
}
