export const Title = ({ children }) => (
  <h2 className="text-3xl font-bold">{children}</h2>
)

export const SectionTitle = ({ title, subtitle }) => (
  <div className="space-y-0.5">
    <h2 className="font-semibold text-xl">{title}</h2>
    <h3 className="text-slate-500 text-sm">{subtitle}</h3>
  </div>
)
